import styled from "styled-components";

export const DefaultImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--background-base-widget-thumbnail);
  border-radius: 4px;

  svg path {
    fill: #cccfd1;
  }
`;

export const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

export const Message = styled.div`
  color: #56595b;
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
`;

export const ThumbnailSkeleton = styled.div`
  width: 100%;
  height: 100%;
  animation: skeleton 1.5s infinite ease-in-out;

  @keyframes skeleton {
    10% {
      background-color: rgba(165 165 165 / 10%);
    }

    50% {
      background-color: rgba(165 165 165 / 30%);
    }

    100% {
      background-color: rgba(165 165 165 / 10%);
    }
  }
`;
