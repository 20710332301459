import { Button } from "@components/common/button";
import ScrollBar from "@components/common/scrollbar";
import { CalendarModeRangeType } from "@custom-types/Types";
import { useLang } from "@hooks/useLang";
import useSessionStorage from "@hooks/useSessionStorage";
import {
  BUTTON_STYLE_TYPE,
  CALENDAR,
  CALENDAR_MODE_RANGE_TYPE,
  FORMAT,
  SESSION_STORAGE_KEY
} from "@resources/Constants";
import {
  IconArrowTimeInputChevronDown,
  IconArrowTimeInputChevronUp,
  IconCalendar
} from "@resources/icons/Icons";
import { getDateTime } from "@utils/Widget";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from "..";
import {
  DateRangePickerProps,
  SearchComparisonDateTimeTemporary
} from "./Interfaces";
import {
  DateRangePickerContainerStyled,
  DateRangePickerStyled
} from "./DateRangePicket.styled";

export const DateRangePicker = <T extends SearchComparisonDateTimeTemporary>({
  modes,
  displayMode = false,
  noTextMode = false,
  showIcon = false,
  dateTime,
  setDateTime,
  tabIndex,
  yearIsLimit,
  showRangeMode,
  showTimePicker,
  dateFormat,
  usePreview = true,
  ...props
}: DateRangePickerProps<T>) => {
  const uniq = useId();
  const { translate } = useLang();
  const [showDatePicker, setShowDatePicker] = useState<boolean>(!usePreview);
  const [previousTimeZone, setPreviousTimeZone] = useState<string>("");
  const [selectedDateTime, setSelectedDateTime] =
    useState<SearchComparisonDateTimeTemporary>(dateTime);
  const [selectedTempDateTime, setSelectedTempDateTime] =
    useState<SearchComparisonDateTimeTemporary>(dateTime);
  const [currentTime, setCurrentTime] = useState<string>();
  const [sessionStorageUtc] = useSessionStorage(
    SESSION_STORAGE_KEY.TIME_ZONE,
    momentTimeZone.tz.guess()
  );
  const currentTimeZone = sessionStorageUtc || momentTimeZone.tz.guess();
  const {
    mode: selectedMode,
    start: selectedStart,
    end: selectedEnd
  } = selectedTempDateTime;

  const intervalCurrentTime = useRef<ReturnType<typeof setInterval> | null>(
    null
  );
  const [maxDate, setMaxDate] = useState<Date>();

  const [startHour, setStartHour] = useState<number>(12);
  const [startMinute, setStartMinute] = useState<number>(0);
  const [startSecond, setStartSecond] = useState<number>(0);
  const [startPeriod, setStartPeriod] = useState<string>("AM");

  const [endHour, setEndHour] = useState<number>(11);
  const [endMinute, setEndMinute] = useState<number>(59);
  const [endSecond, setEndSecond] = useState<number>(59);
  const [endPeriod, setEndPeriod] = useState<string>("PM");
  const [reRender, setReRender] = useState<boolean>(false);

  const timePicker = {
    start: {
      label: "from",
      hour: startHour,
      minute: startMinute,
      second: startSecond,
      period: startPeriod,
      setHour: setStartHour,
      setMinute: setStartMinute,
      setSecond: setStartSecond,
      setPeriod: setStartPeriod
    },
    end: {
      label: "to",
      hour: endHour,
      minute: endMinute,
      second: endSecond,
      period: endPeriod,
      setHour: setEndHour,
      setMinute: setEndMinute,
      setSecond: setEndSecond,
      setPeriod: setEndPeriod
    }
  };

  const formatMinuteSecond = (minSec: number) =>
    minSec < 10 ? `0${minSec}` : minSec.toString();

  const onChangeMode = (currentMode: CalendarModeRangeType) => {
    const newDateTime = getDateTime(
      currentMode,
      moment().utc().toISOString(true)
    );

    setEndHour(11);
    setEndMinute(59);
    setEndSecond(59);
    setEndPeriod("PM");

    setStartHour(12);
    setStartMinute(0);
    setStartSecond(0);
    setStartPeriod("AM");

    setSelectedTempDateTime({
      mode: currentMode,
      ...newDateTime
    });
    setReRender(true);
    setTimeout(() => setReRender(false), 10);
  };

  const onClickOutside = (event: MouseEvent) => {
    if (
      event &&
      !(
        (event.target as HTMLElement).classList.contains(
          "date-range-popup-sidebar"
        ) ||
        (event.target as HTMLElement).classList.contains(
          "date-range-popup-footer"
        ) ||
        (event.target as HTMLElement).closest(".date-range-popup-sidebar") ||
        (event.target as HTMLElement).closest(".date-range-popup-footer") ||
        (event.target as HTMLElement).closest(".date-range-calendar") ||
        (event.target as HTMLElement).closest(".time-range") ||
        (event.target as HTMLElement).classList.contains("next-range-picker") ||
        (event.target as HTMLElement).classList.contains(
          "previous-range-picker"
        ) ||
        (event.target as HTMLElement).parentElement?.classList.contains(
          "next-range-picker"
        ) ||
        (event.target as HTMLElement).parentElement?.classList.contains(
          "previous-range-picker"
        )
      )
    ) {
      setShowDatePicker(false);
      props.onShow?.(false);
    }
  };

  const onChangeDate = (date: Date | null | [Date | null, Date | null]) => {
    if (date && Array.isArray(date)) {
      const [startDateTime, endDateTime] = date;
      let newDateTime: SearchComparisonDateTimeTemporary = {
        mode: CALENDAR_MODE_RANGE_TYPE.CUSTOM,
        start: momentTimeZone(startDateTime).tz(currentTimeZone, true).format()
      };

      if (endDateTime) {
        newDateTime = {
          ...newDateTime,
          ...getDateTime(
            newDateTime.mode,
            momentTimeZone(startDateTime).tz(currentTimeZone, true).format(),
            momentTimeZone(endDateTime).tz(currentTimeZone, true).format()
          )
        };
      }
      setSelectedTempDateTime(newDateTime);
    }
  };

  const renderPeriod = useMemo(() => {
    const { mode, start, end } = selectedDateTime;

    const isSingleDateMode =
      mode === CALENDAR_MODE_RANGE_TYPE.YESTERDAY ||
      mode === CALENDAR_MODE_RANGE_TYPE.DAY_BEFORE_YESTERDAY;

    const selectedDateFormat =
      (Object.keys(FORMAT).find(
        (key) =>
          FORMAT[key as keyof typeof FORMAT].toLowerCase() ===
          dateFormat?.toLowerCase()
      ) as keyof typeof FORMAT) || "MONTH_DATE_YEAR_1";

    const formattedStartDate = moment(start)
      .tz(currentTimeZone)
      .format(FORMAT[selectedDateFormat]);

    const formattedStartTime = showTimePicker
      ? `${startHour || 12}:${formatMinuteSecond(
          startMinute || 0
        )}:${formatMinuteSecond(startSecond || 0)} ${startPeriod || "AM"}`
      : "";

    const formattedEndDate = moment(end || start)
      .tz(currentTimeZone)
      .format(FORMAT[selectedDateFormat]);

    const formattedEndTime = showTimePicker
      ? `${endHour || 11}:${formatMinuteSecond(
          endMinute || 0
        )}:${formatMinuteSecond(endSecond || 0)} ${endPeriod || "PM"}`
      : "";

    return (
      <>
        {mode === CALENDAR_MODE_RANGE_TYPE.TODAY && <div className="circle" />}
        {!noTextMode && <p className="mode">{translate(mode)}</p>}
        <div className="time-start">
          {mode === CALENDAR_MODE_RANGE_TYPE.TODAY ? (
            <span>
              {formattedStartDate}&nbsp;{currentTime}
            </span>
          ) : (
            <>
              <span>
                {formattedStartDate} {formattedStartTime}
              </span>
              {(showTimePicker || !isSingleDateMode) && <span> - </span>}
              <span>
                {isSingleDateMode
                  ? ` ${formattedEndTime}`
                  : `${
                      formattedEndDate || formattedStartDate
                    } ${formattedEndTime}`}
              </span>
            </>
          )}
        </div>
      </>
    );
  }, [selectedDateTime, currentTime, currentTimeZone]);

  useEffect(() => {
    return () => {
      if (intervalCurrentTime.current) {
        clearInterval(
          intervalCurrentTime.current as ReturnType<typeof setInterval>
        );
        intervalCurrentTime.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!showDatePicker && selectedTempDateTime.mode !== dateTime.mode) {
      setSelectedTempDateTime(dateTime);
    }
  }, [dateTime.mode]);

  useEffect(() => {
    if (!showDatePicker) {
      const newDateTime = getDateTime(
        dateTime.mode,
        dateTime.start,
        dateTime.end
      );
      setSelectedTempDateTime({
        ...dateTime,
        ...newDateTime
      });
    }
  }, [showDatePicker]);

  const setCurrentDateTime = (timeFormat: string) => {
    setCurrentTime(momentTimeZone.tz(currentTimeZone).format(timeFormat));
  };

  useEffect(() => {
    if (
      dateTime.mode === CALENDAR_MODE_RANGE_TYPE.TODAY ||
      dateTime.mode === CALENDAR_MODE_RANGE_TYPE.LAST_24_HOURS
    ) {
      if (intervalCurrentTime.current === null) {
        setCurrentTime(momentTimeZone.tz(currentTimeZone).format(FORMAT.TIM12));
        intervalCurrentTime.current = setInterval(() => {
          setCurrentDateTime(` ${FORMAT.TIME_12H_2}`);
        }, 10 * 1000);
      }
    } else if (intervalCurrentTime.current) {
      clearInterval(
        intervalCurrentTime.current as ReturnType<typeof setInterval>
      );
      intervalCurrentTime.current = null;
    }
  }, [dateTime.mode]);

  useEffect(() => {
    let maxDate;
    if (selectedTempDateTime.mode === CALENDAR_MODE_RANGE_TYPE.CUSTOM) {
      let defaultLimit = 90;
      if (yearIsLimit) {
        defaultLimit = 365;
      }
      // The limit must be -1. Because it includes the start date
      // ex. 90 days -> 89 limit
      // ex. 365 days (1 year) -> 364 limit
      const customMaxDate = momentTimeZone
        .tz(selectedStart, currentTimeZone)
        .add(defaultLimit - 1, "d");

      maxDate = customMaxDate.isAfter(
        momentTimeZone.tz(moment(), currentTimeZone),
        "day"
      )
        ? momentTimeZone.tz(moment(), currentTimeZone).toDate()
        : customMaxDate.toDate();
    } else {
      maxDate = momentTimeZone.tz(moment(), currentTimeZone).toDate();
    }

    setMaxDate(maxDate);
  }, [selectedStart, selectedTempDateTime.mode]);

  useEffect(() => {
    if (currentTimeZone !== previousTimeZone) {
      if (intervalCurrentTime.current) {
        clearInterval(
          intervalCurrentTime.current as ReturnType<typeof setInterval>
        );
        intervalCurrentTime.current = null;
      }
      if (intervalCurrentTime.current === null) {
        setCurrentTime(
          momentTimeZone.tz(currentTimeZone).format(FORMAT.TIME_12H_2)
        );
        intervalCurrentTime.current = setInterval(
          () => setCurrentDateTime(` ${FORMAT.TIME_12H_2}`),
          10 * 1000
        );
      }
      setPreviousTimeZone(currentTimeZone);
    }
  });

  const createDateTime = (
    date: Date,
    hour: number,
    minute: number,
    second: number,
    period: string
  ) => {
    let hoursIn24Format;
    if (period === "PM" && hour < 12) {
      hoursIn24Format = hour + 12;
    } else if (period === "AM" && hour === 12) {
      hoursIn24Format = 0;
    } else {
      hoursIn24Format = hour;
    }

    const dateTime = moment(date).tz(currentTimeZone);
    return dateTime
      .set({ hour: hoursIn24Format, minute, second })
      .toISOString(true);
  };

  const classNames = useMemo(() => {
    const list = ["date-range-picker"];
    if (props.disabled) {
      list.push("disabled");
    }

    if (!usePreview) {
      list.push("disable-preview-mode");
    }
    return list.join(" ");
  }, [props.disabled, usePreview]);

  return (
    <DateRangePickerContainerStyled
      id={`date-range-picker_${uniq}`}
      className={classNames}
      {...props}
    >
      <button
        className="date-range-picker-control"
        onClick={() => {
          if (props.disabled) return;
          const isShow = !showDatePicker;
          setShowDatePicker(isShow);
          props.onShow?.(isShow);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setShowDatePicker(true);
            props.onShow?.(true);
          }
        }}
        aria-label="Date range picker"
        tabIndex={tabIndex}
      >
        {showIcon ? (
          <div className="icon-calendar">
            <IconCalendar width={16} height={16} />
          </div>
        ) : null}
        {!displayMode ? (
          <div className="period">{renderPeriod}</div>
        ) : (
          <div style={{ whiteSpace: "nowrap" }}>
            <p className="mode-time-range">{translate(selectedMode)}</p>
          </div>
        )}
      </button>
      {showDatePicker && (
        <DateRangePickerStyled className="date-range-popup">
          <div>
            {showRangeMode && (
              <div className="date-range-popup-sidebar">
                <ScrollBar>
                  {Object.values(modes || CALENDAR_MODE_RANGE_TYPE)
                    .filter(
                      (item) => item !== CALENDAR_MODE_RANGE_TYPE.LAST_24_HOURS
                    )
                    .map((currentMode) => (
                      <button
                        key={currentMode}
                        aria-label={`Range ${currentMode.replaceAll(
                          "_",
                          " "
                        )} `}
                        className={currentMode === selectedMode ? "active" : ""}
                        onClick={() => onChangeMode(currentMode)}
                        tabIndex={tabIndex}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            onChangeMode(currentMode);
                          }
                        }}
                      >
                        {translate(currentMode)}
                      </button>
                    ))}
                </ScrollBar>
              </div>
            )}
            <div className="date-range-calendar">
              <Calendar
                // to re-render calendar when user change mode to get focus on that month and date
                key={reRender.toString()}
                inline
                selectsRange
                showPreviousMonths
                focusSelectedMonth
                today={
                  new Date(
                    momentTimeZone.tz(moment(), currentTimeZone).toDate()
                  )
                }
                selected={
                  new Date(
                    momentTimeZone
                      .tz(selectedStart, currentTimeZone)
                      .format(FORMAT.MONTH_DATE_YEAR_1)
                  )
                }
                startDate={
                  new Date(
                    momentTimeZone
                      .tz(selectedStart, currentTimeZone)
                      .format(FORMAT.MONTH_DATE_YEAR_1)
                  )
                }
                endDate={
                  selectedEnd
                    ? new Date(
                        momentTimeZone
                          .tz(selectedEnd, currentTimeZone)
                          .format(FORMAT.MONTH_DATE_YEAR_1)
                      )
                    : undefined
                }
                maxDate={
                  new Date(
                    momentTimeZone
                      .tz(maxDate, currentTimeZone)
                      .format(FORMAT.MONTH_DATE_YEAR_1)
                  )
                }
                onChange={onChangeDate}
                onClickOutside={onClickOutside}
                {...(yearIsLimit
                  ? {
                      minDate: new Date(
                        momentTimeZone
                          .tz(moment(), currentTimeZone)
                          .subtract(CALENDAR.MAX_SELECTABLE_DAY, "days")
                          .toDate()
                      )
                    }
                  : {})}
              />
              {showTimePicker && (
                <div className="time-range">
                  {(
                    Object.keys(timePicker) as Array<keyof typeof timePicker>
                  ).map((type) => {
                    const isTodayMode =
                      selectedMode === CALENDAR_MODE_RANGE_TYPE.TODAY;
                    const disabledClass = isTodayMode ? "disabled" : "";
                    return (
                      <div key={type} className="time-group">
                        <div className="time-label-date">
                          <div className="time-label">
                            <span>{translate(timePicker[type].label)}</span>
                          </div>
                          <div className="time-date">
                            {moment(
                              type === "start"
                                ? selectedTempDateTime.start
                                : selectedTempDateTime.end ||
                                    selectedTempDateTime.start
                            ).format(FORMAT.MONTH_DATE_YEAR_1)}
                          </div>
                        </div>

                        <div className="time-input-group">
                          <div className="input-arrow-container">
                            <input
                              aria-label={`${type} hour`}
                              type="number"
                              value={timePicker[type].hour}
                              className={disabledClass}
                              onChange={(e) => {
                                const value = Number(e.target.value);
                                timePicker[type].setHour(
                                  value >= 1 && value <= 12
                                    ? value
                                    : timePicker[type].hour
                                );
                              }}
                              min="1"
                              max="12"
                              disabled={isTodayMode}
                            />
                            <div className="arrow-container">
                              <button
                                className={disabledClass}
                                onClick={() =>
                                  timePicker[type].setHour(
                                    Math.min(timePicker[type].hour + 1, 12)
                                  )
                                }
                                aria-label={`Increment ${type} hour`}
                                disabled={isTodayMode}
                              >
                                <IconArrowTimeInputChevronUp />
                              </button>
                              <button
                                className={disabledClass}
                                onClick={() =>
                                  timePicker[type].setHour(
                                    Math.max(timePicker[type].hour - 1, 1)
                                  )
                                }
                                aria-label={`Decrement ${type} hour`}
                                disabled={isTodayMode}
                              >
                                <IconArrowTimeInputChevronDown />
                              </button>
                            </div>
                          </div>
                          <span>:</span>
                          <div className="input-arrow-container">
                            <input
                              aria-label={`${type} minute`}
                              className={disabledClass}
                              type="number"
                              value={formatMinuteSecond(
                                timePicker[type].minute
                              )}
                              onChange={(e) => {
                                const value = Number(e.target.value);
                                timePicker[type].setMinute(
                                  value >= 0 && value <= 59
                                    ? value
                                    : timePicker[type].minute
                                );
                              }}
                              min="0"
                              max="59"
                              disabled={isTodayMode}
                            />
                            <div className="arrow-container">
                              <button
                                className={disabledClass}
                                onClick={() =>
                                  timePicker[type].setMinute(
                                    Math.min(timePicker[type].minute + 1, 59)
                                  )
                                }
                                aria-label={`Increment ${type} minute`}
                                disabled={isTodayMode}
                              >
                                <IconArrowTimeInputChevronUp />
                              </button>
                              <button
                                className={disabledClass}
                                onClick={() =>
                                  timePicker[type].setMinute(
                                    Math.max(timePicker[type].minute - 1, 0)
                                  )
                                }
                                aria-label={`Decrement ${type} minute`}
                                disabled={isTodayMode}
                              >
                                <IconArrowTimeInputChevronDown />
                              </button>
                            </div>
                          </div>
                          <span>:</span>
                          <div className="input-arrow-container">
                            <input
                              aria-label={`${type} second`}
                              className={disabledClass}
                              type="number"
                              value={formatMinuteSecond(
                                timePicker[type].second
                              )}
                              onChange={(e) => {
                                const value = Number(e.target.value);

                                timePicker[type].setSecond(
                                  value >= 0 && value <= 59
                                    ? value
                                    : timePicker[type].second
                                );
                              }}
                              min="0"
                              max="59"
                              disabled={isTodayMode}
                            />
                            <div className="arrow-container">
                              <button
                                className={disabledClass}
                                onClick={() =>
                                  timePicker[type].setSecond(
                                    Math.min(timePicker[type].second + 1, 59)
                                  )
                                }
                                aria-label={`Increment ${type} second`}
                                disabled={isTodayMode}
                              >
                                <IconArrowTimeInputChevronUp />
                              </button>
                              <button
                                className={disabledClass}
                                onClick={() =>
                                  timePicker[type].setSecond(
                                    Math.max(timePicker[type].second - 1, 0)
                                  )
                                }
                                aria-label={`Decrement ${type} second`}
                                disabled={isTodayMode}
                              >
                                <IconArrowTimeInputChevronDown />
                              </button>
                            </div>
                          </div>

                          <div className="am-pm-toggle">
                            <button
                              className={`toggle-button ${
                                timePicker[type].period === "AM" && "active"
                              }`}
                              onClick={() => timePicker[type].setPeriod("AM")}
                              aria-label={`${type} AM`}
                              disabled={isTodayMode}
                            >
                              {translate("am")}
                            </button>
                            <button
                              className={`toggle-button ${
                                timePicker[type].period === "PM" && "active"
                              }`}
                              onClick={() => timePicker[type].setPeriod("PM")}
                              aria-label={`${type} PM`}
                              disabled={isTodayMode}
                            >
                              {translate("pm")}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="button-submit">
                <Button
                  label={translate("ok")}
                  styleType={BUTTON_STYLE_TYPE.FOURTH}
                  aria-label="date-range-picker-submit-button"
                  onClick={() => {
                    const { mode, start, end } = selectedTempDateTime;
                    const dateTime = getDateTime(mode, start, end);

                    const startDateTime = createDateTime(
                      momentTimeZone
                        .tz(start, currentTimeZone)
                        .startOf("day")
                        .toDate(),
                      startHour,
                      startMinute,
                      startSecond,
                      startPeriod
                    );
                    const endDateTime = createDateTime(
                      end
                        ? momentTimeZone
                            .tz(end, currentTimeZone)
                            .endOf("day")
                            .toDate()
                        : momentTimeZone
                            .tz(start, currentTimeZone)
                            .endOf("day")
                            .toDate(),
                      endHour,
                      endMinute,
                      endSecond,
                      endPeriod
                    );
                    const isSameDay = moment(startDateTime).isSame(
                      endDateTime,
                      "day"
                    );
                    const periodElement =
                      document.querySelector(".am-pm-toggle");
                    if (
                      isSameDay &&
                      moment(endDateTime).isBefore(startDateTime)
                    ) {
                      if (periodElement) {
                        periodElement.classList.add("error-border");
                      }
                      return;
                    }

                    setSelectedDateTime({
                      ...selectedTempDateTime,
                      ...dateTime,
                      start: startDateTime,
                      end: endDateTime
                    });

                    setDateTime({
                      ...selectedTempDateTime,
                      ...dateTime,
                      start: startDateTime,
                      end: endDateTime,
                      mode
                    } as T);

                    setShowDatePicker(false);
                    props.onShow?.(false);
                  }}
                />
              </div>
            </div>
          </div>
        </DateRangePickerStyled>
      )}
    </DateRangePickerContainerStyled>
  );
};
