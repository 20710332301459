import React, { ReactNode, useMemo, useState } from "react";
import { ToastContext, ToastInfo } from "./ToastContext";

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toastInfo, setToastInfo] = useState<ToastInfo | undefined>(undefined);
  const contextValue = useMemo(
    () => ({
      toastInfo,
      setToastInfo
    }),
    [toastInfo]
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
    </ToastContext.Provider>
  );
};
