import { IconArrowRight, IconCheckLine } from "@resources/icons/Icons";
import { isArray } from "lodash";
import React, { useId, useState } from "react";
import { ContextMenuProps, MenuItem } from "./Interfaces";
import { ContextMenuStyled } from "./ContextMenu.styled";

export const ContextMenu = ({
  children,
  value,
  data = [],
  trigger = "hover",
  tabIndex = 0
}: ContextMenuProps) => {
  const uniq = useId();
  const [show, setShow] = useState<boolean>(false);

  return (
    <ContextMenuStyled
      id={`context-menu_${uniq}`}
      aria-label="context-menu"
      className="context-menu"
      onMouseMove={() => trigger === "hover" && setShow(true)}
      onMouseLeave={() => trigger === "hover" && setShow(false)}
      onClick={() => trigger === "press" && setShow((prev) => !prev)}
      role="button"
      tabIndex={tabIndex}
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "Space") {
          setShow((prev) => !prev);
        }
      }}
    >
      {children}
      <ul
        style={{ visibility: show ? "visible" : "hidden" }}
        data-visibility={show ? "visible" : "hidden"}
      >
        {isArray(data)
          ? data.map((item: MenuItem, index) => {
              return (
                <li
                  className={`${item.icon ? "has-icon" : ""}`}
                  key={`context-menu-${uniq}-${item.label}`}
                  aria-label={`context-menu-${index}`}
                >
                  <button
                    {...item.custom}
                    onClick={(e) => {
                      item.custom?.onClick?.(e);
                      item.custom?.onSelectItem?.(item);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.currentTarget.click();
                      }
                    }}
                  >
                    {value && (
                      <div>
                        {value === item.label ? (
                          <IconCheckLine width={16} height={16} />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {item.icon || null}
                    {item.label}
                    {item.children && (
                      <>
                        <IconArrowRight
                          className="icon"
                          width={16}
                          height={16}
                        />
                        {item.children && (
                          <ul>
                            {item.children.map((sub: MenuItem) => (
                              <li
                                key={`sub-context-menu-${uniq}-${sub.label}`}
                                aria-label={`sub-context-menu-${index}`}
                              >
                                <button
                                  {...sub.custom}
                                  onClick={(e) => {
                                    sub.custom?.onClick?.(e);
                                    sub.custom?.onSelectItem?.(sub);
                                  }}
                                >
                                  {sub.label}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    )}
                  </button>
                </li>
              );
            })
          : data}
      </ul>
    </ContextMenuStyled>
  );
};
