import { TypeTimeInterval } from "@custom-types/Types";
import { TYPE_TIME_INTERVAL } from "@resources/Constants";
import moment from "moment";
import { Option } from "@components/common/select";
import { useLang } from "./useLang";

interface ChartSelectMenuPrams {
  baseTime: {
    start: string;
    end: string;
  };
}
export const useChartSelectMenu = (params: ChartSelectMenuPrams) => {
  const { start, end } = params.baseTime;
  const { translate } = useLang();

  return [
    {
      value: TYPE_TIME_INTERVAL.HOUR,
      label: translate("hour"),
      disabled: moment(end).diff(start, "day") >= 3
    },
    {
      value: TYPE_TIME_INTERVAL.DAY,
      label: translate("day"),
      disabled: moment(end).diff(start, "day") >= 30
    },
    {
      value: TYPE_TIME_INTERVAL.WEEK,
      label: translate("week"),
      disabled: moment(end).diff(start, "day") <= 2
    },
    {
      value: TYPE_TIME_INTERVAL.MONTH,
      label: translate("month"),
      disabled: moment(end).diff(start, "day") <= 29
    }
  ] as Option<TypeTimeInterval>[];
};

export const useAnalyticsChartSelectMenu = (params: ChartSelectMenuPrams) => {
  const { start, end } = params.baseTime;
  const { translate } = useLang();

  return [
    {
      value: TYPE_TIME_INTERVAL.HOUR,
      label: translate("hour"),
      disabled: moment(end).diff(start, "day") >= 30
    },
    {
      value: TYPE_TIME_INTERVAL.DAY,
      label: translate("day"),
      disabled: false
    },
    {
      value: TYPE_TIME_INTERVAL.WEEK,
      label: translate("week"),
      disabled: false
    },
    {
      value: TYPE_TIME_INTERVAL.MONTH,
      label: translate("month"),
      disabled: moment(end).diff(start, "day") <= 29
    }
  ] as Option<TypeTimeInterval>[];
};
