import styled from "styled-components";
import { ButtonStyleProps } from "./Interfaces";

export const ButtonStyled = styled.button<ButtonStyleProps>`
  font-size: 14px;
  padding: 6.5px 20px;
  min-width: 100px;
  min-height: 32px;
  border-radius: 4px;
  line-height: 17px;
  color: var(--text-primary);

  &.btn {
    &-first {
      background-color: var(--background-1st-button-default);

      &:hover {
        background-color: var(--background-1st-button-hover);
      }

      &:active {
        background-color: var(--background-1st-button-press) !important;
      }

      &:focus {
        background-color: var(--background-1st-button-focus);
      }

      &:disabled {
        cursor: not-allowed;
        color: var(--text-disabled);
        background-color: var(--background-1st-button-disable) !important;
      }
    }

    &-second {
      border: 1px solid var(--border-2nd-button-default);
      background-color: var(--background-2nd-button-default);

      &:hover {
        border: 1px solid var(--border-2nd-button-hover);
      }

      &:active {
        border: 1px solid var(--border-2nd-button-press) !important;
        background-color: var(--background-2nd-button-press) !important;
      }

      &:focus {
        border: 1px solid var(--border-2nd-button-focus);
      }

      &:disabled {
        cursor: not-allowed;
        color: var(--text-disabled);
        background-color: var(--background-2nd-button-disable) !important;
      }
    }

    &-third {
      background-color: var(--background-3rd-button-default);

      &:hover {
        background-color: var(--background-3rd-button-hover);
      }

      &:active {
        background-color: var(--background-3rd-button-press) !important;
      }

      &:disabled {
        cursor: not-allowed;
        color: var(--text-disabled);
        background-color: var(--background-3rd-button-disable) !important;
      }
    }

    &-fourth {
      color: var(--white);
      background-color: var(--background-4th-button-default);

      &:hover {
        background-color: var(--background-4th-button-hover);
      }

      &:active {
        background-color: var(--background-4th-button-press) !important;
      }

      &:disabled {
        cursor: not-allowed;
        color: var(--text-disabled);
        background-color: var(--background-4th-button-disable) !important;
      }
    }
    &-norule {
      background-color: var(--background-3rd-button-default);

      &:hover {
        background-color: var(--background-3rd-button-hover);
      }

      &:active {
        background-color: var(--background-3rd-button-press) !important;
      }

      &:disabled {
        cursor: not-allowed;
        color: var(--text-disabled);
        background-color: var(--background-3rd-button-disable) !important;
      }
      svg path {
        fill: var(--icon-rule) !important;
      }
    }

    &-rule {
      color: var(--white);
      background-color: var(--background-4th-button-default);

      &:hover {
        background-color: var(--background-4th-button-hover);
      }

      &:active {
        background-color: var(--background-4th-button-press) !important;
      }

      &:disabled {
        cursor: not-allowed;
        color: var(--text-disabled);
        background-color: var(--background-4th-button-disable) !important;
      }
      svg path {
        fill: var(--white) !important;
      }
    }
  }

  ${({ icon }) =>
    icon &&
    `
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    svg path {
      fill: var(--icon-disabled) !important;
    }
  }

  svg {
    margin-right: 4px;
    path {
      fill: var(--white);
  .label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.has-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 4px;
    }

    &:disabled {
      svg path {
        fill: var(--icon-disabled) !important;
      }
    }
  }

  `}

  ${({ isLoading }) =>
    isLoading &&
    `
  
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    margin-right: 6px;
    border-color: var(--border-emphasis) !important;
    border-bottom-color: transparent !important;
  }
}`}

${({ arrowCenter }) =>
    arrowCenter &&
    `
  justify-content: center;

  svg {
    margin-left: 7px;
  }
`}

  ${({ hasArrow }) =>
    hasArrow &&
    `
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      path {
        fill: var(--icon-emphasis);
      }
    }

    &:disabled {
      svg path {
        fill: var(--icon-disabled);
      }
    }

   `}
`;

export const ButtonLabel = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
