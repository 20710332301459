import React from "react";
import { ReactComponent as IconClose } from "@resources/icons/svg/icon-close.svg";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";

import { Button } from "@components/common/button";
import { BUTTON_STYLE_TYPE } from "@resources/Constants";
import { useLang } from "@hooks/useLang";
import { Order } from "@queries/tanstack/analytics-filter/AnalyticsFilterQuery";
import * as S from "./ChartOrderLayer.styled";

interface ChartOrderLayerProps {
  items: React.ReactElement[];
  onOk: () => void;
  onCancel: () => void;
  onClose: () => void;
  onChangeOrder: (order: Order[]) => void;
}
export const ChartOrderLayer = (props: ChartOrderLayerProps) => {
  const { translate } = useLang();

  return (
    <S.Container>
      <S.TitleWrap>
        <S.Title>{translate("ordering")}</S.Title>
        <button onClick={props.onClose} style={{ width: 24, height: 24 }}>
          <IconClose width={24} height={24} />
        </button>
      </S.TitleWrap>
      <GridLayout
        onLayoutChange={(v) => {
          props.onChangeOrder(
            v.sort((a, b) => a.y - b.y).map((item) => item.i as Order)
          );
        }}
        draggableHandle=".drag-handle"
        isResizable={false}
        cols={props.items.length}
        rowHeight={40}
        margin={[0, 8]}
        containerPadding={[0, 0]}
        width={496}
        verticalCompact
        style={{
          height: 496,
          overflowY: "auto",
          display: "flex",
          justifyContent: "center"
        }}
      >
        {props.items.map((item, i) => (
          <S.ListItem
            key={item.key}
            data-grid={{ x: 0, y: i * 40, w: 496, h: 1 }}
          >
            {item}
          </S.ListItem>
        ))}
      </GridLayout>

      <S.Footer>
        <Button
          label={translate("ok")}
          disabled={false}
          onClick={props.onOk}
          styleType={BUTTON_STYLE_TYPE.FOURTH}
        />
        <Button
          label={translate("cancel")}
          disabled={false}
          onClick={props.onCancel}
          styleType={BUTTON_STYLE_TYPE.THIRD}
        />
      </S.Footer>
    </S.Container>
  );
};
