import { useEffect } from "react";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryKeys } from "@utils/Query";
import { GetPortalUserResponse, userApi } from "@queries/User";
import { setUserId, setUserPermissions } from "@redux/System";
import { VIEWER_PERMISSIONS } from "@resources/Constants";
import { useAppDispatch } from "@configs/Redux";

export default function useInitialUserInfo(
  orgId: string,
  options?: UseQueryOptions<GetPortalUserResponse>
) {
  const dispatch = useAppDispatch();

  const { data, isLoading, isError } = useQuery<GetPortalUserResponse>(
    queryKeys("userInfo").getAll(),
    () => {
      return userApi.getUserWithPermissions(orgId ?? "");
    },
    {
      ...options,
      enabled: !!orgId
    }
  );

  useEffect(() => {
    dispatch(setUserPermissions(data?.permissions || VIEWER_PERMISSIONS));
    dispatch(setUserId(data?.userId || ""));
  }, [data]);

  return {
    data,
    isLoading,
    isError
  };
}
