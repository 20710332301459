import { useMutation } from "@tanstack/react-query";
import {
  analyticsApi,
  AnalyticsChartDataResponse,
  AnalyticsFilterApiInputPayload,
  AnalyticPeopleFlowResponse,
  AnalyticQueuesResponse,
  AnalyticStoppedVehicleResponse,
  StoppedVehicleChartData
} from "@queries/Analytics";
import { useMemo, useState } from "react";
import {
  ANALYTICS_CHART_API,
  ANALYTICS_CHART_TYPE,
  ANALYTICS_CHART_VIEW_TYPE
} from "@resources/Constants";
import { generateUniqueColors } from "@utils/Global";
import { PALETTES } from "@resources/Palettes";

const chartColors = [
  PALETTES.DARK.SYSTEM_YELLOW,
  PALETTES.DARK.SYSTEM_GREEN,
  PALETTES.DARK.SYSTEM_BLUE,
  PALETTES.DARK.SYSTEM_ORANGE,
  PALETTES.DARK.SYSTEM_EMERALD,
  PALETTES.DARK.SYSTEM_CORAL,
  PALETTES.DARK.SYSTEM_INDIGO,
  PALETTES.DARK.SYSTEM_PURPLE,
  PALETTES.DARK.SYSTEM_RED,
  PALETTES.LIGHT.SYSTEM_PINK,
  PALETTES.DARK.SYSTEM_VIOLET,
  PALETTES.DARK.SYSTEM_SAPPHIRE
];

const locationChartColor: Record<string, string> = {};

const getLocationColor = (location: string) => {
  if (!locationChartColor[location]) {
    const usedColors = Object.values(locationChartColor);
    const uniqueColors = chartColors.filter(
      (color) => usedColors.indexOf(color) < 0
    );
    if (uniqueColors.length) {
      locationChartColor[location] = uniqueColors[0];
    } else {
      locationChartColor[location] = generateUniqueColors(1)[0];
    }
  }
  return locationChartColor[location];
};

export const useGetAnalyticsChartQuery = (
  widgetType: string,
  chartType?: string,
  selectedVehicleTypes?: string[]
) => {
  const [chartData, setChartData] = useState<AnalyticsChartDataResponse>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetched, setIsFetched] = useState(false);
  const [originalData, setOriginalData] = useState<AnalyticsChartDataResponse>(
    []
  );

  const calculateChartData = (
    data: AnalyticsChartDataResponse,
    widgetType: string
  ) => {
    if (widgetType === ANALYTICS_CHART_TYPE.PEOPLE_FLOW) {
      return data.map((location) => {
        const {
          locationName,
          in: inData,
          out: outData
        } = location as AnalyticPeopleFlowResponse;

        let chartData = inData.chartData;

        if (chartType === "people_out") {
          chartData = outData.chartData;
        }

        return {
          locationName,
          chartData,
          color: getLocationColor(locationName)
        };
      });
    }
    if (
      widgetType === ANALYTICS_CHART_TYPE.PEOPLE_QUEUES ||
      widgetType === ANALYTICS_CHART_TYPE.SHOPPING_CART_QUEUES ||
      widgetType === ANALYTICS_CHART_TYPE.VEHICLE_QUEUES
    ) {
      const dataKey = chartType === "count" ? "count" : "time";
      return data.map((location) => {
        const { locationName, chartData } = location as AnalyticQueuesResponse;
        const Data = chartData.map((point) => point[dataKey] as number);
        return {
          locationName,
          chartData: Data,
          color: getLocationColor(locationName)
        };
      });
    }
    if (widgetType === ANALYTICS_CHART_TYPE.STOPPED_VEHICLE_ANALYTICS) {
      return data.map((location) => {
        const { locationName, chartData } =
          location as AnalyticStoppedVehicleResponse;

        let selectedData = selectedVehicleTypes?.map((item) =>
          item.toLowerCase()
        );
        if (selectedData?.includes("all")) {
          const [_, ...other] = selectedData;
          selectedData = other;
        }
        const keys = selectedData?.length
          ? selectedData
          : (Object.keys(
              chartData
            ) as (keyof AnalyticStoppedVehicleResponse["chartData"])[]);

        const chartSumByType = Array.from({
          length: chartData[keys[0] as keyof StoppedVehicleChartData].length
        }).map((_v, i) =>
          keys
            .map((key) => chartData[key as keyof StoppedVehicleChartData][i])
            .reduce((a, b) => a + b, 0)
        );

        return {
          locationName,
          chartData: chartSumByType,
          color: getLocationColor(locationName)
        };
      });
    }
    return data.map((item) => ({
      ...item,
      color: getLocationColor(item.locationName)
    }));
  };

  useMemo(() => {
    if (!chartData.length) return;

    let analyticsData;

    if (
      (chartType &&
        !ANALYTICS_CHART_VIEW_TYPE.CUMULATIVE.includes(chartType)) ||
      selectedVehicleTypes?.length
    ) {
      analyticsData = calculateChartData(
        originalData as AnalyticsChartDataResponse,
        widgetType
      );
    }

    if (analyticsData) {
      setChartData(analyticsData);
    }
  }, [chartType, selectedVehicleTypes]);

  const { mutate: getChartData } = useMutation(
    (options: AnalyticsFilterApiInputPayload) => {
      const type =
        ANALYTICS_CHART_API[widgetType as keyof typeof ANALYTICS_CHART_API];
      setIsLoading(true);
      return analyticsApi.chartAnalytics(type, options);
    },
    {
      onSuccess: (data: AnalyticsChartDataResponse) => {
        setOriginalData(data);
        const analyticsData = calculateChartData(
          data as AnalyticsChartDataResponse,
          widgetType
        );
        setChartData(analyticsData);
        setIsLoading(false);
        setIsFetched(true);
      }
    }
  );

  return {
    data: chartData,
    getChartData,
    isLoading,
    isFetched
  };
};
