import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

export const Container = styled.div<{ $visible: boolean }>`
  margin-bottom: 15px;
  cursor: pointer;
  background: var(--mono-0);
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 var(--box-shadow-return);
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  animation: ${slideIn} 0.3s;

  &.hover {
    background: var(--mono-0) !important;
  }
`;
