import { CHART_TYPE } from "@resources/Constants";
import type {
  ECharts,
  EChartsOption,
  LineSeriesOption,
  SeriesOption,
  SetOptionOpts
} from "echarts";
import { getInstanceByDom, init } from "echarts";
import React, {
  CSSProperties,
  useEffect,
  useId,
  useRef,
  useState
} from "react";
import _ from "lodash";
import { setWidgetResized } from "@redux/Dashboard";
import { useAppDispatch, useAppSelector } from "@configs/Redux";

interface EChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: "light" | "dark";
  clearAfterUpdateChart?: boolean;
  mainChartType?: string;
  expansionChartType?: string;
  onLoad?: (chartInstance?: ECharts) => void;
}

interface DataZoomPayload {
  type: string;
  batch: Array<{
    start: number;
    end: number;
  }>;
}

export default ({
  option,
  style,
  settings,
  loading,
  theme,
  clearAfterUpdateChart = false,
  mainChartType,
  expansionChartType,
  onLoad
}: EChartsProps) => {
  const uniq = useId();
  const chartRef = useRef<HTMLDivElement>(null);
  const [heightChart, setHeightChart] = useState<string>("170px");
  const dispatch = useAppDispatch();
  const { widgetResized } = useAppSelector((state) => state.dashboard);
  const debouncedResizeChart = _.debounce(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (mainChartType !== CHART_TYPE.BAR_CATEGORY_STACK) {
        chart?.resize();
      }
    }
  }, 300);

  useEffect(() => {
    const parentElement = chartRef.current?.parentElement;
    if (!parentElement) return;

    const resizeObserver = new ResizeObserver(debouncedResizeChart);
    resizeObserver.observe(parentElement);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.unobserve(parentElement);
      resizeObserver.disconnect();
    };
  }, [debouncedResizeChart]);

  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme);
      onLoad?.(chart);
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    const resizeChart = _.debounce(() => {
      chart?.resize();
    }, 300);

    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, [theme]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (!loading && chart) {
        chart.hideLoading();
        if (clearAfterUpdateChart) {
          chart.clear();
        }
        chart.setOption(option, settings);
        chart.on("dataZoom", (params) => {
          const dataZoomParams = params as DataZoomPayload;
          if (!dataZoomParams?.batch || dataZoomParams?.batch.length === 0)
            return;

          const start = dataZoomParams?.batch[0].start;
          const end = dataZoomParams?.batch[0].end;
          const currentOption = chart.getOption();
          const seriesArray = Array.isArray(currentOption.series)
            ? currentOption.series
            : [currentOption.series];

          if (seriesArray && Array.isArray(seriesArray)) {
            const allMaxData = seriesArray.map((series) => {
              if (Array.isArray(series.data)) {
                return Math.max(...(series.data as number[]));
              }
              return 0;
            });

            const maxIndex = allMaxData.indexOf(Math.max(...allMaxData));
            const maxIndexValue = Math.max(...allMaxData);
            maxIndexValue > 0 &&
              chart.setOption({
                series: seriesArray.map((seriesData, i) => {
                  const markPoint = seriesData?.markPoint;
                  const zoomStart = Math.round(
                    (start / 100) * (seriesData?.data as number[]).length
                  );
                  const zoomEnd = Math.round(
                    (end / 100) * (seriesData?.data as number[]).length
                  );
                  const visibleData = (seriesData?.data as number[]).slice(
                    zoomStart,
                    zoomEnd
                  );
                  const updatedSeries = { ...(seriesData as SeriesOption) };
                  const maxValue = Math.max(...(visibleData as []));
                  if (maxIndex === i && maxValue === 0 && markPoint) {
                    (
                      updatedSeries?.markPoint as LineSeriesOption
                    ).symbolSize = 0;
                  } else if (markPoint && maxIndex === i) {
                    (
                      updatedSeries?.markPoint as LineSeriesOption
                    ).symbolSize = 12;
                  }
                  return {
                    ...(updatedSeries as SeriesOption)
                  };
                })
              });
          }
        });
      } else {
        chart?.showLoading({
          text: "",
          showSpinner: false
        });
      }
    }
  }, [option, settings, loading, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  useEffect(() => {
    // Resize chart
    if (chartRef.current !== null && widgetResized) {
      const chart = getInstanceByDom(chartRef.current);
      if (chart) {
        setTimeout(() => {
          chart?.resize();
        }, 300);
        dispatch(setWidgetResized(false));
      }
    }
  }, [widgetResized]);

  useEffect(() => {
    if (
      mainChartType === CHART_TYPE.GAUGE_BEELINE ||
      mainChartType === CHART_TYPE.GAUGE_BEELINE_SOLID
    ) {
      setHeightChart("100px");
    } else {
      setHeightChart("170px");
    }
  }, [mainChartType]);

  return (
    <div
      id={`chart_${uniq}`}
      ref={chartRef}
      data-testid={`report-chart ${
        mainChartType
          ? mainChartType?.toLocaleLowerCase()
          : expansionChartType?.toLocaleLowerCase()
      }`}
      style={{ width: "100%", height: heightChart, ...style }}
    />
  );
};
