import { RefObject, useEffect } from "react";

const useDetectOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void,
  additionalElements?: string[]
) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      !additionalElements?.some(
        (className) =>
          document
            .querySelector(`.${className}`)
            ?.contains(event.target as Node)
      )
    ) {
      callback();
    }
  };

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [ref, callback]);
};

export default useDetectOutsideClick;
