import styled, { css } from "styled-components";

export const Container = styled.div<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  border-radius: 8px;
  border: 1px solid var(--border-default);
  box-sizing: border-box;
  font-size: 14px;
  cursor: move;
  overflow: hidden;

  svg path {
    fill: var(--icon-default);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: var(--draggable-item-background-disabled);
      color: var(--text-disabled);
      svg path {
        fill: var(--icon-disabled);
      }
    `}
`;

export const Content = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex: 1;
  height: 100%;
  padding-left: 12px;

  &:hover {
    background-color: var(--draggable-item-background-hover);
  }

  &:active {
    background-color: var(--draggable-item-background-default);
    box-shadow: var(--shadow-medium) !important;
  }
`;

export const ToggleButton = styled.button`
  width: 43px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;

  > span {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 99px;
  }

  :hover {
    > span {
      background-color: var(--mono-0-4-per);
    }
  }

  :active {
    > span {
      background-color: var(--mono-0-8-per);
    }
  }
`;
