import { useCallback, useContext, useRef } from "react";
import moment from "moment";
import { LayerContext, LayerOptions } from "./LayerContext";

export const useLayer = (
  component: React.ReactNode,
  options?: LayerOptions
) => {
  const layerContext = useContext(LayerContext);
  const key = useRef<string>(
    (Math.random() + 1).toString(36).substring(7) + moment.now() // random key
  ).current;

  const open = useCallback(
    () =>
      layerContext.push({
        component,
        key,
        options: options ?? {}
      }),
    [component, key, layerContext, options]
  );

  const close = useCallback(
    () => layerContext.delete(key),
    [key, layerContext]
  );

  return { open, close };
};
