import React from "react";
import { ReactComponent as IconArrowRightRound } from "@resources/icons/svg/icon-arrow-right-round.svg";
import { TreeViewCollapseBtnProps } from "./Interfaces";
import * as S from "./TreeViewCollapse.styled";

const TreeViewCollapseBtn = ({
  isLeaf,
  disabled,
  expanded,
  onExpanded
}: TreeViewCollapseBtnProps) => {
  if (isLeaf) {
    return (
      <S.TreeViewCollapseBtnStyled>
        <span className="tree-view-icon" />
      </S.TreeViewCollapseBtnStyled>
    );
  }

  return (
    <S.TreeViewCollapseBtnStyled>
      <button
        type="button"
        className={`collapse-btn ${expanded && "expaneded"}`}
        disabled={disabled}
        onClick={() => onExpanded()}
        aria-label="button arrow up"
      >
        <IconArrowRightRound />
      </button>
    </S.TreeViewCollapseBtnStyled>
  );
};

export default TreeViewCollapseBtn;
