import useOrganization from "@hooks/useOrganization";
import { useGetPortalDevicesQuery } from "@queries/tanstack/device/DeviceQuery";
import _ from "lodash";
import { useMemo } from "react";
import {
  AI_TYPE,
  ANALYTICS_CHART_TYPE,
  ANALYTICS_CHART_VIEW_TYPE
} from "@resources/Constants";

import { ReactComponent as IconRuleVehicleQueueManagement } from "@resources/icons/svg/rule/icon-rule-vehicle-queue-management.svg";
import { ReactComponent as IconRuleShoppingCartQueueManagement } from "@resources/icons/svg/rule/icon-rule-shopping-cart-queue-management.svg";
import { ReactComponent as IconRuleVehicleCounting } from "@resources/icons/svg/rule/icon-rule-vehicle-counting.svg";
import { ReactComponent as IconRuleStoppedVehicle } from "@resources/icons/svg/rule/icon-rule-stopped-vehicle.svg";
import { ReactComponent as IconRuleShoppingCartCounting } from "@resources/icons/svg/rule/icon-rule-shopping-cart-counting.svg";
import { ReactComponent as IconRuleCrowd } from "@resources/icons/svg/rule/icon-rule-crowd.svg";
// import { ReactComponent as IconRuleForklift } from "@resources/icons/svg/rule/icon-rule-forklift.svg";
import { ReactComponent as IconRulePeopleCount } from "@resources/icons/svg/rule/icon-rule-people-counting.svg";
import { ReactComponent as IconRuleQueueManagement } from "@resources/icons/svg/rule/icon-rule-queue-management.svg";
import { ReactComponent as IconRuleSlipAndFall } from "@resources/icons/svg/rule/icon-rule-slip-and-fall.svg";
import { ReactComponent as IconRuleTrafficJam } from "@resources/icons/svg/rule/icon-rule-traffic-jam.svg";
import { RULE_CACHE_TIME_MS } from "./useAnalyticsRuleData";

export const getRuleType = (chartType: keyof typeof ANALYTICS_CHART_TYPE) => {
  switch (chartType) {
    case ANALYTICS_CHART_TYPE.CUMULATIVE_PEOPLE_COUNT:
    case ANALYTICS_CHART_TYPE.PEOPLE_OCCUPANCY:
    case ANALYTICS_CHART_TYPE.PEOPLE_FLOW:
      return AI_TYPE.PEOPLE_COUNT;
    case ANALYTICS_CHART_TYPE.PEOPLE_QUEUES:
      return AI_TYPE.QUEUE_MANAGEMENT;
    case ANALYTICS_CHART_TYPE.SLIP_AND_FALL:
      return AI_TYPE.SLIP_AND_FALL;
    case ANALYTICS_CHART_TYPE.CROWD_COUNTING:
      return AI_TYPE.CROWD_COUNT;
    case ANALYTICS_CHART_TYPE.CUMULATIVE_SHOPPING_CART_COUNT:
    case ANALYTICS_CHART_TYPE.SHOPPING_CARTS_IN_USE:
      return AI_TYPE.SHOPPING_CART_COUNT;
    case ANALYTICS_CHART_TYPE.SHOPPING_CART_QUEUES:
      return AI_TYPE.SHOPPING_CART_QUEUE_MANAGEMENT;
    case ANALYTICS_CHART_TYPE.CUMULATIVE_VEHICLE_COUNT:
      return AI_TYPE.VEHICLE_COUNT;
    case ANALYTICS_CHART_TYPE.VEHICLE_QUEUES:
      return AI_TYPE.VEHICLE_QUEUE_MANAGEMENT;
    case ANALYTICS_CHART_TYPE.STOPPED_VEHICLE_ANALYTICS:
      return AI_TYPE.STOPPED_VEHICLE;
    case ANALYTICS_CHART_TYPE.SLOW_TRAFFIC:
      return AI_TYPE.TRAFFIC_JAM;
    // case ANALYTICS_CHART_TYPE.FORKLIFT_SPEEDING:
    default:
      return null;
  }
};

export const getChartIcon = (chartType: keyof typeof ANALYTICS_CHART_TYPE) => {
  switch (chartType) {
    case ANALYTICS_CHART_TYPE.CUMULATIVE_PEOPLE_COUNT:
    case ANALYTICS_CHART_TYPE.PEOPLE_OCCUPANCY:
    case ANALYTICS_CHART_TYPE.PEOPLE_FLOW:
      return IconRulePeopleCount;
    case ANALYTICS_CHART_TYPE.PEOPLE_QUEUES:
      return IconRuleQueueManagement;
    case ANALYTICS_CHART_TYPE.SLIP_AND_FALL:
      return IconRuleSlipAndFall;
    case ANALYTICS_CHART_TYPE.CROWD_COUNTING:
      return IconRuleCrowd;
    case ANALYTICS_CHART_TYPE.CUMULATIVE_SHOPPING_CART_COUNT:
    case ANALYTICS_CHART_TYPE.SHOPPING_CARTS_IN_USE:
      return IconRuleShoppingCartCounting;
    case ANALYTICS_CHART_TYPE.SHOPPING_CART_QUEUES:
      return IconRuleShoppingCartQueueManagement;
    case ANALYTICS_CHART_TYPE.CUMULATIVE_VEHICLE_COUNT:
      return IconRuleVehicleCounting;
    case ANALYTICS_CHART_TYPE.VEHICLE_QUEUES:
      return IconRuleVehicleQueueManagement;
    case ANALYTICS_CHART_TYPE.STOPPED_VEHICLE_ANALYTICS:
      return IconRuleStoppedVehicle;
    case ANALYTICS_CHART_TYPE.SLOW_TRAFFIC:
      return IconRuleTrafficJam;
    // case ANALYTICS_CHART_TYPE.FORKLIFT_SPEEDING:
    default:
      return null;
  }
};

export const getChartData = (chartType: keyof typeof ANALYTICS_CHART_TYPE) => {
  switch (chartType) {
    case ANALYTICS_CHART_TYPE.CUMULATIVE_PEOPLE_COUNT:
    case ANALYTICS_CHART_TYPE.CUMULATIVE_VEHICLE_COUNT:
    case ANALYTICS_CHART_TYPE.CUMULATIVE_SHOPPING_CART_COUNT:
      return ANALYTICS_CHART_VIEW_TYPE.CUMULATIVE;
    case ANALYTICS_CHART_TYPE.PEOPLE_FLOW:
      return ANALYTICS_CHART_VIEW_TYPE.PEOPLE_FLOW;
    case ANALYTICS_CHART_TYPE.SLIP_AND_FALL:
    case ANALYTICS_CHART_TYPE.PEOPLE_OCCUPANCY:
    case ANALYTICS_CHART_TYPE.CROWD_COUNTING:
    case ANALYTICS_CHART_TYPE.SHOPPING_CARTS_IN_USE:
    case ANALYTICS_CHART_TYPE.SLOW_TRAFFIC:
      // case ANALYTICS_CHART_TYPE.FORKLIFT_SPEEDING:
      return [];
    case ANALYTICS_CHART_TYPE.SHOPPING_CART_QUEUES:
    case ANALYTICS_CHART_TYPE.VEHICLE_QUEUES:
    case ANALYTICS_CHART_TYPE.PEOPLE_QUEUES:
      return ANALYTICS_CHART_VIEW_TYPE.QUEUE;
    case ANALYTICS_CHART_TYPE.STOPPED_VEHICLE_ANALYTICS:
      return ANALYTICS_CHART_VIEW_TYPE.VEHICLE;
    default:
      return [];
  }
};

export const useGetRuleGroupByLocation = (rule: string[]) => {
  const { orgId } = useOrganization();
  const { data: deviceData, isLoading: isDeviceLoading } =
    useGetPortalDevicesQuery(
      {
        orgId,
        sightmindStatus: true
      },
      {
        enabled: !!orgId,
        staleTime: RULE_CACHE_TIME_MS,
        cacheTime: RULE_CACHE_TIME_MS
      }
    );

  const locations = useMemo(() => {
    if (rule.length > 0 && deviceData) {
      const convertedRule = rule.map((rule) => {
        const [ruleType, deviceId, channel, ruleName] = (rule as string).split(
          ":"
        );
        return {
          deviceId,
          ruleName,
          channel: Number(channel),
          ruleType
        };
      });

      const groupByRuleType = _.groupBy(convertedRule, "ruleType");
      const groupByIds = _.groupBy(deviceData, "id");
      return Object.keys(groupByRuleType)
        .map((key) => {
          const rules = groupByRuleType[key];
          const locations = _.compact(
            _.flatMap(rules, (rule) => {
              const deviceId = rule.deviceId;
              const dinfo = groupByIds[deviceId]?.[0];
              if (!dinfo) {
                return null;
              }

              const locationName = dinfo.locationName;
              const locationId = dinfo.locationId;
              if (locationName && locationId) {
                return {
                  ...rule,
                  locationId,
                  locationName
                };
              }

              return null;
            })
          );

          return { [key]: _.groupBy(locations, "locationName") };
        })
        .reduce((acc, item) => {
          return { ...acc, ...item };
        }, {});
    }

    return {};
  }, [deviceData, rule]);

  return {
    data: locations,
    isLoading: isDeviceLoading
  };
};
