import { useQuery } from "@tanstack/react-query";
import { GetPortalDevicesResponse } from "@queries/Devices";
import { licenseApis } from "@queries/License";
import { useMemo, useState } from "react";
import { generateDevicesInformation } from "@hooks/common/util";
import useWaveSyncData from "@hooks/useWaveSyncData";
import {
  AllPortalDevices,
  PortalDeviceWithLinkedLicenses
} from "@custom-types/Types";
import { ON_CLOUD_STATUS, WAVE_SYNC_STATUS } from "@resources/Constants";
import { useGetPortalDevicesQuery } from "@queries/tanstack/device/DeviceQuery";

export const useDevicesWithLinkedLicenses = (
  orgId: string,
  queryOptions?: Parameters<typeof useQuery>[2]
) => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const {
    waveSyncDevices,
    refetchWaveSyncData,
    isLoadingWaveSyncLinkedStatus
  } = useWaveSyncData(queryOptions);

  const {
    data: allDevices,
    refetch: refetchAllDevices,
    isLoading: isLoadingDevices,
    isError: isDeviceLoadingError
  } = useGetPortalDevicesQuery(
    { orgId, sightmindStatus: true },
    {
      enabled: !!orgId,
      ...(queryOptions as GetPortalDevicesResponse)
    }
  );

  const convertedAllDevices = useMemo(
    () =>
      (allDevices as GetPortalDevicesResponse)?.map(
        (device) =>
          ({
            ...device,
            linkedLicenses: {
              onCloud: ON_CLOUD_STATUS.LINK_DISCONNECT,
              waveSync: WAVE_SYNC_STATUS.LINK_DISCONNECT
            }
          }) as unknown as PortalDeviceWithLinkedLicenses
      ),
    [allDevices]
  );

  const {
    data: licenseList,
    refetch: refetchLicenseList,
    isLoading: isLoadingLicenses,
    isLoadingError: isLicenseLoadingError
  } = useQuery({
    queryKey: ["license-list"],
    queryFn: () => licenseApis.getLicenseList({ orgId, status: ["ALL"] }),
    enabled: !!orgId,
    staleTime: 5000,
    ...(queryOptions as GetPortalDevicesResponse)
  });

  const reloadAllData = async () => {
    setIsRefreshing(true);

    const response = await Promise.allSettled([
      refetchAllDevices(),
      refetchLicenseList(),
      refetchWaveSyncData()
    ]);

    setIsRefreshing(false);
    return !!response;
  };

  const allDevicesWithLinkedLicences = useMemo(() => {
    if (
      isRefreshing ||
      isLoadingDevices ||
      isLoadingLicenses ||
      isLoadingWaveSyncLinkedStatus
    )
      return [];

    return generateDevicesInformation<AllPortalDevices>(
      isDeviceLoadingError ? [] : convertedAllDevices || [],
      isLicenseLoadingError ? [] : licenseList || [],
      waveSyncDevices
    );
  }, [
    convertedAllDevices,
    isDeviceLoadingError,
    isLicenseLoadingError,
    isLoadingDevices,
    isLoadingLicenses,
    isLoadingWaveSyncLinkedStatus,
    isRefreshing,
    licenseList,
    waveSyncDevices
  ]);

  return {
    allDevicesWithLinkedLicences,
    isLoading:
      isLoadingWaveSyncLinkedStatus ||
      isLoadingLicenses ||
      isLoadingDevices ||
      isRefreshing,
    reloadAllData
  };
};
