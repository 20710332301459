/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  AxiosResponseHeaders,
  InternalAxiosRequestConfig,
  RawAxiosResponseHeaders
} from "axios";
import { getPortalURL, getURL } from "@utils/Common";
import { HttpStatus } from "@configs/HttpStatus";
import { REDIRECT_PARAM } from "@resources/Constants";

interface CustomAxiosResponse<T> {
  data: T;
  response?: T;
}

export interface CustomAxiosInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<InternalAxiosRequestConfig>;
    response: AxiosInterceptorManager<
      AxiosResponse<CustomAxiosResponse<any>, any>
    >;
  };

  get<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}

export const BASE_URL = `https://api.${getURL()}`;
let isRefreshing = false;
let refreshSubscribers: (() => void)[] = [];

const instance: CustomAxiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

instance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    newConfig.headers = {
      ...newConfig.headers
    } as AxiosRequestHeaders;
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const isJSONResponse = (
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
) => {
  return (
    headers["content-type"] &&
    headers["content-type"].includes("application/json")
  );
};

const isForbiddenUser = (errorResponseData: {
  message: string;
  error: string;
  statusCode: number;
}) => {
  return errorResponseData.message === "Forbidden resource";
};

instance.interceptors.response.use(
  (response) => {
    if (isJSONResponse(response.headers)) {
      return { ...response, data: response.data.data };
    }
    return { ...response, data: response.data };
  },
  (error) => {
    if (error.response) {
      console.error(error.response.data);
      if (error.response.status === HttpStatus.UNAUTHORIZED) {
        const originalRequest = error.config;
        if (error.response?.data?.code === 401000) {
          window.location.href = `https://${getPortalURL()}?${REDIRECT_PARAM}=${
            window.location.origin
          }`;
        }

        if (error.response?.data?.code === 401001 && !originalRequest.retry) {
          originalRequest.retry = true;

          if (!isRefreshing) {
            isRefreshing = true;
            instance
              .post(`/v1/auth/access-token/refresh`)
              .then(() => {
                isRefreshing = false;
                refreshSubscribers.forEach((callback) => {
                  callback();
                });
                refreshSubscribers = [];
              })
              .catch(() => {
                isRefreshing = false;

                window.location.href = `https://${getPortalURL()}?${REDIRECT_PARAM}=${
                  window.location.origin
                }`;
              });
          }

          return new Promise((res) => {
            refreshSubscribers.push(() => res(axios(originalRequest)));
          });
        }
      }
      if (
        error.response.status === HttpStatus.FORBIDDEN &&
        isForbiddenUser(error.response.data)
      ) {
        const currentUrl = window.location.href;
        if (
          currentUrl.includes("forbidden") &&
          currentUrl.includes(REDIRECT_PARAM)
        ) {
          window.location.href = currentUrl;
        } else {
          window.location.href = `/forbidden?${REDIRECT_PARAM}=${currentUrl}`;
        }
      }
    }

    return Promise.reject(error);
  }
);

export const Get = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  const { data } = await instance.get<AxiosResponse>(url, config);
  return data;
};

export const Post = async <T>(
  url: string,
  req?: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  const { data } = await instance.post<AxiosResponse>(url, req, config);
  return data;
};
export const PostWithStatusReturn = async (
  url: string,
  req?: any,
  config?: AxiosRequestConfig
): Promise<number> => {
  const { status } = await instance.post<AxiosResponse>(url, req, config);
  return status;
};

export const Put = async <T>(
  url: string,
  req?: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  const { data } = await instance.put<AxiosResponse>(url, req, config);
  return data;
};

export const Patch = async <T>(
  url: string,
  req?: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  const { data } = await instance.patch<AxiosResponse>(url, req, config);
  return data;
};

export const Delete = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  const { data } = await instance.delete<AxiosResponse>(url, config);
  return data;
};
