import styled from "styled-components";

export const CheckboxStyled = styled.label`
  display: flex;
  align-items: center;
  position: relative;

  input[type="checkbox"],
  input[type="checkbox"]:checked {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
  }

  input {
    cursor: pointer;
    height: 13px;
    width: 13px;
    left: 1px;

    &:checked,
    &:indeterminate {
      & ~ .checkbox {
        border: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        svg {
          display: block;
        }
      }
      &:disabled {
        & ~ .checkbox {
          border-color: var(--border-checkbox-disabled);
          background-color: var(--background-ibb-disabled);

          svg {
            display: block;

            path,
            rect {
              fill: none;
              stroke: var(--icon-disabled);
            }

            rect:nth-child(3) {
              fill: var(--icon-disabled);
            }
          }
        }
      }
    }

    &:disabled {
      & ~ .checkbox {
        cursor: not-allowed;
        border-color: var(--border-checkbox-disabled);
        background-color: var(--background-ibb-disabled);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & ~ .label {
        color: var(--text-disabled);
      }
    }
  }
`;

export const StyledCheckboxIconWrapper = styled.span`
  position: absolute;
  height: 14px;
  width: 14px;
  background-color: var(--background-checkbox-default);
  border: 1px solid var(--border-checkbox-default);
  border-radius: 2px;

  svg {
    width: 50px;
    height: 16px;
    display: none;
  }

  &:hover {
    border: 1px solid var(--border-checkbox-hover);
  }
`;

export const StyledCheckboxLabel = styled.span`
  font-size: 14px;
  color: var(--text-primary);
  padding-left: 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;
