import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { waveApis, WaveSyncDevice, WaveSyncStatus } from "../../Wave";
import { keys } from "./keys";

export const useGetWavesyncUnlinkQuery = (
  orgId: string,
  options?: Parameters<typeof useQuery>[2]
) => {
  return useQuery(keys.getUnlik(), () => waveApis.getUnlink(orgId), options);
};

export const useGetWavesyncStatusQuery = (
  orgId: string,
  options?: UseQueryOptions<WaveSyncStatus>
) => {
  return useQuery<WaveSyncStatus>(
    keys.getStatus(),
    () => waveApis.wavesyncStatus(orgId),
    options
  );
};

export const useGetWavesyncDeviceQuery = (
  orgId: string,
  options?: UseQueryOptions<WaveSyncDevice[]>
) => {
  return useQuery<WaveSyncDevice[]>(
    keys.getDevice(),
    () => waveApis.wavesyncDevice(orgId),
    options
  );
};

interface GetWavesyncVideoParams {
  orgId: string;
  systemId: string;
  deviceId: string;
  timestamp?: string;
}
export const useGetWavesyncVideo = (
  params: GetWavesyncVideoParams,
  options?: Parameters<typeof useQuery>[2]
) => {
  const { orgId, systemId, deviceId, timestamp } = params;
  return useQuery(
    keys.getVideo(deviceId, timestamp ?? ""),
    () => waveApis.wavesyncVideo(orgId, systemId, deviceId, timestamp),
    options
  );
};
