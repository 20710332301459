import { THEME_TYPE } from "@resources/Constants";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  background-color: var(--surface-widget);
  border-radius: 12px;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 700;
  color: var(--text-primary);
`;

export const TopMenu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 16px;
`;

export const MenuButton = styled.button<{
  disabled?: boolean;
  isActive?: boolean;
}>`
  height: 32px;
  border: 1px solid var(--border-default);
  color: var(--text-primary);
  font-size: 14px;
  background-color: var(--surface-modal);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  gap: 4px;

  &:hover,
  &:focus {
    border: 1px solid var(--border-emphasis);
  }

  ${({ isActive }) =>
    !isActive &&
    css`
      &:hover {
        &[data-tooltip]:before {
          position: absolute;
          content: attr(data-tooltip);
          font-size: 13px;
          top: -35px;
          color: var(--text-primary);
          padding: 0 8px;
          height: 28px;
          line-height: 28px;
          box-sizing: border-box;
          border: 1px solid var(--border-default);
          background-color: var(--surface-modal);
          border-radius: 8px;
        }
      }
    `};

  > svg path {
    fill: var(--icon-default);
  }

  > svg circle {
    fill: var(--icon-default);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: var(--background-button-disabled) !important;
      border: 1px solid var(--border-default) !important;
      color: var(--text-disabled) !important;

      > svg path {
        fill: var(--icon-disabled) !important;
      }

      > svg circle {
        fill: var(--icon-disabled) !important;
      }
    `};
`;

export const SelectWrap = styled.div`
  > div {
    width: 200px;
    > div {
      width: 400px;
    }
  }
`;

export const SideMenu = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const LeftSideMenu = styled(SideMenu)``;
export const RightSideMenu = styled(SideMenu)``;

export const SearchButton = styled(MenuButton)`
  height: 32px;
  background-color: var(--orange-primary);
  color: var(--white);
  border: none;
`;

export const Divider = styled.div<{ height: number }>`
  width: 1px;
  margin: 0 4px;
  height: ${(props) => props.height}px;
  background-color: var(--border-emphasis);
  margin: 0 4px;
`;

export const RuleCountBox = styled.div`
  height: 20px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) =>
    theme.type === THEME_TYPE.DARK
      ? css`
          border: 1px solid var(--border-emphasis);
          background-color: #ffffff0d;
        `
      : css`
          background-color: var(--mono-10);
        `};
`;

export const LayerWrap = styled.div`
  position: absolute;
  z-index: 10;
  top: 36px;
  left: 0px;
`;

export const DropDownList = styled.ul`
  position: absolute;
  top: 36px;
  left: 0px;

  background-color: var(--surface-popup);
  box-shadow: 0px 2px 2px 0px #0000001a;
  border: 1px solid var(--border-default);
  border-radius: 4px;
  > li {
    color: var(--text-primary);
    height: 32px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 400;

    :hover {
      background-color: var(--tree-item-hover);
    }
  }
`;

export const InputTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: var(--text-secondary-1);
`;
