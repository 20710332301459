import { WidgetType } from "@custom-types/Types";
import { useMemo } from "react";
import _ from "lodash";
import { WidgetRuleItem } from "@components/widgets/base/setup/widget-rules/Interfaces";
import { useRuleData } from "@hooks/widget-rules/useRuleData";
import { useQuery } from "@tanstack/react-query";

export const RULE_CACHE_TIME_MS = 1000 * 60 * 60;

interface AnalyticsRuleDataParams {
  widgetType?: WidgetType;
  inValidateCacheOnUnmount?: boolean;
}

export const useAnalyticsRuleData = (
  param: AnalyticsRuleDataParams,
  queryOptions?: Parameters<typeof useQuery>[2]
) => {
  const { widgetType } = param;
  const { data, isLoading } = useRuleData(
    {
      widgetType,
      inValidateCacheOnUnmount: param.inValidateCacheOnUnmount
    },
    {
      staleTime: RULE_CACHE_TIME_MS,
      cacheTime: RULE_CACHE_TIME_MS,
      ...queryOptions
    }
  );

  // re-groupBy location and area
  const converted = useMemo(() => {
    if (data.length > 0) {
      const locationData = _.map(data as WidgetRuleItem[], (v) => ({
        ...v,
        location: v.metaData?.location,
        area: v.metaData?.area
      }));

      const groupByLocation = _.groupBy(locationData, (v) => v.location);
      const groupByLocationAndArea = _.mapValues(groupByLocation, (items) =>
        _.groupBy(items, (v) => v.area)
      );

      return [
        {
          title: "All",
          label: "All",
          value: "All",
          children: Object.keys(groupByLocationAndArea).map((locationKey) => {
            const children = Object.keys(
              groupByLocationAndArea[locationKey]
            ).map((areaKey) => {
              return {
                title: areaKey,
                label: areaKey,
                value: `${locationKey}_${areaKey}`,
                children: groupByLocationAndArea[locationKey][areaKey],
                metaData: {
                  name: areaKey
                }
              };
            });

            return {
              title: locationKey,
              label: locationKey,
              value: locationKey,
              children,
              metaData: {
                name: locationKey
              }
            };
          })
        }
      ];
    }

    return [];
  }, [data]);

  return {
    data: converted,
    originData: data,
    isLoading
  };
};
