import React, { useState } from "react";
import { ReactComponent as IconDrag } from "@resources/icons/svg/analytics/icon-drag.svg";
import { ReactComponent as IconEyeOnLine } from "@resources/icons/svg/analytics/icon-eye-on-line.svg";
import { ReactComponent as IconEyeOffLine } from "@resources/icons/svg/analytics/icon-eye-off-line.svg";

import * as S from "./OrderItem.styled";

interface OrderItemProps {
  title: string;
  icon: React.ReactNode | null;
  defaultIsShow: boolean;
  onChangeShow: (isShow: boolean) => void;
}
export const OrderItem = (props: OrderItemProps) => {
  const [isShow, setIsShow] = useState(props.defaultIsShow);
  const handleToggle = () => {
    const nextIsShow = !isShow;
    setIsShow(nextIsShow);
    props.onChangeShow(nextIsShow);
  };

  return (
    <S.Container disabled={!isShow}>
      <S.Content className="drag-handle">
        <IconDrag />
        {props.icon}
        <span>{props.title}</span>
      </S.Content>
      <S.ToggleButton onClick={handleToggle}>
        <span>{isShow ? <IconEyeOnLine /> : <IconEyeOffLine />}</span>
      </S.ToggleButton>
    </S.Container>
  );
};
