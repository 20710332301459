import { Delete, Get, Patch, Post, Put } from "@configs/Axios";
import {
  ChannelInfo,
  DeviceStatus,
  DeviceType,
  OpenAppInfo,
  Recording,
  VmsInfo
} from "@custom-types/Types";
import { AI_TYPE } from "@resources/Constants";
import _ from "lodash";
import type { DeviceSetupColumn } from "../pages/setup/device/device-setup/Interfaces";

export type GetDeviceRequest = {
  deviceId: string;
};

export type DeleteDeviceRequest = {
  deviceId: string;
};

export interface GetDeviceResponse {
  ip: string;
  mac: string;
  area: string;
  location: string;
  serial: string;
  waveSync: boolean;
  widgetIds: string[];
  model: string;
  userId: string;
  password: string;
  name: string;
  maxChannel: number;
  channelInfo: ChannelInfo[];
  openAppInfo: OpenAppInfo[];
  status: DeviceStatus;
  deviceId: string;
  port: number;
  recording: Recording;
  type?: DeviceType;
  vms?: VmsInfo;
  licenceInfo?: {
    retentionPeriodType: string;
    expiryDate: string;
  };
}

export type CreateDeviceResponse = GetDeviceResponse;

export type GetDevicesRequest = {
  searchText?: string;
  limit?: number;
  page?: number;
  orderKey?: string;
  order?: string;
  type?: DeviceType;
};

export type GetDevicesResponse = {
  devices: GetDeviceResponse[];
  total: number;
};

export type AuthenticateDeviceRequest = {
  deviceId: string;
  userId: string;
  password: string;
};
export type AuthenticateDeviceResponse = Omit<GetDeviceResponse, "password">;

export type SnapshotResponse = {
  contentType: string;
  content: string;
};

export type ConnectionTestDeviceRequest = {
  startIp: string;
  endIp: string;
  userId: string;
  password: string;
};
export type CreateDeviceRequest = GetDeviceResponse;
export type ConnectionTestDeviceResponse = {
  success: GetDeviceResponse[];
  failure: {
    ip: string;
    status: number;
  }[];
};

export type CreateWaveDeviceRequest = {
  vmsId: string;
  waveDeviceId: string;
};

type GetSnapshotRequest = {
  channel: number;
};

export type DeviceLicenseInfo = {
  retentionPeriodType: string;
  expiryDate: string;
};

type PortalDevicesConfig = {
  gatewayId: string;
  orgId: string;
  channelInfo: ChannelInfo[];
  model?: string;
  name?: string;
  licenseInfo: DeviceLicenseInfo;
};

export type RefreshDeviceResponse = GetDeviceResponse;

export type UpdateDeviceRequest = GetDeviceResponse;

export type GetPortalDevicesResponse = DeviceSetupColumn[];

type GetDeviceConfigResponse = PortalDevicesConfig;

export type GetPortalDevicesPaginationResponse = {
  data: DeviceSetupColumn[];
  total: number;
  limit: number;
  page: number;
};

export type GetPortalDevicesConfigResponse = PortalDevicesConfig[];

export type ClaimDeviceParam = {
  gatewayId?: string;
  deviceId?: string;
  model?: string;
  name?: string;
};

export type ClaimDevices = {
  deviceIds: ClaimDeviceParam[];
};

interface PortalDeviceConfigOptions {
  ruleTypes?: keyof typeof AI_TYPE;
  claimed?: "all" | "true" | "false";
}

export interface PortalDevicesConfigParms {
  orgId: string;
  options?: PortalDeviceConfigOptions;
}

export const devicesApis = {
  getAll: async (req: GetDevicesRequest) => {
    return Get<GetDevicesResponse>("/v1/devices", {
      params: req
    });
  },
  get: async ({ deviceId }: GetDeviceRequest) => {
    return Get<GetDeviceResponse>(`/v1/devices/${deviceId}`);
  },

  create: async (req: CreateDeviceRequest, signal?: AbortSignal) => {
    return Post<CreateDeviceResponse>(`/v1/devices`, req, { signal });
  },

  delete: async ({ deviceId }: DeleteDeviceRequest, signal?: AbortSignal) => {
    return Delete<void>(`/v1/devices/${deviceId}`, { signal });
  },

  connectionTest: async ({
    startIp,
    endIp,
    ...req
  }: ConnectionTestDeviceRequest) => {
    try {
      if (startIp && endIp) {
        return Post<ConnectionTestDeviceResponse>(
          `/v1/devices/test?startIp=${startIp}&endIp=${endIp}`,
          req
        );
      }
      return Post<ConnectionTestDeviceResponse>(
        `/v1/devices/test?Ip=${startIp}`,
        req
      );
    } catch (error) {
      throw new Error();
    }
  },

  createWaveDevice: async (
    { vmsId, waveDeviceId }: CreateWaveDeviceRequest,
    signal?: AbortSignal
  ) => {
    return Post<GetDeviceResponse>(
      `/v1/devices/wave/${vmsId}/${waveDeviceId}`,
      {
        signal
      }
    );
  },
  authenticate: async (
    { deviceId, ...req }: AuthenticateDeviceRequest,
    signal?: AbortSignal
  ) => {
    return Patch<AuthenticateDeviceResponse>(`/v1/devices/${deviceId}`, req, {
      signal
    });
  },
  refresh: async (orgId: string, deviceIds: ClaimDeviceParam[]) => {
    return Put<RefreshDeviceResponse>(`/v3/organizations/${orgId}/devices`, {
      deviceIds
    });
  },

  update: async ({ deviceId, ...req }: UpdateDeviceRequest) => {
    return Put<GetDeviceResponse>(`/v1/devices/${deviceId}`, req);
  },

  claimAllDevices: async (
    orgId: string
  ): Promise<GetPortalDevicesConfigResponse> => {
    return Put<GetPortalDevicesConfigResponse>(
      `/v3/organizations/${orgId}/devices/all`
    );
  },

  /// from portal device

  getPortalDevicesConfig: async (params: PortalDevicesConfigParms) => {
    const { orgId, options = {} } = params;
    const qs = new URLSearchParams(_.omitBy(options, _.isNil)).toString();
    return Get<GetPortalDevicesConfigResponse>(
      `/v3/organizations/${orgId}/devices/configs${qs ? `?${qs}` : ""}`
    );
  },
  getAllPortalDevices: async (orgId: string) => {
    return Get<GetPortalDevicesResponse>(`/v3/organizations/${orgId}/devices`);
  },
  getPortalDevices: async (orgId: string, sightmindStatus: boolean) => {
    return Get<GetPortalDevicesResponse>(
      `/v3/organizations/${orgId}/devices?sightmindStatus=${sightmindStatus}`
    );
  },
  getDeviceConfig: async (orgId: string, gatewayId: string) => {
    return Get<GetDeviceConfigResponse>(
      `/v3/organizations/${orgId}/devices/${gatewayId}/config`
    );
  },

  getPortalDevicesWithPagination: async (
    orgId: string,
    sightmindStatus: boolean,
    page: number
  ) => {
    return Get<GetPortalDevicesPaginationResponse>(
      `/v3/organizations/${orgId}/devices?sightmindStatus=${sightmindStatus}&page=${page}&limit=100`
    );
  },

  claimDevices: async (orgId: string, { deviceIds }: ClaimDevices) => {
    return Post<ClaimDevices>(`/v3/organizations/${orgId}/devices`, {
      deviceIds
    });
  },

  unClaimDevice: async (
    orgId: string,
    { deviceIds }: { deviceIds: ClaimDeviceParam }
  ) => {
    return Delete<{ deviceId: string }>(
      `/v3/organizations/${orgId}/devices/${deviceIds.deviceId}?gatewayId=${deviceIds.gatewayId}`
    );
  },

  getSnapshot: async (
    orgId: string,
    deviceId: string,
    { ...req }: GetSnapshotRequest
  ) => {
    if (!orgId || !deviceId) return "";
    return Get<SnapshotResponse>(
      `/v3/organizations/${orgId}/devices/${deviceId}/snapshot`,
      {
        params: req
      }
    );
  }
};
