import React, { useEffect, useMemo, useRef } from "react";
import { useLang } from "@hooks/useLang";
import { useTheme } from "styled-components";
import {
  CalendarModeRangeType,
  TimeInterval,
  TypeTimeInterval
} from "@custom-types/Types";
import { Select, Option } from "@components/common/select";
import { useAnalyticsChartSelectMenu } from "@hooks/useChartSelectMenu";
import {
  chartTooltipFormatter,
  getAxisIntervalLabels,
  numberSymbol,
  xAxisLabelFormatter
} from "@utils/Widget";
import { ReactComponent as IconMore } from "@resources/icons/svg/icon-more.svg";
import HandleIcon from "@resources/icons/svg/analytics/icon-handle.svg";
import ECharts from "@components/common/chart";
import {
  EChartsOption,
  ECharts as EchartType,
  SeriesOption,
  TooltipComponentFormatterCallbackParams
} from "echarts";
import {
  ANALYTICS_CHART_VIEW_TYPE_ALL,
  ANALYTICS_CHART_TYPE,
  BUTTON_STYLE_TYPE,
  MAX_VISIBLE_LEGENDS,
  ANALYTICS_CHART_VIEW_TYPE
} from "@resources/Constants";
import { Theme } from "@themes/Types";
import _, { isArray } from "lodash";
import { AnalyticsDataResponse } from "@queries/Analytics";
import { Checkbox } from "@components/common/checkbox";
import { Button } from "@components/common/button";
import useDetectOutsideClick from "@hooks/useDetectOutsideClick";
import { IconPlus, IconRuleVehicle } from "@resources/icons/Icons";
import ScrollBar from "@components/common/scrollbar";
import * as S from "./ChartCard.styled";
import { ChartCardProps } from "./Interfaces";

const ChartTypeList = ["Line"] as const;
type ChartType = (typeof ChartTypeList)[number];
type ViewType = string;

const convertSvgToBase64 = (svgContent: string): string => {
  return `data:image/svg+xml;base64,${btoa(
    unescape(encodeURIComponent(svgContent))
  )}`;
};

const changeSvgColor = (
  svgContent: string,
  fillColor: string,
  pathStrokeColor: string,
  rectStrokeColor: string
): string => {
  let updatedSvg = svgContent;

  updatedSvg = updatedSvg.replace(/fill="([^"]+)"/g, `fill="${fillColor}"`);
  updatedSvg = updatedSvg.replace(
    /(<path[^>]+stroke=")([^"]+)(")/g,
    `$1${pathStrokeColor}$3`
  );

  updatedSvg = updatedSvg.replace(
    /(<rect[^>]+stroke=")([^"]+)(")/g,
    `$1${rectStrokeColor}$3`
  );
  return updatedSvg;
};

function Presenter(props: ChartCardProps): JSX.Element {
  const {
    icon,
    title,
    baseTime,
    options,
    analyticsData,
    selected = [],
    setSelected,
    viewType,
    setViewType,
    timeInterval,
    typeInterval,
    setType
  } = props;
  const { translate } = useLang();
  const theme = useTheme();
  const chartTypeRef = useRef<HTMLDivElement | null>(null);
  const intervalMenuList = useAnalyticsChartSelectMenu({
    baseTime
  });
  const { mode, start, end } = baseTime;
  const result = getAxisIntervalLabels(mode, start, end, timeInterval);
  const { labels: xAxisLabels } = result;
  const [chartInstance, setChartInstance] = React.useState<EchartType>();
  const [chartType, setChartType] = React.useState<ChartType>("Line");
  const [iconHandle, setIconHandle] = React.useState<string>();
  const [showVechicleMenu, setShowVechicleMenu] =
    React.useState<boolean>(false);
  const [currentSelected, setCurrentSelected] = React.useState(selected);
  const analyticsRef = useRef<HTMLDivElement | null>(null);
  const [showDropDownList, setShowDropDownList] =
    React.useState<boolean>(false);
  const [activeLocations, setActiveLocations] = React.useState<Set<string>>(
    new Set()
  );

  const fetchSvgAndConvertToBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const svgContent = await response.text();
    const updatedSvg = changeSvgColor(
      svgContent,
      theme.chart.dataZoomHandleIconColor,
      theme.chart.dataZoomMoveHandleIconBorderColor,
      theme.chart.dataZoomMoveHandleIconOutBorderColor
    );
    return convertSvgToBase64(updatedSvg);
  };

  useEffect(() => {
    fetchSvgAndConvertToBase64(HandleIcon)
      .then((base64IconHandle) => {
        setIconHandle(base64IconHandle);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [theme]);

  const generateSeries = (
    analyticsData: AnalyticsDataResponse[]
  ): SeriesOption[] => {
    return analyticsData
      .filter((item) => !activeLocations.has(item.locationName))
      .map((item) => ({
        name: item.locationName,
        type: "line",
        showSymbol: item?.chartData.length === 1,
        data: item?.chartData,
        color: item?.color
      }));
  };

  const tooltipSettingLine = (
    theme: Theme,
    timeInterval: TimeInterval,
    mode: CalendarModeRangeType
  ): EChartsOption["tooltip"] => ({
    trigger: "axis",
    formatter: (params: TooltipComponentFormatterCallbackParams) => {
      if (isArray(params)) {
        return chartTooltipFormatter(
          timeInterval,
          params[0].name,
          params.map((item) => ({
            label: item.seriesName || "",
            data: String(item.data),
            color: String(item.color)
          })),
          "",
          mode
        );
      }
      return "";
    },
    backgroundColor:
      theme.widget.stoppedVehicleStatistics.backgroundColorTooltip,
    borderColor: theme.widget.stoppedVehicleStatistics.borderColorTooltip,
    axisPointer: {
      lineStyle: {
        type: "dotted",
        width: 2,
        color: theme.widget.stoppedVehicleStatistics.chartSelectLine
      }
    },
    textStyle: {
      fontSize: 12,
      fontFamily: "Pretendard",
      color: theme.widget.stoppedVehicleStatistics.textPrimary
    },
    padding: [5, 6]
  });
  const chartOptions: EChartsOption = useMemo(
    () => ({
      tooltip: tooltipSettingLine(theme, timeInterval, mode),
      legend: {
        show: false
      },
      dataZoom: [
        {
          height: 32,
          type: "slider",
          show: false,
          borderColor: theme.chart.dataZoomBorderColor,
          borderRadius: 2,
          fillerColor: theme.chart.dataZoomFillerColor,
          moveHandleStyle: {
            borderColor: theme.chart.dataZoomMoveHandleBorderColor,
            color: theme.chart.dataZoomMoveHandleColor,
            cursor: "default"
          },
          handleIcon: `image://${iconHandle}`,
          moveHandleIcon:
            "path://M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10ZM19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z",

          emphasis: {
            moveHandleStyle: {
              color: theme.chart.dataZoomMoveHandleColor
            },
            handleStyle: {
              color: theme.chart.dataZoomMoveHandleColor
            }
          },
          handleSize: 20,
          textStyle: {
            show: false,
            color: "transparent"
          },
          selectedDataBackground: {
            areaStyle: {
              opacity: 0
            },
            lineStyle: {
              opacity: 0
            }
          },
          dataBackground: {
            areaStyle: {
              opacity: 0
            },
            lineStyle: {
              opacity: 0
            }
          },
          brushStyle: {
            color: theme.chart.dataZoomBrushStyle
          }
        }
      ],
      grid: {
        left: 25,
        right: 25,
        containLabel: true,
        show: true,
        borderColor: "transparent"
      },
      toolbox: {
        show: false
      },
      xAxis: [
        {
          type: "category",
          nameTextStyle: {
            color: theme.chart.colorAxisLabel,
            fontSize: "12px",
            fontFamily: "Pretendard",
            verticalAlign: "top",
            lineHeight: 25,
            padding: [1, 0, 0, 0]
          },
          nameGap: 11,
          data: xAxisLabels,
          boundaryGap: false,
          axisLabel: {
            color: theme.chart.colorAxisLabel,
            fontSize: "12px",
            fontFamily: "Pretendard",
            formatter: xAxisLabelFormatter(timeInterval, mode)
          },
          axisLine: {
            show: false
          },
          axisTick: {
            lineStyle: {
              color: theme.widget.stoppedVehicleStatistics.graphLineColor,
              width: 1
            }
          }
        }
      ],
      yAxis: {
        type: "value",
        nameTextStyle: {
          color: theme.chart.colorAxisLabel,
          fontSize: "12px",
          fontFamily: "Pretendard",
          align: "right",
          padding: [0, 6, 0, 0]
        },
        nameGap: 12,
        splitNumber: 2,
        axisLabel: {
          color: theme.chart.colorAxisLabel,
          fontSize: "12px",
          fontFamily: "Pretendard",
          align: "right",
          hideOverlap: false,
          formatter:
            props.viewType === "wait_time"
              ? "{value}s"
              : (value) => {
                  const [inNumber, inSymbol] = numberSymbol(value);
                  return `${inNumber}${inSymbol}`;
                }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: theme.chart.splitLineColor,
            width: 1
          }
        }
      },
      series: generateSeries(analyticsData)
    }),
    [iconHandle, theme, activeLocations, analyticsData]
  );

  const toggleDataZoom = (value: boolean) => () => {
    chartInstance?.setOption({
      dataZoom: [
        {
          show: value
        }
      ]
    });
  };

  const locationHighlightChart = (locationName: string | null) => () => {
    if (locationName && activeLocations.has(locationName)) {
      return;
    }
    const updatedData = analyticsData
      .filter((item) => !activeLocations.has(item.locationName))
      .map((seriesItem, index) => {
        const isHighlighted =
          locationName && seriesItem.locationName.includes(locationName);
        return {
          ...seriesItem,
          z: isHighlighted ? analyticsData.length : index,
          lineStyle: {
            opacity: isHighlighted || !locationName ? 1 : 0.2
          }
        };
      });
    chartInstance?.setOption({
      series: updatedData,
      dataZoom: [
        {
          show: true
        }
      ]
    });
  };

  useEffect(() => {
    chartInstance?.on("dataZoom", () => {
      chartInstance?.setOption({
        dataZoom: [
          {
            type: "slider",
            show: true
          }
        ]
      });
    });
  }, [chartInstance]);

  const handleCheckboxChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    label: string
  ) => {
    if (label === ANALYTICS_CHART_VIEW_TYPE_ALL) {
      if (evt.currentTarget.checked && options?.viewList) {
        options?.viewList && setCurrentSelected?.(options?.viewList);
      } else {
        setCurrentSelected?.([]);
      }
    } else {
      const [_, ...withoutAll] = options?.viewList || [];
      const updatedSelected = evt.currentTarget.checked
        ? [...currentSelected, label]
        : currentSelected.filter(
            (item) => item !== ANALYTICS_CHART_VIEW_TYPE_ALL && item !== label
          );

      if (updatedSelected.length === withoutAll.length && options?.viewList) {
        setCurrentSelected?.(options?.viewList);
      } else {
        setCurrentSelected?.(updatedSelected);
      }
    }
  };

  const handleVehicleTypeOK = () => {
    setSelected?.(currentSelected);
    setShowVechicleMenu(false);
  };

  useDetectOutsideClick(chartTypeRef, () => setShowVechicleMenu(false));
  useDetectOutsideClick(analyticsRef, () => setShowDropDownList(false));

  const getSelectedVehicleTypeCount = () => {
    if (selected.includes(ANALYTICS_CHART_VIEW_TYPE_ALL)) {
      return selected.length - 1;
    }

    return selected.length;
  };

  const updateSetTimeInterval = (value: TypeTimeInterval) => {
    setType?.(value);
    props.updatedAnalytics?.([], value, null);
  };

  const updatedLocationName = (locationName: string) => {
    setActiveLocations((prev) => {
      const updated = new Set(prev);
      if (updated.has(locationName)) {
        updated.delete(locationName);
      } else {
        updated.add(locationName);
      }
      return updated;
    });
  };

  const legendShowData = () => {
    return [...analyticsData.slice(0, MAX_VISIBLE_LEGENDS)].map((data) => {
      return (
        <S.LegendItem
          onClick={() => updatedLocationName(data.locationName)}
          disabled={activeLocations.has(data.locationName)}
          onMouseEnter={locationHighlightChart(data.locationName)}
          onMouseLeave={locationHighlightChart(null)}
        >
          <S.LegendCircle
            style={{
              backgroundColor: (data as AnalyticsDataResponse).color as string
            }}
          />
          {data.locationName}
        </S.LegendItem>
      );
    });
  };
  const legendHideData = () => {
    return [
      ...analyticsData.slice(MAX_VISIBLE_LEGENDS, analyticsData.length)
    ].map((data) => {
      return (
        <S.LegendDropdownItem
          onClick={() => updatedLocationName(data.locationName)}
          disabled={activeLocations.has(data.locationName)}
          onMouseEnter={locationHighlightChart(data.locationName)}
          onMouseLeave={locationHighlightChart(null)}
        >
          <S.LegendCircle
            style={{
              backgroundColor: (data as AnalyticsDataResponse).color as string
            }}
          />
          {data.locationName}
        </S.LegendDropdownItem>
      );
    });
  };

  useEffect(() => {
    if (chartInstance) {
      chartInstance.setOption(chartOptions, true);
    }
  }, [chartInstance, chartOptions]);

  return (
    <S.Container>
      <S.TopMenu>
        <S.LeftSideMenu>
          <S.IconWrap>{icon}</S.IconWrap>
          {title}
        </S.LeftSideMenu>
        <S.RightSideMenu>
          {(() => {
            if (!options?.viewList || options?.viewList.length === 0) {
              return null;
            }

            if (props.type === ANALYTICS_CHART_TYPE.STOPPED_VEHICLE_ANALYTICS) {
              return (
                <S.ChartTypeContent ref={chartTypeRef}>
                  <S.ChartTypeIcon>
                    <IconRuleVehicle />
                  </S.ChartTypeIcon>
                  <S.ChartTypeButton onClick={() => setShowVechicleMenu(true)}>
                    {translate("vehicle_type")}
                  </S.ChartTypeButton>
                  {showVechicleMenu && (
                    <S.ChartTypeDropdown>
                      <S.ChartTypeCheckboxGroup>
                        {options?.viewList?.map((label) => (
                          <S.ChartTypeCheckbox>
                            <Checkbox
                              key={label}
                              checked={
                                label === ANALYTICS_CHART_VIEW_TYPE_ALL
                                  ? currentSelected.length ===
                                    options.viewList.length
                                  : currentSelected.includes(label)
                              }
                              onChange={(evt) =>
                                handleCheckboxChange(evt, label)
                              }
                              data-testid={`filter-checkbox-${label}`}
                            />
                            <S.ChartTypeLabel>
                              {translate(label)}
                            </S.ChartTypeLabel>
                          </S.ChartTypeCheckbox>
                        ))}
                      </S.ChartTypeCheckboxGroup>
                      <S.ButtonWrap>
                        <Button
                          styleType={BUTTON_STYLE_TYPE.FOURTH}
                          label={translate("ok")}
                          onClick={handleVehicleTypeOK}
                          disabled={!currentSelected.length}
                        />
                      </S.ButtonWrap>
                    </S.ChartTypeDropdown>
                  )}

                  <S.ChartTypeCount>
                    {selected.length > 100
                      ? "99+"
                      : getSelectedVehicleTypeCount()}
                  </S.ChartTypeCount>
                </S.ChartTypeContent>
              );
            }

            // Default
            return (
              <Select<ViewType>
                defaultValue={viewType}
                data={
                  _.map(options?.viewList, (type) => ({
                    value: type,
                    label: translate(type)
                  })) as Option<ViewType>[]
                }
                onChange={(value) => {
                  value && setViewType && setViewType(value);
                  if (
                    value &&
                    ANALYTICS_CHART_VIEW_TYPE.CUMULATIVE.includes(value)
                  ) {
                    props.updatedAnalytics?.([], null, value);
                  }
                }}
              />
            );
          })()}
          <Select<ChartType>
            defaultValue={chartType}
            data={
              _.map(ChartTypeList, (type) => ({
                value: type,
                label: type
              })) as Option<ChartType>[]
            }
            onChange={(value) => {
              value && setChartType(value);
            }}
          />
          <Select<TypeTimeInterval>
            defaultValue={typeInterval}
            data={intervalMenuList}
            onChange={(value) => {
              value && updateSetTimeInterval(value);
            }}
          />
          <S.Divider />
          <S.MoreButton disabled>
            <IconMore width={16} height={16} />
          </S.MoreButton>
        </S.RightSideMenu>
      </S.TopMenu>
      <S.ChartWrap>
        <S.LegendContainer>
          {legendShowData()}
          <div ref={analyticsRef}>
            {analyticsData.length > MAX_VISIBLE_LEGENDS && (
              <S.LegendMore>
                <S.LegendButton
                  onClick={() => setShowDropDownList(!showDropDownList)}
                >
                  <IconPlus width={12} height={12} />
                  {analyticsData.length - MAX_VISIBLE_LEGENDS} more
                </S.LegendButton>
                {showDropDownList && (
                  <S.LegendDropdown>
                    <ScrollBar>{legendHideData()}</ScrollBar>
                  </S.LegendDropdown>
                )}
              </S.LegendMore>
            )}
          </div>
        </S.LegendContainer>

        <div
          style={{
            width: "100%",
            height: "100%",
            paddingRight: 16
          }}
          onMouseEnter={toggleDataZoom(true)}
          onMouseLeave={toggleDataZoom(false)}
        >
          <ECharts
            option={chartOptions}
            onLoad={setChartInstance}
            style={{
              height: "400px"
            }}
          />
        </div>
      </S.ChartWrap>
    </S.Container>
  );
}

export const ChartCardPresenter = React.memo(Presenter);
