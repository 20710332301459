import React, { useCallback, useRef, useState } from "react";
import { FlatNodeItem } from "@components/common/tree-view/Interfaces";
import { Button } from "@components/common/button";
import { BUTTON_STYLE_TYPE } from "@resources/Constants";
import { useLang } from "@hooks/useLang";
import useDetectOutsideClick from "@hooks/useDetectOutsideClick";
import { IconReset } from "@resources/icons/Icons";
import * as S from "./AnalyticsRuleLayer.styled";
import { AnalyticsRuleTree } from "./AnalyticsRuleTree";

interface AnalyticsRuleLayerProps {
  onOK: () => void;
  defaultRules: FlatNodeItem[];
  onChecked: (checkedNodes: FlatNodeItem[]) => void;
}
export const AnalyticsRuleLayer = (props: AnalyticsRuleLayerProps) => {
  const { translate } = useLang();
  const rootRef = useRef(null);
  useDetectOutsideClick(rootRef, props.onOK);

  const [rulesChecked, setRulesChecked] = useState<FlatNodeItem[]>(
    props.defaultRules
  );

  const isCheckboxChanged = useRef(false);
  const handleRulesOK = useCallback(() => {
    if (isCheckboxChanged.current) {
      props.onChecked(rulesChecked);
    }
    props.onOK();
  }, [props, rulesChecked]);

  const handleClearAll = () => {
    isCheckboxChanged.current = true;
    setRulesChecked([]);
  };

  return (
    <S.Container ref={rootRef}>
      <S.RuleWrap>
        <AnalyticsRuleTree
          rulesChecked={rulesChecked.map((node) => node.value)}
          onChecked={(node) => {
            isCheckboxChanged.current = true;
            setRulesChecked(node.filter((v) => !v.children));
          }}
        />
      </S.RuleWrap>
      <S.BottomWrap>
        <S.ClearAllButton onClick={handleClearAll}>
          <IconReset width={16} height={16} />
          <span>{translate("clear_all")}</span>
        </S.ClearAllButton>
        <Button
          styleType={BUTTON_STYLE_TYPE.FOURTH}
          label={translate("ok")}
          onClick={handleRulesOK}
          style={{ minWidth: "80px" }}
        />
      </S.BottomWrap>
    </S.Container>
  );
};
