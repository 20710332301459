import { useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useOrganizations from "@hooks/resources/useOrganizations";
import { setActiveOrganization } from "@redux/Organization";
import { useAppDispatch, useAppSelector } from "@configs/Redux";
import { SESSION_STORAGE_KEY } from "@resources/Constants";

export default function useOrganization() {
  const { organizations, refetch } = useOrganizations();
  const { orgId } = useAppSelector((state) => state.organization);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const orgIdInParams = searchParams.get("orgId");

  useEffect(() => {
    if (organizations) {
      const orgIdInStorage = sessionStorage.getItem(SESSION_STORAGE_KEY.ORG_ID);
      if (
        orgIdInParams &&
        organizations.some((org) => org.orgId === orgIdInParams)
      ) {
        sessionStorage.setItem(SESSION_STORAGE_KEY.ORG_ID, orgIdInParams);
        dispatch(setActiveOrganization(orgIdInParams));
        navigate("/");
      } else if (
        orgIdInStorage &&
        organizations.some((org) => org.orgId === orgIdInStorage)
      ) {
        dispatch(setActiveOrganization(orgIdInStorage));
      } else {
        dispatch(setActiveOrganization(organizations[0].orgId));
        sessionStorage.setItem(
          SESSION_STORAGE_KEY.ORG_ID,
          organizations[0].orgId
        );
      }
    }
  }, [organizations, navigate, orgId]);

  const organization = useMemo(
    () =>
      organizations?.find((org) => org.orgId === orgId) || organizations?.[0],
    [orgId, organizations, navigate]
  );

  return {
    organization,
    orgId,
    refetch
  };
}
