import styled from "styled-components";

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  z-index: 1000;
  background-color: rgb(0 0 0 / 60%);

  &.modal-only-body {
    .modal-container {
      justify-content: initial;
    }
  }

  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    font-size: 14px;
    color: var(--text-primary);
    background-color: var(--background-modal-container-default);
    border: 1px solid var(--border-light);
    width: 400px;
    min-height: 148px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    box-shadow: 2px 2px 2px 2px var(--box-shadow-return);

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px 22px;

      .modal-title {
        font-size: 16px;
        color: var(--text-primary);
        font-weight: 600;
      }

      .modal-close {
        cursor: pointer;

        svg {
          width: 24px;
          path {
            fill: var(--icon-default);
          }
        }
      }
    }

    .modal-body {
      padding: 0 20px 22px;
      white-space: pre-line;
    }

    .modal-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 20px 16px;
      border-top: 1px solid var(--border-light);

      > div:last-child {
        > button {
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
    }
  }
`;
