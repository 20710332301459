import { ReactNode, createElement } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { createRoot } from "react-dom/client";
import { Modal, ModalProps } from "@components/common/modal";

let zIndex = 9000;
let modalRoot: HTMLElement | null = null;

const ModalWrapper = () => {
  const id = `modal-${nanoid()}`;
  modalRoot = document.getElementById(id);

  if (!modalRoot) {
    modalRoot = document.createElement("div");
    modalRoot.id = id;
    modalRoot.style.position = "fixed";
    modalRoot.style.width = "100vw";
    modalRoot.style.height = "100vh";
    modalRoot.style.top = "0";
    modalRoot.style.left = "0";
    modalRoot.style.zIndex = String(zIndex);
    document.body.appendChild(modalRoot);
  }

  zIndex += 1;
  return createRoot(modalRoot);
};

const open = (component?: ReactNode, opts?: ModalProps) => {
  const root = ModalWrapper();
  root.render(
    createElement(
      Modal,
      {
        ...opts,
        onSubmit: () => {
          opts?.onSubmit?.();
          modalRoot?.remove();
          root.unmount();
        },
        onCancel: () => {
          opts?.onCancel?.();
          modalRoot?.remove();
          root.unmount();
        }
      },
      component
    )
  );
};

const message = (msg?: string, opts?: ModalProps) => {
  open(msg, {
    ...opts,
    showModal: true,
    isConfirm: false
  });
};

const confirm = (component?: ReactNode, opts?: ModalProps) => {
  open(component, { ...opts, showModal: true, isConfirm: true });
};

const ModalManager = {
  message,
  confirm
};

export default ModalManager;
