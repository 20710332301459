import { IconArrowDown, IconArrowUp, IconClose } from "@resources/icons/Icons";
import { classNames } from "@utils/Global";
import React, {
  HTMLAttributes,
  ReactNode,
  useEffect,
  useId,
  useRef,
  useState
} from "react";
import * as S from "./Select.styled";

type Value = string | number;

export interface Option<T> {
  label: Value | JSX.Element | ReactNode;
  value: T;
  disabled?: boolean;
}

interface SelectProps<T extends Value>
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  data: Option<T>[];
  disabled?: boolean;
  defaultValue?: T;
  canRemove?: boolean;
  onChange?: (value?: T) => void;
  showOptions?: boolean;
  tabIndex?: number;
  placeholder?: string;
  minWidth?: string;
  setIsChartHovered?: React.Dispatch<React.SetStateAction<boolean>>;
  isOnSetupPageTable?: boolean;
}

export const Select = <T extends Value>({
  data,
  disabled = false,
  canRemove = false,
  defaultValue,
  onChange,
  showOptions = false,
  tabIndex = 0,
  placeholder,
  minWidth,
  setIsChartHovered,
  isOnSetupPageTable = false,
  ...props
}: SelectProps<T>) => {
  const uniq = useId();
  const selectRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [showOptionsState, setShowOptionsState] =
    useState<boolean>(showOptions);
  const [currentOption, setCurrentOption] = useState<Option<T>>();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(e.target as HTMLElement)
      ) {
        setShowOptionsState(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  useEffect(() => {
    if (data.length && defaultValue) {
      const index = data.findIndex((item) => item.value === defaultValue);

      if (index > -1) {
        setCurrentOption(data[index]);
      }
    } else if (data.length && defaultValue === 0) {
      if (data.length) {
        const index = data.findIndex((item) => item.value === defaultValue);

        if (index > -1) {
          setCurrentOption(data[index]);
        }
      }
    }
  }, [data, defaultValue]);

  const calculatePosition = () => {
    if (!selectRef.current || !optionsRef.current) return;
    const DROPDOWN_MAX_HEIGHT = 300;
    const selectRect = selectRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const spaceBelow = viewportHeight - selectRect.bottom;
    const spaceAbove = selectRect.top;
    let position: "bottom" | "top";
    if (spaceBelow >= Math.min(DROPDOWN_MAX_HEIGHT, data.length * 40 + 2)) {
      position = "bottom";
    } else if (
      spaceAbove >= Math.min(DROPDOWN_MAX_HEIGHT, data.length * 40 + 2)
    ) {
      position = "top";
    } else if (spaceBelow >= spaceAbove) {
      position = "bottom";
    } else {
      position = "top";
    }

    if (position === "bottom") {
      optionsRef.current.style.setProperty("top", "100%");
      optionsRef.current.style.removeProperty("bottom");
    } else {
      optionsRef.current.style.setProperty("bottom", "100%");
      optionsRef.current.style.removeProperty("top");
    }
  };
  useEffect(() => {
    if (showOptionsState) {
      calculatePosition();
    }
  }, [showOptionsState]);

  return (
    <S.SelectStyled
      id={`select_${uniq}`}
      aria-label="Select input"
      ref={selectRef}
      $minWidth={minWidth}
      onMouseEnter={() => setIsChartHovered?.(true)}
      onMouseLeave={() => setIsChartHovered?.(false)}
      {...props}
    >
      <button
        tabIndex={tabIndex}
        id={`select_${uniq}_container`}
        aria-label={`Select input ${currentOption?.label || placeholder}`}
        className={classNames({
          "select-box": true,
          "select-box__expanded": showOptionsState,
          "select-box__disabled": disabled
        })}
        onClick={() => {
          if (!disabled) {
            setShowOptionsState(!showOptionsState);
          }
        }}
        onKeyDown={(e) => {
          if (e.code === "Escape") {
            setShowOptionsState(false);
          } else if (e.code === "Enter") {
            setShowOptionsState(true);
          }
        }}
      >
        <div>{currentOption?.label || placeholder}</div>
        <div className="select-icon">
          {canRemove && currentOption && (
            <div
              role="button"
              aria-label="Clear Selection"
              tabIndex={0}
              className="select-icon-remove"
              onClick={() => {
                setCurrentOption(undefined);
                onChange?.(undefined);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "Space") {
                  setCurrentOption(undefined);
                  onChange?.(undefined);
                }
              }}
            >
              <IconClose width={16} height={16} />
            </div>
          )}
          <div className="select-icon-arrow">
            {showOptionsState ? (
              <IconArrowUp width={16} height={16} />
            ) : (
              <IconArrowDown width={16} height={16} />
            )}
          </div>
        </div>
      </button>
      {showOptionsState && (
        <S.OptionList
          ref={optionsRef}
          aria-label="Options"
          minWidth={minWidth || "148px"}
        >
          {data.map((option: Option<T>) => (
            <div
              key={option.value}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "Space") {
                  if (option.disabled) {
                    return;
                  }
                  setCurrentOption(option);
                  onChange?.(option.value);
                  setShowOptionsState(false);
                }
                if (e.code === "Escape") {
                  setShowOptionsState(false);
                }
              }}
              onClick={() => {
                if (option.disabled) {
                  return;
                }

                setCurrentOption(option);
                onChange?.(option.value);
                setShowOptionsState(false);
              }}
              aria-label={`Option ${option.label}`}
              className={classNames({
                "selected-option": true,
                "selected-option__active":
                  currentOption?.value === option.value,
                "selected-option__disabled": option.disabled
              })}
            >
              <div className="selected-option-content">{option.label}</div>
            </div>
          ))}
        </S.OptionList>
      )}
    </S.SelectStyled>
  );
};
