import { THEME_TYPE } from "@resources/Constants";
import { PALETTES } from "@resources/Palettes";
import { convertHexWithOpacity } from "@utils/Global";
import { css, FlattenInterpolation, ThemeProps } from "styled-components";
import { Theme } from "./Types";

export const CSS_COLORS: {
  [THEME_TYPE.DARK]: FlattenInterpolation<ThemeProps<Theme>>;
  [THEME_TYPE.LIGHT]: FlattenInterpolation<ThemeProps<Theme>>;
} = {
  [THEME_TYPE.DARK]: css`
    :root {
      --white: ${PALETTES.DARK.MONO_0};
      --black: ${PALETTES.DARK.MONO_BLACK};
      --transparent: ${PALETTES.DARK.TRANSPARENT};

      /* SHADOW */
      --shadow-small: 0px 2px 4px rgba(0, 0, 0, 0.32);
      --shadow-medium: 8px 12px 20px rgba(0, 0, 0, 0.4);
      --shadow-large: 20px 20px 36px rgba(0, 0, 0, 0.36);
      --shadow-2-small: 0px 4px 4px rgba(0, 0, 0, 0.32);
      --shadow-report: 4px 4px 4px 4px rgba(0, 0, 0, 0.32);
      --shadow-3-small: 0 2px 2px rgba(0, 0, 0, 0.25);

      /* MONO - 텍스트, 아이콘, 버튼 등에 사용되는 기본 컬러 */
      --mono-0: ${PALETTES.DARK.MONO_0};
      --mono-1: ${PALETTES.DARK.MONO_1};
      --mono-2: ${PALETTES.DARK.MONO_2};
      --mono-3: ${PALETTES.DARK.MONO_3};
      --mono-4: ${PALETTES.DARK.MONO_4};
      --mono-5: ${PALETTES.DARK.MONO_5};
      --mono-6: ${PALETTES.DARK.MONO_6};
      --mono-7: ${PALETTES.DARK.MONO_7};
      --mono-8: ${PALETTES.DARK.MONO_8};
      --mono-9: ${PALETTES.DARK.MONO_9};
      --mono-10: ${PALETTES.DARK.MONO_10};
      --mono-11: ${PALETTES.DARK.MONO_11};
      --dark_black: ${PALETTES.DARK.DARK_BLACK};
      --box-shadow-0-32-per: ${convertHexWithOpacity(
        PALETTES.DARK.DARK_BLACK,
        32
      )};

      /* POINT - 한화 비전의 브랜드 컬러로, 포인트가 되는 요소에 사용 */
      --orange-1: ${PALETTES.DARK.ORANGE_1};
      --orange-2: ${PALETTES.DARK.ORANGE_2};
      --orange-3: ${PALETTES.DARK.ORANGE_3};
      --orange-4: ${PALETTES.DARK.ORANGE_4};
      --orange-5: ${PALETTES.DARK.ORANGE_5};
      --orange-6: ${PALETTES.DARK.ORANGE_6};
      --orange-7: ${PALETTES.DARK.ORANGE_7};
      --orange-8: ${PALETTES.DARK.ORANGE_8};
      --orange-primary: var(--orange-5);
      --orange-primary-10-per: ${convertHexWithOpacity(
        PALETTES.DARK.ORANGE_5,
        10
      )};
      --orange-secondary: var(--orange-7);
      --orange-tertiary: var(--orange-8);

      /* SYSTEM - 그래프 데이터에서 사용되는 컬러 */
      --system-blue: ${PALETTES.DARK.SYSTEM_BLUE};
      --system-red: ${PALETTES.DARK.SYSTEM_RED};
      --system-gray: ${PALETTES.DARK.MONO_4};
      --system-green: ${PALETTES.DARK.SYSTEM_GREEN};
      --system-yellow: ${PALETTES.DARK.SYSTEM_YELLOW};
      --system-sapphire: ${PALETTES.DARK.SYSTEM_SAPPHIRE};
      --system-orange: ${PALETTES.DARK.SYSTEM_ORANGE};
      --system-emerald: ${PALETTES.DARK.SYSTEM_EMERALD};
      --system-coral: ${PALETTES.DARK.SYSTEM_CORAL};
      --system-indigo: ${PALETTES.DARK.SYSTEM_INDIGO};
      --system-purple: ${PALETTES.DARK.SYSTEM_PURPLE};
      --system-violet: ${PALETTES.DARK.SYSTEM_VIOLET};
      --system-pink: ${PALETTES.DARK.SYSTEM_PINK};
      --system-light-gray: ${PALETTES.DARK.SYSTEM_LIGHT_GRAY};
      --system-tiny-light-gray: ${PALETTES.DARK.SYSTEM_TINY_LIGHT_GRAY};

      /* LINE GRAPH */
      --data-color: var(--mono-4);
      --grid-line: var(--mono-6);
      --select-line: var(--mono-0);
      --point-line: var(--mono-0);

      /* SURFACE */
      --surface: var(--black);
      --surface-widget: #1d1d1d; // exception
      --surface-popup: var(--mono-8);
      --surface-modal: var(--mono-7);

      /* TEXT */
      --text-primary: var(--mono-0);
      --text-secondary-1: var(--mono-1);
      --text-secondary-2: var(--mono-3);
      --text-secondary-3: var(--mono-1);
      --text-disabled: var(--mono-4);

      --sementic-text-primary: #ffffff;

      /* ICON */
      --icon-emphasis: var(--mono-0);
      --icon-default: var(--mono-1);
      --icon-disabled: var(--mono-4);
      --icon-report: var(--mono-1);

      /* BORDER */
      --border-light: var(--mono-7);
      --border-default: var(--mono-6);
      --border-emphasis: var(--mono-5);
      --border-card: var(--mono-5);

      /* TYPOGRAPHY */
      --font-weight-normal: 400;
      --font-weight-bold: 700;
      --font-size-minimum: 12px;
      --font-size-basic: 14px;
      --font-size-heading: 16px;
      --font-size-body: 14px;
      --font-size-caption: 12px;

      --mono-0-4-per: ${convertHexWithOpacity(PALETTES.DARK.MONO_0, 4)};
      --mono-0-8-per: ${convertHexWithOpacity(PALETTES.DARK.MONO_0, 8)};
      --mono-0-14-per: ${convertHexWithOpacity(PALETTES.DARK.MONO_0, 14)};

      /* Draggable Menu */
      --draggable-item-background-default: var(--mono-7);
      --draggable-item-background-hover: rgba(255, 255, 255, 0.04);
      --draggable-item-background-disabled: var(--mono-8);

      /* HEADER & LNB */
      --background-header-lnb-default: var(--black);
      --mono-0-8-per-font: var(--orange-5);

      /* TAB */
      --background-tab-default: var(--black);
      --background-tab-hover: var(--orange-7);
      --background-tab-select: var(--black);
      --background-tab-edit: var(--black);
      --background-tab-active-area: ${convertHexWithOpacity(
        PALETTES.DARK.MONO_10,
        20
      )}; // MONO_10

      /* INPUT & BOX & BUTTON */
      --background-ibb-default: var(--mono-7);
      --background-ibb-hover: var(--mono-7);
      --background-ibb-select: var(--mono-9);
      --background-ibb-disabled: var(--mono-9);
      --border-input-hover-active: var(--mono-0);
      --border-input-disabled: var(--border-emphasis);
      --button-type-play: var(--mono-7);

      /* INPUT SEARCH */
      --text-input-search-placeholder: var(--text-secondary-2);

      --border-input-search-default: var(--border-emphasis);
      --border-input-search-hover-active: var(--mono-0);

      /* TEXT AREA */
      --border-textarea-hover-active: var(--mono-0);
      --border-textarea-disabled: var(--border-emphasis);

      /* BUTTON */
      --background-button-default: var(--orange-5);
      --background-button-hover: var(--orange-4);
      --background-button-press: var(--orange-6);
      --background-button-disabled: var(--mono-7);

      --background-1st-button-default: transparent;
      --background-1st-button-hover: var(--mono-4);
      --background-1st-button-press: var(--mono-6);
      --background-1st-button-focus: var(--mono-8);
      --background-1st-button-disable: var(--mono-7);

      --border-2nd-button-default: var(--mono-5);
      --border-2nd-button-hover: var(--mono-0);
      --border-2nd-button-press: var(--mono-0);
      --border-2nd-button-focus: var(--mono-5);
      --border-2nd-button-disable: var(--mono-5);
      --background-2nd-button-default: var(--mono-7);
      --background-2nd-button-hover: var(--mono-7);
      --background-2nd-button-press: var(--mono-9);
      --background-2nd-button-focus: var(--mono-7);
      --background-2nd-button-disable: var(--mono-7);

      --background-3rd-button-default: var(--mono-5);
      --background-3rd-button-hover: var(--mono-4);
      --background-3rd-button-press: var(--mono-6);
      --background-3rd-button-focus: var(--mono-5);
      --background-3rd-button-disable: var(--mono-7);

      --background-4th-button-default: var(--orange-5);
      --background-4th-button-hover: var(--orange-4);
      --background-4th-button-press: var(--orange-6);
      --background-4th-button-focus: var(--orange-5);
      --background-4th-button-disable: var(--mono-7);

      /* WIDGET ELEMENTS TAB */
      --background-widget-tab-default: var(--mono-6);
      --background-widget-tab-disabled: var(--mono-10);

      /* WIDGET LIST */
      --background-widget-list-default: var(--mono-9);
      --background-widget-list-hover: var(--black);
      --background-widget-list-select: var(--black);

      /* SETTING LIST */
      --background-setting-list-default: var(--black);
      --background-setting-list-hover: var(--orange-7);

      /* TREE LIST */
      --background-tree-default-1: var(--mono-7);
      --background-tree-default: var(--mono-8);
      --background-tree-hover: var(--orange-7);
      --background-tree-press: var(--orange-8);

      /* DATE PICKER */
      --background-date-default: var(--mono-8);
      --background-date-arrow-active: var(--mono-6);
      --background-date-dropdown-default: var(--mono-8);
      --background-date-dropdown-active: var(--mono-6);
      --background-date-day-hover: var(--mono-4);
      --background-date-day-active: var(--mono-6);
      --background-date-range-sidebar: var(--mono-9);
      --background-date-range-popup-panel: var(--mono-11);
      --background-date-range-arrow-active: var(--mono-6);
      --background-date-range-dropdown-hover: var(--mono-5);
      --background-date-range-dropdown-press: var(--mono-6);

      --text-date-stroke: 3px rgba(0, 0, 0, 0.2);
      --text-date-range-sidebar: var(--mono-1);

      --border-date-today: var(--mono-0);
      --border-date-range-today: var(--white);

      /* CHECKBOX */
      --background-checkbox-default: var(--transparent);
      --background-checkbox-hover: var(--background-ibb-default);
      --background-checkbox-disabled: var(--background-ibb-disabled);

      --border-checkbox-default: var(--icon-default);
      --border-checkbox-checked: var(--orange-5);
      --border-checkbox-hover: var(--icon-emphasis);
      --border-checkbox-disabled: var(--mono-6);
      --fill-path-checkbox-checked: var(--orange-5);

      /* CONTEXT MENU */
      --text-li-context-menu-hover: var(--orange-primary);

      --background-li-context-menu-hover: var(--mono-10);
      --background-li-context-menu-active: var(--mono-10);

      --fill-path-li-context-menu-hover: var(--orange-primary);
      --icon-close-context-menu-header: vaR(--mono-0);

      /* MODAL */
      --background-modal-container-default: var(--surface-popup);

      /* RADIO */
      --background-radio-default: var(--transparent);
      --background-radio-hover: var(--background-ibb-default);
      --background-radio-after-checked: var(--orange-5);
      --background-radio-input-checked-disabled: var(--mono-8);
      --background-radio-input-checked-after-disabled: var(--mono-5);
      --background-radio-input-disabled: var(--mono-5);

      --border-radio-default: var(--mono-0);
      --border-radio-checked: var(--orange-5);
      --border-radio-disabled: var(--mono-5);

      --outline-radio-hover: var(--background-ibb-default);

      /* SELECT */
      --background-select-box-hover: var(--surface);

      --border-select-list-default: var(--border-emphasis);
      --border-select-box-default: var(--border-emphasis);
      --border-select-box-hover: var(--mono-0);

      /* SKELETON */
      --background-skeleton-0: #c4c7c94d;
      --background-skeleton-50: #4e51534d;
      --background-skeleton-100: #c4c7c94d;

      /* LIST */
      --list-border-row: var(--border-light);
      --list-background-row: var(--black);
      --list-color-header: var(--text-primary);
      --list-background-header: var(--mono-7);
      --list-active-header: var(--mono-5);
      --list-background-selected: var(--orange-8);

      /* TABLE */
      --background-tbody-tr-hover: var(--orange-8);
      --background-thead-tr-default: var(--mono-10);
      --background-bordered-thead-tr-default: var(--mono-9);
      --background-head-thead-tr-default: var(--mono-9);
      --background-has-check-box-thead-tr-default: var(--black);
      --background-has-check-box-title-header-hover: var(--mono-4);
      --background-has-check-box-title-header-active: var(--mono-6);

      --border-th-td-default: var(--mono-9);
      --border-tbody-tr-default: var(--mono-9);
      --border-bordered-thead-tr-top-default: var(--mono-6);
      --border-bordered-thead-tr-bottom-default: var(--mono-6);
      --border-bordered-tr-td-top-default: var(--mono-6);
      --border-bordered-tr-td-bottom-default: var(--mono-6);
      --border-head-th-td-default: var(--mono-6);
      --border-head-tbody-tr-default: var(--mono-6);
      --border-has-check-box-tbody-tr-top-default: var(--mono-6);
      --border-has-check-box-tbody-tr-bottom-default: var(--mono-6);

      --fill-path-has-check-box-th-svg-default: var(--mono-1);
      --fill-path-has-check-box-th-svg-hover: var(--mono-0);
      --fill-path-has-check-box-th-svg-active: var(--mono-0);

      /* TOOLBOX */
      --background-toolbox-default: var(--mono-6);
      --background-toolbox-hover: var(--mono-5);
      --background-toolbox-active: var(--mono-7);
      --background-toolbox-out-after: var(--mono-5);

      /* TREE VIEW */
      --border-tree-view-text-node-default: var(--border-default);
      --border-tree-view-text-node-hover: var(--border-emphasis);
      --border-tree-view-text-node-active: var(--border-emphasis);

      --fill-rect-tree-view-node-icon: var(--icon-emphasis);
      --fill-rect-path-tree-view-node-icon: var(--mono-8);
      --fill-path-tree-view-node-icon: var(--icon-emphasis);

      /** LOGIN */
      --login-logo: var(--icon-emphasis);
      --login-notice: var(--text-secondary-1);

      /** SIGN UP */
      --signup-logo: var(--icon-emphasis);
      --signup-notice: var(--text-secondary-1);

      /* DASHBOARD ADD WIDGET BUTTON */
      --background-add-widget-button: #0c0c0c;
      --dashboard-bottom-gradient-color1: #0c0c0c00;
      --dashboard-bottom-gradient-color2: #0c0c0c;

      /* DASHBOARD DATE RANGE PICKER */
      --background-dashboard-date-range-default: var(--mono-8);
      --background-dashboard-date-range-hover: var(--mono-7);
      --background-dashboard-date-range-icon-default: var(--mono-8);
      --background-dashboard-date-range-icon-active: var(--mono-6);
      --background-dashboard-date-range-bar-default: var(--mono-7);

      /* ALARM BUBBLE */
      --background-alarm-bubble-default: #101010;
      --background-alarm-bubble-hover: var(--mono-8);

      /* COUNTS CIRCLE */
      --counts-circle-default: var(--mono-2);

      /* POS THUMBNAIL */
      --shadow-pos-thumbnail: var(--shadow-3-small);

      /* RULE SETUP */
      --icon-rule: var(--white);

      /* SIDE MENU */
      --background-side-menu-default: var(--surface);
      --side-menu-icon: rgba(255, 255, 255, 0.61);

      --text-side-menu-active-no-logo-icon-default: var(--white);
      --text-side-menu-active-no-logo-title-default: var(--white);

      /* TOP MENU */
      --text-top-menu-tab-default: var(--mono-4);
      --text-top-menu-icon-divider: var(--mono-7);
      --fill-top-menu-add-button-svg: var(--mono-1);
      --background-top-menu-button-svg: var(--black);
      --background-top-menu-button-svg-hover: var(--mono-7);
      --background-top-menu-tab-icon-count: var(--mono-4);
      --background-top-menu-text-tab-icon-count: var(--mono-0);
      --background-top-menu-input-tab: var(--black);
      --background-top-menu-tab-active: var(--orange-8);

      /* WIDGET SETUP */
      /* MODAL NEW WIDGET */
      --background-modal-new-widget: rgb(0 0 0 / 60%);
      --background-add-new-widget-container: #292929;
      --background-add-new-widget-header: #292929;
      --background-add-new-widget-footer: var(--mono-8);

      --text-add-new-widget-li: var(--mono-4);
      --text-widget-rule-setup-empty: var(--text-primary);

      --border-add-new-widget-preview-hover: var(--mono-0);

      --fill-modal-widget-setup-checkbox-rect: var(--mono-4);
      --fill-modal-widget-setup-setting-title: var(--icon-default);

      --border-modal-widget-setup-after: var(--border-emphasis);
      --border-modal-widget-setup-hover: var(--mono-0);

      --filter-modal-widget-setup-preview: drop-shadow(
        0 2px 2px rgba(0 0 0 / 25%)
      );

      /* COUNT BY AREA QUEUE */
      --border-number-of-people-by-area-bottom: var(--mono-7);

      /* CUMULATE PEOPLE COUNT*/
      --border-cumulate-peoplecounts-bottom: var(--mono-7);

      /* CUMULATE SALES POS*/
      --border-cumulate-sales-pos-bottom: var(--mono-7);

      /* ENTRY EXIT PEOPLE COUNT*/
      --border-entrance-exit-peoplecounts-bottom: var(--mono-7);

      /* RANK PEOPLE COUNT*/
      --border-rank-peoplecounts-bottom: var(--mono-7);

      /* REVENUE PER SALES POS*/
      --border-revenue-per-sales-pos-bottom: var(--mono-7);

      /* SALES TRANSACTION POS*/
      --border-sales-transaction-pos-bottom: var(--mono-7);

      /* STAYING PEOPLE COUNT*/
      --border-staying-peoplecounts-bottom: var(--mono-7);

      /* AREA TRAFFIC */
      --fill-rect-area-traffic: var(--mono-4);

      /* Type People Count */
      --border-type-peoplecounts-bottom: var(--mono-7);

      /* ADD FORM */
      --background-add-form-default: var(--surface-popup);
      --border-add-form-default: var(--border-light);

      /* WIDGETS */
      /* BASE WIDGET */
      --background-base-widget-default: rgb(0 0 0 / 60%);
      --background-base-widget-left: var(--mono-8);
      --background-base-widget-setup-combo-button-default: var(--mono-5);
      --background-base-widget-setup-combo-button-hover: var(--mono-4);
      --background-base-widget-setup-combo-button-active: var(--mono-6);
      --background-base-widget-setup-combo-button-disabled: var(--mono-7);
      --background-base-widget-rules-select: var(--mono-5);
      --background-base-widget-thumbnail: var(--black);

      --border-base-widget-expansion-default: var(--mono-5);
      --border-base-widget-expansion-hover: var(--mono-0);

      /* WIDGET */
      --background-base-widget-more-default: var(--mono-4);
      --background-base-widget-more-active: var(--mono-6);

      /* COUNT BY AREA QUEUE */
      --background-number-of-people-by-area-button: rgba(72, 145, 255, 0.2);
      --background-number-of-people-by-area-collapse-button-disabled: rgba(
        250,
        77,
        77,
        0.2
      );
      --background-number-of-people-by-area-detail-box-loading: var(--mono-9);
      --background-number-of-people-by-area-button-less-kpi: ${convertHexWithOpacity(
        PALETTES.DARK.SYSTEM_RED,
        20
      )};

      /* DOCK MANAGEMENT */
      --background-dock-management-default: var(--black);
      --background-dock-management-active: var(--mono-6);

      --text-dock-block-item-status: var(--mono-4);

      /* MAP IMAGE */
      --image-dim-layer-opacity: 0%;
      --image-dim-layer-color: #235371;

      /* FACTORY MAP */
      --background-factory-map-left: #0000000d;
      --background-factory-map-item-info: #101010;

      /* ILLEGAL PARKING */
      --background-illegal-parking-info-box: var(--mono-7);

      /* RETAIL MAP */
      --background-retail-map-left: var(--white);

      /* TYPE PEOPLE COUNT SETUP */
      --border-type-people-count-setup-bottom: var(--mono-7);

      /* VEHICLE IN OUT EVENT */
      --background-vehicle-in-out-event-info-box: var(--mono-7);

      /* SLIP AND FALL */
      --background-slip-and-fall-meta: #101010;

      /* ZOOM IN AND OUT */
      --background-zoom-in-out: var(--mono-8);

      /* PARTNER APP */
      --partnel-app-hover-background-color: var(--mono-7);
      --partnel-app-hover-border-color: var(--mono-0);

      /* PAGES */
      /* EVENT */
      --border-event-text-box-today: var(--white);
      --event-button-default: var(--mono-8);
      --event-button-hover: var(--mono-4);
      --event-button-press: var(--mono-6);
      --event-button-selected: var(--orange-primary);
      --background-duration-time: var(--mono-7);

      /* DEVICE SETUP */
      --background-device-setup-contents-left: var(--mono-8);

      /* USER ADMIN */
      --background-tooltip-password-guide: var(--mono-7);
      --background-group-tree: var(--mono-7);
      --border-group-tree: var(--border-emphasis);
      --icon-root-group-tree: var(--icon-emphasis);
      --overlay-user-profile: #00000080;
      --background-root-group-tree-selected: var(--orange-8);

      /* USER PROFILE */
      --profile-divider: var(--mono-5);

      /* SCROLL BAR */
      --background-scroll-thumb: ${convertHexWithOpacity(
        PALETTES.DARK.MONO_2,
        40
      )};
      --border-scroll-thumb: var(--white);

      --background-tooltip: #292929;

      --event-notification-background: var(--mono-9);
      --event-notification-icon: var(--mono-10);
      --event-notification-background-close: var(--black);
      --event-notification-icon-close: var(--icon-emphasis);

      /* Event Player */
      --background-control-media: var(--mono-10);
      --background-control-bar: var(--mono-6);

      /* Slip And Fall */
      --border-th-td: var(--mono-6);
      --background-thead-tr: var(--surface);
      --background-row-data: var(--mono-9);

      /*  Widget Form Style */
      --fill-outer-border: var(--black);
      --fill-outer-border-active: #612e0d;

      --fill-rect-color-1: var(--mono-6);
      --fill-rect-color-2: var(--mono-8);
      --fill-rect-color-active: var(--orange-7);

      --fill-text-color: var(--mono-1);
      --fill-text-color-active: var(--white);

      --fill-text-color-content: var(--mono-1);

      --stroke-outer-border: var(--mono-5);
      --stroke-outer-border-active: var(--orange-5);

      /*  Event Filter */
      --fill-path-color-arrow-filter: var(--icon-emphasis);
      --fill-path-color-filter-hover: var(--white);
      --background-icon-filter: var(--mono-8);
      --background-date-time-picker: var(--mono-7);
      --background-filter-wrapper: var(--mono-9);
      --border-bottom-filter-item: var(--border-light);

      /* Pagination */
      --background-button-next-previous: var(--transparent);
      --background-button-page-default: var(--mono-9);
      --background-button-page-hover: var(--mono-4);
      --background-button-page-selected: var(--orange-primary);

      /* Event timer */
      --fill-path-timer-item: var(--mono-7);
      --text-timer-color: var(--text-secondary-1);
      --background-button-confirmed: var(--mono-5);
      --text-channel-color: var(--black);
      --background-pagination-temp-statistics: var(--mono-8);

      /* Event action */
      --opacity-event-action-default: 0.3;
      --opacity-event-action-select: 0.75;

      --fill-icon-done-event-action: var(--icon-default);
      --background-event-action-btn: var(--mono-8);
      --background-event-action-btn-hover: var(--mono-4);
      --background-event-action-btn-press: var(--mono-6);
      --background-event-action-btn-disabled: var(--mono-7);
      --border-event-action-btn: 1px solid
        ${convertHexWithOpacity(PALETTES.DARK.SYSTEM_EMERALD, 40)};

      /* Sequence */
      --background-slider-thumb: var(--white);
      --background-conversion-content: #1b1b1b;

      --background-widget-main-list-head: var(--mono-7);
      --background-widget-main-list-row: var(--orange-8);

      /* Crowd Safety Statistics */
      --background-crowd-item: var(--mono-9);
      --text-crowd-location: var(--text-secondary-1);

      /* Number Of People By Are */
      --background-pin-hover: var(--mono-4);
      --background-pin-press: var(--mono-6);

      /* Stopped Vehicle Event */
      --fill-color-icon-vehicle-event: var(--icon-emphasis);

      --claim-device-header: #1d1d1d;

      --device-setup-search-placeholder: var(--text-disabled);
      --device-setup-search-icon: var(--icon-disabled);

      --background-arrow-icon-default: var(--surface-widget);
      --background-arrow-icon-hover: var(--surface-widget);
      --background-arrow-icon-press: var(--mono-7);
      --background-arrow-icon-select: var(--surface-widget);

      --border-arrow-icon-default: var(--border-emphasis);
      --border-arrow-icon-hover: var(--mono-0);
      --border-arrow-icon-press: var(--mono-0);
      --border-arrow-icon-select: var(---border-emphasis);

      --icon-arrow-icon-default: var(--icon-default);
      --icon-arrow-icon-hover: var(--icon-emphasis);
      --icon-arrow-icon-press: var(--icon-emphasis);
      --icon-arrow-icon-select: var(--icon-default);

      --icon-beta: var(--mono-0);

      /* CITY MAP RETURN BUTTON */
      --icon-arrow-return: var(--icon-default);
      --text-return: var(--mono-0);
      --border-return: rgba(255, 255, 255, 0.1);
      --background-return: var(--mono-8);
      --box-shadow-return: rgba(0, 0, 0, 0.32);

      /* Notification BG State */
      --background-notification-default: var(--mono-9);
      --background-notification-hover: var(--mono-8);
      --background-notification-press: var(--mono-10);
      --notification-shadow: rgba(0, 0, 0, 0.2);

      /* Event Icon */
      --background-event-icon: var(--mono-7);
      --event-icon-path: var(--mono-10);

      /* Profile Avatar */
      --background-profile-avatar: #612e0d;
      --border-profile-avatar: #1d1d1d;
      --text-profile-avatar: #f37321;
      /* REPORT */
      --report-contributors: var(--orange-5);
      --report-create-date: var(--mono-3);
      --report-popup-background: #292929;
      --report-content-hover: var(--mono-10);
      --add-report-btn-background: var(--mono-9);
      --add-report-btn-fill: var(--mono-1);
      --report-content-type-text-hover: var(--orange-5);
      --report-person-list: var(--mono-8);
      --report-person-list-border: var(--mono-7);
      --report-list-active: var(--mono-0);
      --report-person-text: var(--orange-5);
      --report-person-text-gray: var(--mono-1);
      --report-person-background-gray: var(--mono-7);
      --report-person-background: var(--orange-8);
      --report-person-count-background: var(--mono-7);
      --report-person-count-color: var(--mono-1);
      --report-Icon-color: var(--mono-7);
      --report-edit-icon-color: var(--mono-1);
      --report-default-chart-icon-color: var(--mono-4);
      --report-default-chart-icon-bg: var(--mono-8);
      --selected-widget_background: var(--dark_black);
      --delete-widget-color: var(--mono-7);
      --report-widget-hover: var(--mono-8);
      --report-widget-icon-stroke-dark: var(--mono-0);
      --report-widget-icon-stroke-light: var(--mono-8);
      --report-widget-icon-stroke-outer: var(--mono-6);
      --report-insight-statement-container-background: var(--mono-9);
      --report-toastify-message-background: var(--mono-10);
      --report-insight-statement-container-b: var(--mono-9);
      --report-body-divider: var(--mono-6);
      --report-modal-right-content: var(--mono-9);
      --report-widget-disabled-input-bg: var(--mono-7);
      --report-toastify-message-background: var(--mono-10);
      --report-input-text-color: var(--mono-0);
      --report-add-widget-icon-bg: var(--mono-5);
      --report-add-widget-icon-fill: var(--mono-1);
      --report-select-widget-container-bg: var(--mono-7);
      --report-select-widget-container-hover: var(--black);
      --report-modal-chart-style-disabled: var(--mono-8);
      --report-modal-chart-style-default: var(--surface-widget);
      --report-modal-chart-style-hover: var(--mono-6);
      --report-modal-chart-style-select: var(--surface-widget);
      --report-modal-chart-style-disabled-default-hover-border: var(--mono-6);
      --report-modal-chart-style-select-border: var(--system-orange);
      --report-input-text-color: var(--mono-0);
      --report-input-editor-background: var(--mono-10);
      --report-input-editor-hover: var(--mono-9);
      --report-text-editor-color: var(--mono-0);
      --report-email-user-list-hover: var(--orange-7);
      --report-email-chip-border: var(--border-emphasis);
      --report-email-body-placeholder: var(--mono-4);
      --report-email-user-list-background-color: var(--mono-7);
      --report-email-pdf-title-background-color: var(--mono-7);

      --report-background: var(--mono-10);
      --report-background-hover: var(--mono-9);

      --report-body-config-tab-bg: var(--mono-7);
      --report-graph-avg-line: var(--mono-0);

      --report-graph-bar-stack-color: var(--mono-0);
      --report-header-background-color: var(--mono-7);

      --background-report-cancel-button-default: var(--mono-5);
      --background-report-cancel-button-hover: var(--mono-4);

      /* Hidden Widget */
      --background-hidden-widget-default: var(--mono-7);
      --background-hidden-widget-hover: var(--mono-6);
      --background-hidden-widget-press: var(--mono-8);

      /* Device Status */
      --background-device-status-default: var(--mono-8);
      --background-device-status-hover: var(--mono-10);
      --background-device-status-press: var(--mono-10);

      /* Licenses */
      --background-license-device: var(--mono-9);

      /* Roles */
      --background-roles: var(--mono-9);

      /* Rules Details */
      --rule-separator: var(--mono-7);
      --rule-active-text: var(--mono-1);
      --rule-inactive-text: var(--mono-4);
      --rule-devider: var(--mono-7);

      /* dashboard tree */
      --float-button: var(--mono-10);
      --context-menu-hover: var(--mono-4);
      --context-menu-active: var(--mono-6);
      --drag-active-item: rgba(97, 46, 13, 0.5); // orange_8

      --white-black: var(--white);

      /* rule tree */
      --tree-item-selected: ${convertHexWithOpacity(PALETTES.DARK.MONO_0, 8)};
      --tree-item-selected-left-bar: ${PALETTES.DARK.ORANGE_5};
      --tree-item-hover: ${convertHexWithOpacity(PALETTES.DARK.MONO_0, 4)};

      --event-filter-item-active: rgba(255, 255, 255, 0.08);

      --list-text-color: var(--mono-1);
      --list-text-color-active: var(--mono-0);

      --share-report-input-bg: var(--mono-7);
      --share-report-border: var(--mono-5);

      /* combined-map */
      --marker-hover-background: var(--mono-6);

      --cal-text-color: ${convertHexWithOpacity(PALETTES.DARK.MONO_0, 61)};

      /* Analytics page */
      --background-analytics-notice-wrap: var(--mono-10);
      --background-analytics-top-menu: var(--mono-8);
      --analytics-chart-title: var(--white);
      --analytics-chart-icon: var(--white);
      --analytics-chart-count-badge-bg: var(--white);
      --analytics-chart-count-badge-text: var(--black);
      --analytics-popup-border-color: var(--mono-5);

      /* drop down */
      --border-dropdown: var(--mono-5);
      --border-dropdown-hover: #ffffff0a;
      --border-dropdown-active: #ffffff14;

      /* legend items */

      --button-background-color: var(--mono-0-4-per);
      --legend-background-color: var(--mono-7);
      --legend-border-color: var(--mono-5);
      --legend-box-color: #00000052;
    }
  `,
  [THEME_TYPE.LIGHT]: css`
    :root {
      --white: ${PALETTES.LIGHT.MONO_WHITE};
      --black: ${PALETTES.LIGHT.MONO_10};
      --transparent: ${PALETTES.DARK.TRANSPARENT};

      /* SHADOW */
      --shadow-small: 0px 2px 2px rgba(0, 0, 0, 0.1);
      --shadow-medium: 8px 12px 20px rgba(0, 0, 0, 0.16);
      --shadow-large: 20px 20px 36px rgba(0, 0, 0, 0.04);
      --shadow-2-small: 0px 4px 4px rgba(0, 0, 0, 0.1);
      --shadow-report: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);

      /* MONO - 텍스트, 아이콘, 버튼 등에 사용되는 기본 컬러 */
      --mono-0: ${PALETTES.LIGHT.MONO_0};
      --mono-1: ${PALETTES.LIGHT.MONO_1};
      --mono-2: ${PALETTES.LIGHT.MONO_2};
      --mono-3: ${PALETTES.LIGHT.MONO_3};
      --mono-4: ${PALETTES.LIGHT.MONO_4};
      --mono-5: ${PALETTES.LIGHT.MONO_5};
      --mono-6: ${PALETTES.LIGHT.MONO_6};
      --mono-7: ${PALETTES.LIGHT.MONO_7};
      --mono-8: ${PALETTES.LIGHT.MONO_8};
      --mono-9: ${PALETTES.LIGHT.MONO_9};
      --mono-10: ${PALETTES.LIGHT.MONO_10};
      --mono-11: ${PALETTES.LIGHT.MONO_11};
      --light_white: ${PALETTES.LIGHT.LIGHT_WHITE};
      --box-shadow-0-32-per: ${convertHexWithOpacity(
        PALETTES.LIGHT.LIGHT_WHITE,
        32
      )};

      /* POINT - 한화 비전의 브랜드 컬러로, 포인트가 되는 요소에 사용 */
      --orange-1: ${PALETTES.LIGHT.ORANGE_1};
      --orange-2: ${PALETTES.LIGHT.ORANGE_2};
      --orange-3: ${PALETTES.LIGHT.ORANGE_3};
      --orange-4: ${PALETTES.LIGHT.ORANGE_4};
      --orange-5: ${PALETTES.LIGHT.ORANGE_5};
      --orange-6: ${PALETTES.LIGHT.ORANGE_6};
      --orange-7: ${PALETTES.LIGHT.ORANGE_7};
      --orange-8: ${PALETTES.LIGHT.ORANGE_8};
      --orange-primary: var(--orange-4);
      --orange-primary-10-per: ${convertHexWithOpacity(
        PALETTES.LIGHT.ORANGE_4,
        10
      )};
      --orange-secondary: var(--orange-7);
      --orange-tertiary: var(--orange-8);

      /* SYSTEM - 그래프 데이터에서 사용되는 컬러 */
      --system-blue: ${PALETTES.LIGHT.SYSTEM_BLUE};
      --system-red: ${PALETTES.LIGHT.SYSTEM_RED};
      --system-gray: ${PALETTES.LIGHT.MONO_7};
      --system-green: ${PALETTES.LIGHT.SYSTEM_GREEN};
      --system-yellow: ${PALETTES.LIGHT.SYSTEM_YELLOW};
      --system-sapphire: ${PALETTES.LIGHT.SYSTEM_SAPPHIRE};
      --system-orange: ${PALETTES.LIGHT.SYSTEM_ORANGE};
      --system-emerald: ${PALETTES.LIGHT.SYSTEM_EMERALD};
      --system-coral: ${PALETTES.LIGHT.SYSTEM_CORAL};
      --system-indigo: ${PALETTES.LIGHT.SYSTEM_INDIGO};
      --system-purple: ${PALETTES.LIGHT.SYSTEM_PURPLE};
      --system-violet: ${PALETTES.LIGHT.SYSTEM_VIOLET};
      --system-pink: ${PALETTES.LIGHT.SYSTEM_PINK};
      --system-light-gray: ${PALETTES.LIGHT.SYSTEM_LIGHT_GRAY};
      --system-tiny-light-gray: ${PALETTES.LIGHT.SYSTEM_TINY_LIGHT_GRAY};

      /* LINE GRAPH */
      --data-color: var(--mono-8);
      --grid-line: var(--mono-9);
      --select-line: var(--mono-7);
      --point-line: var(--mono-7);

      /* SURFACE */
      --surface: var(--mono-10);
      --surface-widget: var(--white);
      --surface-popup: var(--white);
      --surface-modal: var(--white);

      /* TEXT */
      --text-primary: var(--mono-1);
      --text-secondary-1: var(--mono-4);
      --text-secondary-2: var(--mono-7);
      --text-secondary-3: var(--mono-4);
      --text-disabled: var(--mono-8);

      --sementic-text-primary: #111111;

      /* ICON */
      --icon-emphasis: var(--mono-1);
      --icon-default: var(--mono-7);
      --icon-disabled: var(--mono-8);
      --icon-report: var(--system-gray);

      /* BORDER */
      --border-light: var(--mono-10);
      --border-default: var(--mono-9);
      --border-emphasis: var(--mono-8);
      --border-card: var(--mono-9);

      /* TYPOGRAPHY */
      --font-weight-normal: 400;
      --font-weight-bold: 700;
      --font-size-minimum: 12px;
      --font-size-basic: 14px;
      --font-size-heading: 16px;
      --font-size-body: 14px;
      --font-size-caption: 12px;

      --mono-0-4-per: ${convertHexWithOpacity(PALETTES.LIGHT.MONO_0, 4)};
      --mono-0-8-per: ${convertHexWithOpacity(PALETTES.LIGHT.MONO_0, 8)};
      --mono-0-14-per: ${convertHexWithOpacity(PALETTES.LIGHT.MONO_0, 14)};

      /* Draggable Menu */
      --draggable-item-background-default: var(--white);
      --draggable-item-background-hover: rgba(0, 0, 0, 0.04);
      --draggable-item-background-disabled: var(--mono-11);

      /* HEADER & LNB */
      --background-header-lnb-default: var(--mono-10);
      --mono-0-8-per-font: var(--orange-4);

      /* TAB */
      --background-tab-default: var(--mono-10);
      --background-tab-hover: var(--orange-7);
      --background-tab-select: var(--mono-10);
      --background-tab-edit: var(--mono-10);
      --background-tab-active-area: ${convertHexWithOpacity(
        PALETTES.LIGHT.MONO_10,
        20
      )};
      /* INPUT & BOX & BUTTON */
      --background-ibb-default: var(--white);
      --background-ibb-hover: var(--white);
      --background-ibb-select: var(--mono-10);
      --background-ibb-disabled: var(--mono-9);

      --border-input-hover-active: var(--border-emphasis);
      --border-input-disabled: var(--border-default);
      --border-checkbox-disabled: var(--mono-8);
      --button-type-play: var(--white);

      /* INPUT SEARCH */
      --text-input-search-placeholder: var(--text-primary);

      --border-input-search-default: var(--border-default);
      --border-input-search-hover-active: var(--mono-8);

      /* TEXT AREA */
      --border-textarea-hover-active: var(--border-emphasis);
      --border-textarea-disabled: var(--border-default);

      /* BUTTON */
      --background-button-default: var(--orange-4);
      --background-button-hover: var(--orange-5);
      --background-button-press: var(--orange-6);
      --background-button-disabled: var(--mono-9);

      --background-1st-button-default: transparent;
      --background-1st-button-hover: var(--mono-10);
      --background-1st-button-press: var(--mono-9);
      --background-1st-button-focus: var(--white);
      --background-1st-button-disable: var(--mono-9);

      --border-2nd-button-default: var(--mono-9);
      --border-2nd-button-hover: var(--mono-8);
      --border-2nd-button-press: var(--mono-8);
      --border-2nd-button-focus: var(--mono-8);
      --border-2nd-button-disable: var(--mono-9);
      --background-2nd-button-default: var(--white);
      --background-2nd-button-hover: var(--white);
      --background-2nd-button-press: var(--mono-10);
      --background-2nd-button-focus: var(--white);
      --background-2nd-button-disable: var(--mono-9);

      --background-3rd-button-default: var(--mono-9);
      --background-3rd-button-hover: var(--mono-8);
      --background-3rd-button-press: var(--mono-7);
      --background-3rd-button-focus: var(--mono-9);
      --background-3rd-button-disable: var(--mono-9);

      --background-4th-button-default: var(--orange-4);
      --background-4th-button-hover: var(--orange-5);
      --background-4th-button-press: var(--orange-3);
      --background-4th-button-focus: var(--orange-4);
      --background-4th-button-disable: var(--mono-9);

      /* WIDGET ELEMENTS TAB */
      --background-widget-tab-default: var(--mono-10);
      --background-widget-tab-disabled: var(--mono-9);

      /* WIDGET LIST */
      --background-widget-list-default: var(--white);
      --background-widget-list-hover: var(--mono-10);
      --background-widget-list-select: var(--mono-10);

      /* SETTING LIST */
      --background-setting-list-default: var(--white);
      --background-setting-list-hover: var(--orange-7);

      /* TREE LIST */
      --background-tree-default-1: var(--mono-10);
      --background-tree-default: var(--mono-10);
      --background-tree-hover: var(--orange-7);
      --background-tree-press: var(--orange-6);

      /* DATE PICKER */
      --background-date-default: var(--white);
      --background-date-arrow-active: var(--mono-7);
      --background-date-dropdown-default: var(--mono-9);
      --background-date-dropdown-active: var(--mono-7);
      --background-date-day-hover: var(--mono-10);
      --background-date-day-active: var(--mono-9);
      --background-date-range-sidebar: var(--mono-10);
      --background-date-range-popup-panel: var(--mono-11);
      --background-date-range-arrow-active: var(--mono-7);
      --background-date-range-dropdown-hover: var(--mono-10);
      --background-date-range-dropdown-press: var(--mono-9);

      --text-date-stroke: 3px rgba(0, 0, 0, 0.1);
      --text-date-range-sidebar: var(--mono-7);

      --border-date-today: var(--mono-9);
      --border-date-range-today: var(--orange-primary);

      /* CHECKBOX */
      --background-checkbox-default: var(--white);
      --background-checkbox-disabled: var(--mono-9);
      --border-checkbox-disabled: var(--mono-8);

      --border-checkbox-default: var(--border-emphasis);
      --border-checkbox-checked: var(--orange-4);
      --border-checkbox-hover: var(--icon-emphasis);

      --fill-path-checkbox-checked: var(--orange-4);

      /* CONTEXT MENU */
      --text-li-context-menu-hover: var(--orange-primary);

      --background-li-context-menu-hover: var(--mono-9);
      --background-li-context-menu-active: var(--mono-9);

      --fill-path-li-context-menu-hover: var(--orange-primary);
      --icon-close-context-menu-header: var(--mono-7);

      /* MODAL */
      --background-modal-container-default: var(--white);

      /* RADIO */
      --background-radio-default: var(--white);
      --background-radio-hover: var(--background-ibb-select);
      --background-radio-after-checked: var(--orange-4);
      --background-radio-input-checked-disabled: var(--background-ibb-disabled);
      --background-radio-input-checked-after-disabled: var(--mono-8);
      --background-radio-input-disabled: var(--background-ibb-disabled);

      --border-radio-default: var(--border-emphasis);
      --border-radio-checked: var(--orange-4);
      --border-radio-disabled: var(--mono-8);

      --outline-radio-hover: var(--background-ibb-select);

      /* SELECT */
      --background-select-box-hover: var(--white);

      --border-select-list-default: var(--border-default);
      --border-select-box-default: var(--border-default);
      --border-select-box-hover: var(--border-default);

      /* SKELETON */
      --background-skeleton-0: #e5e8eb66;
      --background-skeleton-50: #5d616533;
      --background-skeleton-100: #e5e8eb66;

      /* LIST */
      --list-border-row: var(--mono-9);
      --list-background-row: var(--white);
      --list-color-header: var(--text-secondary-1);
      --list-background-header: var(--mono-9);
      --list-active-header: var(--mono-7);
      --list-background-selected: var(--orange-7);

      /* TABLE */
      --background-tbody-tr-hover: var(--orange-7);
      --background-thead-tr-default: var(--mono-9);
      --background-bordered-thead-tr-default: var(--mono-10);
      --background-head-thead-tr-default: var(--mono-10);
      --background-has-check-box-thead-tr-default: var(--mono-9);
      --background-has-check-box-title-header-hover: var(--mono-8);
      --background-has-check-box-title-header-active: var(--mono-7);

      --border-th-td-default: var(--mono-6);
      --border-tbody-tr-default: var(--mono-6);
      --border-bordered-thead-tr-top-default: var(--mono-9);
      --border-bordered-thead-tr-bottom-default: var(--mono-9);
      --border-bordered-tr-td-top-default: var(--mono-9);
      --border-bordered-tr-td-bottom-default: var(--mono-9);
      --border-head-th-td-default: var(--mono-9);
      --border-head-tbody-tr-default: var(--mono-9);
      --border-has-check-box-tbody-tr-top-default: var(--mono-9);
      --border-has-check-box-tbody-tr-bottom-default: var(--mono-9);

      --fill-path-has-check-box-th-svg-default: var(--mono-7);
      --fill-path-has-check-box-th-svg-hover: var(--mono-1);
      --fill-path-has-check-box-th-svg-active: var(--mono-1);

      /* TOOLBOX */
      --background-toolbox-default: var(--mono-10);
      --background-toolbox-hover: var(--mono-9);
      --background-toolbox-active: var(--mono-8);
      --background-toolbox-out-after: var(--mono-9);

      /* TREE VIEW */
      --border-tree-view-text-node-default: var(--border-emphasis);
      --border-tree-view-text-node-hover: var(--border-default);
      --border-tree-view-text-node-active: var(--border-default);

      --fill-rect-tree-view-node-icon: var(--icon-default);
      --fill-rect-path-tree-view-node-icon: var(--white);
      --fill-path-tree-view-node-icon: var(--icon-default);

      /** LOGIN */
      --login-logo: #141414;
      --login-notice: var(--text-secondary-2);

      /** SIGN UP */
      --signup-logo: #141414;
      --signup-notice: var(--text-secondary-2);

      /* DASHBOARD ADD WIDGET BUTTON */
      --background-add-widget-button: #0c0c0c;
      --dashboard-bottom-gradient-color1: #eef1f200;
      --dashboard-bottom-gradient-color2: #eef1f2;

      /* DASHBOARD DATE RANGE PICKER */
      --background-dashboard-date-range-default: var(--mono-9);
      --background-dashboard-date-range-hover: var(--mono-8);
      --background-dashboard-date-range-icon-default: var(--mono-9);
      --background-dashboard-date-range-icon-active: var(--mono-7);
      --background-dashboard-date-range-bar-default: var(--mono-9);

      /* ALARM BUBBLE */
      --background-alarm-bubble-default: var(--white);
      --background-alarm-bubble-hover: var(--white);

      /* COUNTS CIRCLE */
      --counts-circle-default: var(--mono-8);

      /* POS THUMBNAIL */
      --shadow-pos-thumbnail: var(--shadow-2-small);

      /* RULE SETUP */
      --icon-rule: var(--icon-default);

      /* SIDE MENU */
      --background-side-menu-default: var(--mono-10);
      --side-menu-icon: rgba(0, 0, 0, 0.77);

      --text-side-menu-active-no-logo-icon-default: var(--mono-7);
      --text-side-menu-active-no-logo-title-default: var(--mono-7);

      /* TOP MENU */
      --text-top-menu-tab-default: var(--mono-7);
      --text-top-menu-icon-divider: var(--mono-8);
      --fill-top-menu-add-button-svg: var(--mono-7);
      --background-top-menu-button-svg: var(--mono-10);
      --background-top-menu-button-svg-hover: var(--mono-8);
      --background-top-menu-tab-icon-count: var(--mono-9);
      --background-top-menu-text-tab-icon-count: var(--mono-7);
      --background-top-menu-input-tab: var(--mono-10);
      --background-top-menu-tab-active: var(--orange-6);

      /* WIDGET SETUP */
      /* ADD WIDGET */
      --background-modal-new-widget: rgb(12 12 12 / 50%);
      --background-add-new-widget-container: var(--white);
      --background-add-new-widget-header: var(--white);
      --background-add-new-widget-footer: var(--white);

      --text-add-new-widget-li: var(--mono-7);
      --text-widget-rule-setup-empty: var(--text-secondary-1);

      --border-add-new-widget-preview-hover: var(--mono-8);

      --fill-modal-widget-setup-checkbox-rect: var(--mono-7);
      --fill-modal-widget-setup-setting-title: #d9d9d9;

      --border-modal-widget-setup-after: var(--border-default);
      --border-modal-widget-setup-hover: var(--border-emphasis);

      --filter-modal-widget-setup-preview: drop-shadow(
        0 4px 4px rgba(0 0 0 / 10%)
      );

      /* COUNT BY AREA QUEUE */
      --border-number-of-people-by-area-bottom: var(--mono-10);

      /* CUMULATE PEOPLE COUNT*/
      --border-cumulate-peoplecounts-bottom: var(--mono-10);

      /* CUMULATE SALES POS*/
      --border-cumulate-sales-pos-bottom: var(--mono-10);

      /* ENTRY EXIT PEOPLE COUNT*/
      --border-entrance-exit-peoplecounts-bottom: var(--mono-10);

      /* RANK PEOPLE COUNT*/
      --border-rank-peoplecounts-bottom: var(--mono-10);

      /* REVENUE PER SALES POS*/
      --border-revenue-per-sales-pos-bottom: var(--mono-10);

      /* SALES TRANSACTION POS*/
      --border-sales-transaction-pos-bottom: var(--mono-10);

      /* STAYING PEOPLE COUNT*/
      --border-staying-peoplecounts-bottom: var(--mono-10);

      /* AREA TRAFFIC */
      --fill-rect-area-traffic: var(--mono-7);

      /* Type People Count */
      --border-type-peoplecounts-bottom: var(--mono-7);

      /* ADD FORM */
      --background-add-form-default: var(--white);
      --border-add-form-default: var(--mono-10);

      /* WIDGETS */
      /* BASE WIDGET */
      --background-base-widget-default: rgb(12 12 12 / 50%);
      --background-base-widget-left: var(--white);
      --background-base-widget-setup-combo-button-default: var(--mono-10);
      --background-base-widget-setup-combo-button-hover: var(--mono-9);
      --background-base-widget-setup-combo-button-active: var(--mono-8);
      --background-base-widget-setup-combo-button-disabled: var(--mono-9);
      --background-base-widget-rules-select: var(--mono-10);
      --background-base-widget-thumbnail: #141414;

      --border-base-widget-expansion-default: var(--mono-9);
      --border-base-widget-expansion-hover: var(--border-emphasis);

      /* WIDGET */
      --background-base-widget-more-default: var(--mono-10);
      --background-base-widget-more-active: var(--mono-9);

      /* COUNT BY AREA QUEUE */
      --background-number-of-people-by-area-button: rgba(85, 150, 226, 0.2);
      --background-number-of-people-by-area-collapse-button-disabled: rgba(
        246,
        90,
        90,
        0.2
      );
      --background-number-of-people-by-area-detail-box-loading: var(--mono-10);
      --background-number-of-people-by-area-button-less-kpi: ${convertHexWithOpacity(
        PALETTES.LIGHT.SYSTEM_RED,
        20
      )};

      /* DOCK MANAGEMENT */
      --background-dock-management-default: var(--mono-9);
      --background-dock-management-active: rgba(85, 150, 226, 0.2);

      --text-dock-block-item-status: var(--mono-8);

      /* MAP IMAGE */
      --image-dim-layer-opacity: 5%;
      --image-dim-layer-color: #235371;

      /* FACTORY MAP */
      --background-factory-map-left: #2353710d;
      --background-factory-map-item-info: var(--mono-10);

      /* ILLEGAL PARKING */
      --background-illegal-parking-info-box: var(--mono-9);

      /* RETAIL MAP */
      --background-retail-map-left: #2353710d;

      /* TYPE PEOPLE COUNT SETUP */
      --border-type-people-count-setup-bottom: var(--mono-10);

      /* VEHICLE IN OUT EVENT */
      --background-vehicle-in-out-event-info-box: var(--mono-9);

      /* SLIP AND FALL */
      --background-slip-and-fall-meta: var(--mono-10);

      /* ZOOM IN AND OUT */
      --background-zoom-in-out: var(--white);

      /* PARTNER APP */
      --partnel-app-hover-background-color: var(--mono-10);
      --partnel-app-hover-border-color: #fde3d3;

      /* PAGES */
      /* EVENT */
      --border-event-text-box-today: var(--orange-4);
      --event-button-default: var(--mono-9);
      --event-button-hover: var(--mono-8);
      --event-button-press: var(--mono-7);
      --event-button-selected: var(--orange-primary);
      --background-duration-time: var(--mono-10);

      /* DEVICE SETUP */
      --background-device-setup-contents-left: var(--mono-10);

      /* USER ADMIN */
      --background-tooltip-password-guide: var(--white);
      --background-group-tree: var(--mono-10);
      --border-group-tree: var(--border-default);
      --icon-root-group-tree: var(--icon-default);
      --overlay-user-profile: #00000066;
      --background-root-group-tree-selected: var(--orange-6);

      /* USER ADMIN */
      --profile-divider: var(--mono-9);

      /* SCROLL BAR */
      --background-scroll-thumb: ${convertHexWithOpacity(
        PALETTES.DARK.MONO_2,
        40
      )};
      --border-scroll-thumb: var(--white);

      --background-tooltip: var(--white);

      --event-notification-background: var(--white);
      --event-notification-icon: var(--icon-emphasis);
      --event-notification-background-close: var(--mono-0);
      --event-notification-icon-close: var(--white);

      /* Event Player */
      --background-control-media: var(--mono-1);
      --background-control-bar: var(--mono-4);

      /* Slip And Fall */
      --border-th-td: var(--mono-9);
      --background-thead-tr: var(--mono-10);
      --background-row-data: var(--white);

      /*  Widget Form Style */
      --fill-outer-border: var(--mono-10);
      --fill-outer-border-active: var(--orange-7);

      --fill-rect-color-1: var(--mono-8);
      --fill-rect-color-2: var(--mono-8);
      --fill-rect-color-active: var(--orange-6);

      --fill-text-color: var(--mono-4);
      --fill-text-color-active: var(--mono-1);
      --fill-text-color-content: var(--mono-7);

      --stroke-outer-border: var(--mono-9);
      --stroke-outer-border-active: var(--orange-4);

      /*  Event Filter */
      --fill-path-color-arrow-filter: var(--icon-default);
      --fill-path-color-filter-hover: var(--mono-8);
      --background-icon-filter: var(--mono-10);
      --background-date-time-picker: var(--white);
      --background-filter-wrapper: var(--mono-10);
      --border-bottom-filter-item: var(--border-default);

      /* Pagination */
      --background-button-next-previous: var(--white);
      --background-button-page-default: var(--white);
      --background-button-page-hover: var(--mono-10);
      --background-button-page-selected: var(--orange-primary);

      /* Event timer */
      --fill-path-timer-item: var(--mono-10);
      --text-timer-color: var(--text-secondary-2);
      --background-button-confirmed: var(--mono-10);
      --text-channel-color: var(--white);
      --background-pagination-temp-statistics: var(--mono-10);

      /* Event action */
      --opacity-event-action-default: 0.8;
      --opacity-event-action-select: 1;

      --fill-icon-done-event-action: var(--white);
      --background-event-action-btn: var(--mono-9);
      --background-event-action-btn-hover: var(--mono-8);
      --background-event-action-btn-press: var(--mono-7);
      --background-event-action-btn-disabled: var(--mono-9);
      --border-event-action-btn: 1px solid
        ${convertHexWithOpacity(PALETTES.LIGHT.SYSTEM_EMERALD, 9)};

      /* Sequence */
      --background-slider-thumb: var(--icon-default);
      --background-conversion-content: var(--surface);

      --background-widget-main-list-head: var(--mono-9);
      --background-widget-main-list-row: var(--orange-6);

      /* Crowd Safety Statistics */
      --background-crowd-item: var(--mono-10);
      --text-crowd-location: var(--text-secondary-2);

      /* Number Of People By Are */
      --background-pin-hover: var(--mono-10);
      --background-pin-press: var(--mono-9);

      /* Stopped Vehicle Event */
      --fill-color-icon-vehicle-event: var(--icon-default);

      --claim-device-header: #e2e8ec;

      --device-setup-search-placeholder: var(--text-secondary-2);
      --device-setup-search-icon: var(--icon-default);

      --background-arrow-icon-default: var(--white);
      --background-arrow-icon-hover: var(--white);
      --background-arrow-icon-press: var(--mono-10);
      --background-arrow-icon-select: var(--white);

      --border-arrow-icon-default: var(--border-default);
      --border-arrow-icon-hover: var(--mono-8);
      --border-arrow-icon-press: var(--mono-8);
      --border-arrow-icon-select: var(---border-default);

      --icon-arrow-icon-default: var(--icon-default);
      --icon-arrow-icon-hover: var(--icon-default);
      --icon-arrow-icon-press: var(--icon-default);
      --icon-arrow-icon-select: var(--icon-default);

      --icon-beta: var(--mono-0);

      /* CITY MAP RETURN BUTTON */
      --icon-arrow-return: var(--icon-default);
      --text-return: var(--text-secondary-1);
      --border-return: var(--border-light);
      --background-return: var(--white);
      --box-shadow-return: rgba(0, 0, 0, 0.1);

      /* Notification BG State */
      --background-notification-default: rgba(238, 241, 242, 0.5);
      --background-notification-hover: var(--mono-10);
      --background-notification-press: var(--mono-9);
      --notification-shadow: rgba(0, 0, 0, 0.04);

      /* Event Icon */
      --background-event-icon: var(--mono-9);
      --event-icon-path: var(--icon-disabled);

      /* Profile Avatar */
      --background-profile-avatar: var(--orange-8);
      --border-profile-avatar: #ffffff;
      --text-profile-avatar: #f37321;
      /* REPORT */
      --report-contributors: var(--orange-5);
      --report-create-date: var(--mono-7);
      --report-popup-background: var(--mono-10);
      --report-content-hover: var(--orange-6);
      --add-report-btn-background: var(--mono-10);
      --add-report-btn-fill: var(--mono-7);
      --report-content-type-text-hover: var(--mono-1);
      --report-person-list: var(--light_white);
      --report-person-list-border: var(--mono-9);
      --report-list-active: var(--mono-0);
      --report-person-text-gray: var(--mono-4);
      --report-person-text: var(--orange-5);
      --report-person-background-gray: var(--mono-9);
      --report-person-background: var(--orange-8);
      --report-person-count-background: var(--mono-9);
      --report-person-count-color: var(--mono-4);
      --report-Icon-color: var(--mono-7);
      --report-edit-icon-color: var(--mono-7);
      --report-default-chart-icon-color: var(--mono-8);
      --report-default-chart-icon-bg: var(--mono-9);
      --selected-widget_background: var(--mono-8);
      --delete-widget-color: var(--light_white);
      --report-widget-hover: var(--light_white);
      --report-widget-icon-stroke-dark: var(--mono-7);
      --report-widget-icon-stroke-light: var(--mono-9);
      --report-widget-icon-stroke-outer: var(--mono-9);
      --report-insight-statement-container-background: var(--mono-9);
      --report-toastify-message-background: var(--light_white);
      --report-insight-statement-container-b: var(--mono-10);
      --report-body-divider: var(--mono-10);
      --report-modal-right-content: var(--white);
      --report-widget-disabled-input-bg: var(--mono-9);
      --report-input-text-color: var(--mono-10);
      --report-add-widget-icon-bg: var(--mono-9);
      --report-add-widget-icon-fill: var(--mono-7);
      --report-select-widget-container-bg: var(--mono-white);
      --report-select-widget-container-hover: var(--mono-8);
      --report-modal-chart-style-disabled: var(--mono-9);
      --report-modal-chart-style-default: var(--surface-widget);
      --report-modal-chart-style-hover: var(--mono-10);
      --report-modal-chart-style-select: var(--surface-widget);
      --report-modal-chart-style-disabled-default-hover-border: var(--mono-9);
      --report-modal-chart-style-select-border: var(--system-orange);
      --report-input-editor-background: var(--mono-9);
      --report-input-editor-hover: var(--mono-10);
      --report-background: var(--mono-9);
      --report-background-hover: var(--mono-10);

      --report-body-config-tab-bg: var(--mono-10);
      --report-graph-avg-line: var(--light_white);

      --report-graph-bar-stack-color: var(--mono-7);
      --report-header-background-color: var(--mono-9);

      --background-report-cancel-button-default: var(--mono-8);
      --background-report-cancel-button-hover: var(--mono-7);

      /* Hidden Widget */
      --background-hidden-widget-default: rgba(238, 241, 242, 0.5);
      --background-hidden-widget-hover: var(--mono-10);
      --background-hidden-widget-press: var(--mono-9);
      --report-text-editor-color: var(--mono-0);
      --report-email-user-list-hover: var(--orange-8);
      --report-email-chip-border: var(--border-default);
      --report-email-body-placeholder: var(--mono-8);
      --report-email-user-list-background-color: var(--mono-white);
      --report-email-pdf-title-background-color: var(--mono-white);

      /* Device Status */
      --background-device-status-default: var(--mono-10);
      --background-device-status-hover: var(--white);
      --background-device-status-press: var(--white);

      /* Licenses */
      --background-license-device: rgba(238, 241, 242, 0.5);

      /* Roles */
      --background-roles: var(--border-light);

      /* Rules Details */
      --rule-separator: var(--mono-10);
      --rule-active-text: var(--mono-4);
      --rule-inactive-text: var(--mono-8);
      --rule-devider: var(--mono-10);

      /* dashboard tree */
      --float-button: var(--white);
      --context-menu-hover: var(--mono-10);
      --context-menu-active: var(--mono-9);
      --drag-active-item: rgba(253, 219, 186, 0.5); // orange_7

      --white-black: #000000;

      /* rule tree */
      --tree-item-selected: ${convertHexWithOpacity(PALETTES.LIGHT.MONO_0, 8)};
      --tree-item-selected-left-bar: ${PALETTES.LIGHT.ORANGE_4};
      --tree-item-hover: ${convertHexWithOpacity(PALETTES.LIGHT.MONO_0, 4)};

      --event-filter-item-active: rgba(0, 0, 0, 0.08);
      --list-text-color: var(--mono-4);
      --list-text-color-active: var(--mono-1);

      --share-report-input-bg: var(--mono-white);
      --share-report-border: var(--mono-9);

      /* combined-map */
      --marker-hover-background: var(--white);

      --cal-text-color: ${convertHexWithOpacity(PALETTES.LIGHT.MONO_0, 77)};

      /* Analytics page */
      --background-analytics-notice-wrap: var(--white);
      --background-analytics-top-menu: var(--mono-9);
      --analytics-chart-title: var(--mono-4);
      --analytics-chart-icon: var(--icon-default);
      --analytics-chart-count-badge-bg: var(--mono-7);
      --analytics-chart-count-badge-text: var(--white);
      --analytics-popup-border-color: var(--mono-9);

      /* drop down */
      --border-dropdown: var(--mono-9);
      --border-dropdown-hover: #0000000a;
      --border-dropdown-active: #00000014;

      /* legend items */

      --button-background-color: var(--mono-0-8-per);
      --legend-background-color: var(--surface-modal);
      --legend-border-color: var(--border-light);
      --legend-box-color: #0000001a;
    }
  `
};
