import { Get } from "@configs/Axios";

export type WaveSyncDevice = {
  id: string;
  mac: string;
  name: string;
  systemId: string;
  status: string;
};

type WaveSyncHls = {
  url: string;
};

export type WaveSyncStatus = {
  linked: boolean;
};

const convertToUnixTimestamp = (dateString: string | undefined): number => {
  if (!dateString) return 0;
  const date = new Date(dateString);
  return date.getTime();
};
export const waveApis = {
  getUnlink: async (orgId: string) => {
    if (!orgId) return "";
    return Get<[]>(`/v1/organizations/${orgId}/wavesync/unlink`);
  },
  wavesyncStatus: async (orgId: string) => {
    return Get<WaveSyncStatus>(
      `/v1/organizations/${orgId}/wavesync/link-status`
    );
  },
  wavesyncDevice: async (orgId?: string) => {
    return Get<WaveSyncDevice[]>(
      `/v1/organizations/${orgId}/wavesync/systems/devices`
    );
  },
  wavesyncVideo: async (
    orgId: string,
    systemId: string,
    deviceId: string,
    timestamp?: string
  ) => {
    const unixTimestamp = convertToUnixTimestamp(timestamp);
    if (unixTimestamp === 0) return null;
    return Get<WaveSyncHls>(
      `/v1/organizations/${orgId}/wavesync/systems/${systemId}/devices/${deviceId}/hls?pos=${unixTimestamp}`
    );
  }
};
