import React, { ButtonHTMLAttributes, ReactNode, useId } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { BUTTON_STYLE_TYPE } from "@resources/Constants";
import { IconArrowRight } from "@resources/icons/Icons";
import { ButtonStyleType } from "@custom-types/Types";
import * as S from "./Button.styled";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string | ReactNode;
  hasArrow?: boolean;
  arrowCenter?: boolean;
  isLoading?: boolean;
  styleType?: ButtonStyleType;
  icon?: ReactNode;
}

const Button = ({
  label = "",
  hasArrow = false,
  isLoading = false,
  arrowCenter = false,
  styleType = BUTTON_STYLE_TYPE.FIRST,
  icon,
  className = "",
  ...props
}: ButtonProps) => {
  const uniq = useId();

  return (
    <S.ButtonStyled
      id={`button_${uniq}`}
      hasArrow={hasArrow}
      isLoading={isLoading}
      arrowCenter={arrowCenter}
      icon={!!icon}
      styleType={styleType}
      className={`btn-${styleType} ${className}`}
      {...props}
    >
      {isLoading ? <MoonLoader loading={isLoading} size={12} /> : null}
      {icon}
      {label && <S.ButtonLabel>{label}</S.ButtonLabel>}
      {hasArrow && (
        <S.ButtonIconWrapper>
          <IconArrowRight width={16} height={16} />
        </S.ButtonIconWrapper>
      )}
    </S.ButtonStyled>
  );
};

export { Button };
