import { TypeTimeInterval } from "@custom-types/Types";
import { TIME_INTERVAL, TYPE_TIME_INTERVAL } from "@resources/Constants";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

interface ChartIntervalParaps {
  baseTime: {
    start: string;
    end: string;
  };
}
export const useChartFilterInterval = (params: ChartIntervalParaps) => {
  const getType = (time: ChartIntervalParaps["baseTime"]) => {
    const { start, end } = time;
    const period = moment(end).diff(start, "day");
    if (period >= 14) {
      return TYPE_TIME_INTERVAL.DAY;
    }
    return TYPE_TIME_INTERVAL.HOUR;
  };
  const [type, setType] = useState<TypeTimeInterval>(getType(params.baseTime));
  const time = useMemo(() => {
    if (type === TYPE_TIME_INTERVAL.DAY) {
      return TIME_INTERVAL.DAY_1;
    }
    if (type === TYPE_TIME_INTERVAL.WEEK) {
      return TIME_INTERVAL.DAY_7;
    }

    if (type === TYPE_TIME_INTERVAL.MONTH) {
      return TIME_INTERVAL.MONTH_1;
    }

    return TIME_INTERVAL.HOUR_1;
  }, [type]);

  useEffect(() => {
    setType(getType(params.baseTime));
  }, [params.baseTime.start, params.baseTime.end]);

  const mapTypeToTimeInterval = (type: TypeTimeInterval) => {
    if (type === TYPE_TIME_INTERVAL.DAY) {
      return TIME_INTERVAL.DAY_1;
    }

    if (type === TYPE_TIME_INTERVAL.WEEK) {
      return TIME_INTERVAL.DAY_7;
    }

    if (type === TYPE_TIME_INTERVAL.MONTH) {
      return TIME_INTERVAL.MONTH_1;
    }

    return TIME_INTERVAL.HOUR_1;
  };

  const getTypeTime = (params: ChartIntervalParaps) => {
    const type = getType(params.baseTime);
    setType(getType(params.baseTime));
    return mapTypeToTimeInterval(type);
  };

  return {
    time,
    type,
    setType,
    getTypeTime,
    mapTypeToTimeInterval,
    getType
  };
};
