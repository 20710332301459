import { convertHexToRgb } from "@utils/Global";
import styled from "styled-components";

export const ScrollBarStyled = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  .scrollbar-content {
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .scrollbar-thumb {
    display: none;
    position: absolute;
    top: 0;
    right: 4px;
    bottom: 6px;
    width: 6px;
    background-color: rgba(${convertHexToRgb("#141414")}, 0.4);
    border: solid 1px rgba(${convertHexToRgb("#fff")}, 0.4);
    border-radius: 3px;
  }
`;
