import styled from "styled-components";
import { StandardScrollBarStyle } from "@themes/GlobalStyles";

export const SelectStyled = styled.div<{
  $minWidth?: string;
}>`
  font-size: 14px;
  cursor: pointer;
  position: relative;

  .select-box {
    min-height: 32px;
    display: flex;
    min-width: ${(props) => (props.$minWidth ? props.$minWidth : "100%")};
    max-width: 200px;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px;
    border-radius: 4px;
    color: var(--text-secondary-1);
    background-color: var(--surface-modal);
    border: 1px solid var(--border-default);

    svg path {
      fill: var(--icon-default);
    }

    .select-icon {
      display: flex;
      align-items: center;

      > div {
        height: 12px;
        display: flex;
        align-items: center;
      }
    }

    .select-icon-remove {
      display: none;
    }

    &__disabled {
      color: var(--text-disabled) !important;
      background-color: var(--background-ibb-disabled) !important;
      border: 1px solid var(--border-default) !important;
      cursor: not-allowed;
      pointer-events: none;
      .select-icon-remove,
      .select-icon-arrow {
        svg path {
          fill: var(--icon-disabled) !important;
        }
      }
    }

    &:active,
    &__expanded {
      color: var(--text-primary);
      background-color: var(--surface-widget) !important;
      border: 1px solid var(--border-default) !important;

      svg path {
        fill: var(--icon-default) !important;
      }
    }

    &:hover {
      color: var(--text-primary);
      background-color: var(--surface-popup);
      border: 1px solid var(--border-emphasis);

      svg path {
        fill: var(--icon-emphasis);
      }

      .select-icon-remove {
        display: block;
        position: absolute;
        top: 5px;
        right: 20px;

        svg {
          width: 20px;
        }
      }
    }

    .select-icon-remove,
    .select-icon-arrow {
      svg path {
        fill: var(--icon-emphasis);
      }
    }

    > div:last-child {
      display: flex;
      align-items: center;
    }

    > div:first-child {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: normal;
    }
  }
`;

export const OptionList = styled.div<{
  minWidth: string;
}>`
  position: absolute;
  color: var(--text-primary);
  box-sizing: border-box;
  border: 1px solid var(--border-dropdown);
  outline: var(--border-light);
  box-shadow: var(--shadow-small);
  border-radius: 4px;
  background-color: var(--background-ibb-default);
  min-width: ${(props) => (props.minWidth ? props.minWidth : "100%")};
  max-width: 471px;
  max-height: 136px;
  overflow-y: auto;
  z-index: 99;
  margin-top: 4px;
  padding: 4px 0px;
  cursor: default;
  ${StandardScrollBarStyle}

  .scrollbar-content {
    max-height: 200px;
  }

  .selected-option {
    min-height: 32px;
    height: 32px;
    align-items: center;
    max-width: 450px;
    padding: 7px 12px 8px 8px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    border-left: 2px solid transparent;

    .selected-option-content {
      display: block;
      white-space: nowrap;
      max-width: 430px;
      line-height: normal;
      overflow: hidden;
    }

    &:hover,
    &:focus {
      border-radius: 2px;
      background-color: var(--border-dropdown-hover);
    }

    &__active {
      /* font-weight: bold; */
      border-left: 2px solid var(--orange-primary);
      background-color: var(--border-dropdown-active);
    }
    &__disabled {
      color: var(--text-disabled);

      &:hover,
      &:focus {
        background-color: initial;
      }
    }
  }
`;
