import { GetLicenseResponse } from "@queries/License";
import { ON_CLOUD_STATUS, WAVE_SYNC_STATUS } from "@resources/Constants";
import { WaveSyncDevice } from "@queries/Wave";
import { LinkedLicenses } from "@custom-types/Types";

export function generateDevicesInformation<T>(
  allDevices: (T & { id?: string; gatewayId?: string; mac?: string })[],
  licenseList: GetLicenseResponse[],
  waveSyncDevices: WaveSyncDevice[]
): (T & { linkedLicenses: LinkedLicenses })[] {
  if (!allDevices) return [];

  const mappedLicenseList = allDevices.reduce(
    (result, device) => {
      return {
        ...result,
        [device.id as string]:
          licenseList?.filter((license) => license.gatewayId === device.id) ||
          []
      };
    },
    {} as Record<string, GetLicenseResponse[]>
  );

  return allDevices
    .filter((device) => device.id !== device.gatewayId)
    .map((device) => {
      return {
        ...device,
        linkedLicenses: {
          onCloud:
            device.id &&
            mappedLicenseList &&
            mappedLicenseList[device.id] &&
            mappedLicenseList[device.id][0]?.serviceId === "vms-service"
              ? ON_CLOUD_STATUS.LINKED
              : ON_CLOUD_STATUS.LINK_DISCONNECT,
          waveSync:
            waveSyncDevices &&
            waveSyncDevices.filter(
              (waveSyncDevice) =>
                waveSyncDevice.mac?.replace(/-/g, "") ===
                device.mac?.replace(/:/g, "")
            ).length > 0
              ? WAVE_SYNC_STATUS.LINKED
              : WAVE_SYNC_STATUS.LINK_DISCONNECT
        }
      };
    });
}
