import styled from "styled-components";
import { StandardScrollBarStyle } from "@themes/GlobalStyles";

export const Container = styled.section`
  width: 560px;
  border-radius: 20px;
  background-color: var(--background-ibb-default);
  border: 1px solid var(--border-default);

  .react-grid-item {
    &.react-grid-placeholder {
      border-radius: 8px;
      background-color: var(--orange-8);
      opacity: 1;
    }
  }

  .react-grid-layout {
    margin: 0 4px;
    ${StandardScrollBarStyle}
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 28px 32px 20px;
`;

export const Title = styled.h1`
  font-size: 20px;
  color: var(--text-primary);
  font-weight: 600;
`;

export const ListItem = styled.li`
  width: 496px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin: 24px 32px;
`;
