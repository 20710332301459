import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { userApi } from "@queries/User";
import queryKeys from "../../constants/queryKeys";

export default function useOrganizations() {
  const {
    data: organizations,
    isSuccess,
    isFetched,
    error,
    refetch
  } = useQuery({
    queryKey: [queryKeys.ORGANIZATIONS],
    queryFn: async () => userApi.getOrganizations(),
    staleTime: 60 * 1000
  });

  return {
    organizations,
    isSuccess,
    isFetched,
    error: error as AxiosError,
    refetch
  };
}
