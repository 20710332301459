import styled from "styled-components";

export const DateRangePickerContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin-right: 18px;
  /* dmpro */
  /* color: $ {({ theme }) => theme.color.mono1}; */
  /* sightmind */
  color: var(--text-primary);
  background-color: var(--background-dashboard-date-range-default);
  position: relative;
  border-radius: 3px;
  z-index: 500;
  height: 24px;
  padding: 0 8px;
  cursor: pointer;

  &:not(.no-select):hover {
    /* dmpro */
    /* background-color: $ {({ theme }) => theme.color.mono8}; */
    /* sightmind */
    background-color: var(--background-dashboard-date-range-hover);
  }

  .icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    /* dmpro */
    /* background-color: $ {({ theme }) => theme.color.mono9}; */
    /* sightmind */
    background-color: var(--background-dashboard-date-range-icon-default);

    &:first-of-type {
      margin-left: 8px;
    }

    &:last-of-type {
      margin-right: 8px;
    }

    svg {
      path {
        /* dmpro */
        /* fill: $ {({ theme }) => theme.color.mono7}; */
        /* sightmind */
        fill: var(--icon-default);
      }
    }

    &:hover {
      /* dmpro */
      /* background-color: $ {({ theme }) => theme.color.mono8}; */
      /* sightmind */
      background-color: var(--border-emphasis);

      path {
        /* dmpro */
        /* fill: $ {({ theme }) => theme.color.mono1}; */
        /* sightmind */
        fill: var(--icon-emphasis);
      }
    }

    &:active {
      /* dmpro */
      /* background-color: $ {({ theme }) => theme.color.mono7}; */
      /* sightmind */
      background-color: var(--background-dashboard-date-range-icon-active);

      path {
        /* dmpro */
        /* fill: $ {({ theme }) => theme.color.mono1}; */
        /* sightmind */
        fill: var(--icon-emphasis);
      }
    }

    &:disabled {
      /* dmpro */
      /* background-color: $ {({ theme }) => theme.color.mono10}; */
      /* sightmind */
      background-color: var(--background-tree-default);

      path {
        /* dmpro */
        /* fill: $ {({ theme }) => theme.color.mono8}; */
        /* sightmind */
        fill: var(--icon-disabled);
      }
    }
  }

  > .date-range-popup {
    position: absolute;
    top: 28px;
    right: 0;
  }

  .icon-calendar {
    display: flex;
    align-items: center;
    svg {
      path {
        /* dmpro */
        /* fill: $ {({ theme }) => theme.color.mono7}; */
        /* sightmind */
        fill: var(--icon-default);
      }
    }
  }

  .date-range-picker-control {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 4px;

    > div {
      display: flex;
      align-items: center;
    }

    .period {
      /* dmpro */
      /* color: $ {({ theme }) => theme.color.mono4}; */
      /* sightmind */
      color: var(--text-secondary-1);
      white-space: nowrap;
      display: flex;
      overflow: hidden;
    }

    .circle {
      width: 6px;
      height: 6px;
      /* dmpro */
      /* background-color: $ {({ theme }) => theme.color.systemRed}; */
      /* sightmind */
      background-color: var(--system-red);
      border-radius: 50%;
      margin-right: 4px;
    }

    .mode {
      padding-right: 8px;
      margin-right: 8px;
      /* dmpro */
      /* border-right: 1px solid $ {({ theme }) => theme.color.mono9}; */
      /* sightmind */
      border-right: 1px solid var(--border-default);
    }
  }

  &.disable-preview-mode {
    width: fit-content;
    height: fit-content;
    background: none !important;
    padding: 0;

    > div {
      position: relative;
      top: 0px;
    }

    .date-range-picker-control {
      display: none;
    }
  }
`;

export const DateRangePickerStyled = styled.div`
  width: fit-content;
  /* dmpro */
  /* border: 1px solid $ {({ theme }) => theme.color.mono10};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.32);
  background-color: $ {({ theme }) => theme.color.mono0}; */
  /* sightmind */
  border: 1px solid var(--border-light);
  box-shadow: var(--shadow-2-small);
  background-color: var(--background-date-default);
  border-radius: 16px;

  > div:first-child {
    display: flex;
  }

  .date-range-popup-sidebar {
    width: 180px;
    border-radius: 16px 0 0 16px;
    font-size: 14px;
    line-height: 18px;
    padding-top: 12px;
    /* dmpro */
    /* color: $ {({ theme }) => theme.color.mono7};
    background-color: $ {({ theme }) => theme.color.mono10}; */
    /* sightmind */
    color: var(--text-secondary-2);
    background-color: var(--background-date-range-popup-panel);

    .scrollbar-content {
      margin-bottom: -15px !important;

      > button {
        cursor: pointer;
        /* dmpro */
        /* color: $ {({ theme }) => theme.color.mono4}; */
        /* sightmind */
        color: var(--cal-text-color);
        width: 180px;
        height: 32px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-left: 2px solid transparent;

        &:hover {
          border-radius: 0;
          /* dmpro */
          /* background-color: rgba(255, 255, 255, 0.4)
          color: $ {({ theme }) => theme.color.mono1}; */
          /* sightmind */
          background-color: var(--mono-0-4-per);
          color: var(--text-primary);
        }

        &.active {
          /* dmpro */
          /* color: $ {({ theme }) => theme.color.mono1};
          background-color: rgba(255, 255, 255, 0.8);
          border-color: $ {({ theme }) => theme.color.orange040}; */
          /* sightmind */
          color: var(--text-primary) !important;
          background-color: var(--mono-0-8-per);
          border-color: var(--orange-4);
          border-radius: 0;
          opacity: 0.8;
        }
      }
    }
  }

  .time-range {
    display: flex;
    flex-direction: column;
    padding: 16px 20px 0;
    /* dmpro */
    /* border-top: 1px solid rgba(255, 255, 255, 0.8) */
    /* sightmind */
    border-top: 1px solid var(--mono-0-8-per);
  }
  .time-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    :first-child {
      margin-bottom: 16px;
    }
  }
  .time-label-date {
    display: flex;
    align-items: center;
    line-height: 18px;
    gap: 8px;
  }
  .time-label {
    font-size: 14px;
    font-weight: 600;
    /* dmpro */
    /* color: $ {({ theme }) => theme.color.mono1}; */
    /* sightmind */
    color: var(--mono-1);
  }
  .time-date {
    font-size: 14px;
    font-weight: 400;
    /* dmpro */
    /* color: $ {({ theme }) => theme.color.mono3}; */
    /* sightmind */
    color: var(--mono-3);
  }
  .time-input-group {
    display: flex;
    align-items: center;
    gap: 4px;
    input {
      &:disabled {
        /* dmpro */
        /* color: $ {({ theme }) => theme.color.mono8};
        background-color: $ {({ theme }) => theme.color.mono9}; 
        border: 1px solid $ {({ theme }) => theme.color.mono9}; */
        /* sightmind */
        color: var(--text-disabled);
        background-color: var(--background-ibb-disabled) !important;
        border: 1px solid var(--border-default) !important;
        pointer-events: none;
        cursor: not-allowed;
        &:hover,
        &:focus {
          background-color: initial;
        }
      }
    }
    button {
      svg path {
        /* dmpro */
        /* fill: $ {({ theme }) => theme.color.mono1}; */
        /* sightmind */
        stroke: var(--text-secondary-1);
        stroke-opacity: 1;
      }
      &:disabled {
        /* dmpro */
        /* color: $ {({ theme }) => theme.color.mono8}; */
        /* sightmind */
        color: var(--text-disabled);
        pointer-events: none;
        cursor: not-allowed;
        svg path {
          /* dmpro */
          /* fill: $ {({ theme }) => theme.color.mono8}; */
          /* sightmind */
          stroke: var(--icon-disabled) !important;
          stroke-opacity: 1;
        }
        &:hover,
        &:focus {
          background-color: initial;
        }
      }
    }
    span {
      /* dmpro */
      /* color: $ {({ theme }) => theme.color.mono4}; */
      /* sightmind */
      color: var(--text-secondary-1);
    }
  }
  .input-arrow-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  .arrow-container {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
  }
  .arrow-container button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input[type="number"] {
    width: 60px;
    height: 32px;
    padding: 7px 8px;
    font-size: 14px;
    border-radius: 4px;
    /* dmpro */
    /* color: $ {({ theme }) => theme.color.mono4}; 
    background-color: $ {({ theme }) => theme.color.mono0}; 
    border: 1px solid $ {({ theme }) => theme.color.mono8}; */
    /* sightmind */
    color: var(--text-secondary-1);
    background-color: var(--surface-modal);
    border: 1px solid var(--border-emphasis);
    outline: none;
    box-sizing: border-box;
  }
  input[type="number"]:hover,
  input[type="number"]:active {
    /* dmpro */
    /* color: $ {({ theme }) => theme.color.mono1};
    background-color: $ {({ theme }) => theme.color.mono0};
    border: 1px solid $ {({ theme }) => theme.color.mono8}; */
    /* sightmind */
    color: var(--text-primary);
    background-color: var(--surface-popup);
    border: 1px solid var(--border-emphasis);
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .error-border {
    /* dmpro */
    /* border: 1px solid $ {({ theme }) => theme.color.systemRed}; */
    /* sightmind */
    border: 1px solid var(--system-red) !important;
  }
  .date-range-calendar {
    display: flex;
    flex-direction: column;
  }
  .button-submit {
    margin-left: auto;
    padding: 20px;
    button {
      min-width: 80px;
    }
  }

  .toggle-button {
    width: 46px;

    font-size: 14px;
    border-radius: 0;
    /* dmpro */
    /* color: $ {({ theme }) => theme.color.mono4};
    background-color: $ {({ theme }) => theme.color.mono0}; */
    /* sightmind */
    color: var(--mono-3);
    background-color: var(--surface-modal);
    outline: none;
    box-sizing: border-box;
  }

  .toggle-button:hover {
    /* dmpro */
    /* color: $ {({ theme }) => theme.color.mono1};
    background-color: $ {({ theme }) => theme.color.mono0}; */
    /* sightmind */
    color: var(--text-primary);
    background-color: var(--surface-popup);
  }
  .toggle-button.active {
    /* dmpro */
    /* background-color: $ {({ theme }) => theme.color.mono8}; */
    /* sightmind */
    background-color: var(--border-emphasis);
    color: var(--text-primary);
  }

  .toggle-button:first-child {
    border-top-left-radius: 1px !important;
    border-bottom-left-radius: 1px !important;
  }

  .toggle-button:last-child {
    border-top-right-radius: 1px !important;
    border-bottom-right-radius: 1px !important;
  }

  .am-pm-toggle {
    width: 92px;
    display: flex;
    height: 32px;
    /* dmpro */
    /* border: 1px solid $ {({ theme }) => theme.color.mono8}; */
    /* sightmind */
    border: 1px solid var(--border-emphasis);
    border-radius: 4px;
    overflow: hidden;
    margin-left: 4px;

    button:focus-visible {
      outline: 2px solid;
    }

    .toggle-button.active:disabled {
      /* dmpro */
      /* background-color: $ {({ theme }) => theme.color.mono9}; */
      /* sightmind */
      background-color: var(--background-ibb-disabled);
    }
  }

  /* stylelint-disable selector-class-pattern */
  .react-datepicker {
    width: 344px;
    border: 0;
    border-radius: 16px;
    box-shadow: none;

    .react-datepicker__month {
      padding: 0 16px 20px;
    }
    .react-datepicker__day-names {
      margin: 16px 0 12px;
    }

    > .react-datepicker__month-container {
      width: 100%;
      height: 324px;

      + .react-datepicker__month-container {
        border-right: 0;
        padding-right: 0;
      }
    }
    &__day:not([aria-disabled="true"]):hover {
      background-color: transparent;
    }
  }
  /* stylelint-enable */

  .date-range-picker {
    &__right {
      .react-datepicker {
        &__day {
          .date-picker-custom-today {
            /* dmpro */
            /* color: $ {({ theme }) => theme.color.mono4};
            background-color: $ {({ theme }) => theme.color.mono9}; */
            /* sightmind */
            color: var(--text-secondary-1);
            background-color: var(--background-ibb-disabled);
            border: 2px solid transparent;
          }
        }
      }
    }
  }
`;
