import styled from "styled-components";

export const ContextMenuStyled = styled.div`
  font-size: 14px;
  position: relative;
  z-index: 50;

  > ul {
    transition: visibility 0.3s ease-in-out;
  }

  ul {
    min-width: 100px;
    background-color: var(--background-tooltip);
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0 0 0 / 10%);
    border: 2px solid var(--border-light);
    position: absolute;
    z-index: 5;
    right: 0;

    ul {
      left: 100px;
      visibility: hidden;
      transition: visibility 0.3s ease-in-out;
      top: 0;
      max-width: 200px;
      width: max-content;
    }
    .has-icon {
      display: flex;
      align-items: center;
      svg {
        margin-right: 6px;
      }
    }
    > li {
      button {
        border-radius: 2px;
        color: var(--text-primary);
        display: block;
        width: 100%;
        height: 100%;
        text-align: left;
        padding: 5px 6px;
        cursor: pointer;
        align-items: center;
        position: relative;
        line-height: 18px;

        &:hover,
        &:focus {
          background-color: var(--background-li-context-menu-hover);
          color: var(--text-li-context-menu-hover);
        }
      }
      
      li {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &:last-child {
        color: var(--system-red);
      }

      .icon {
        position: absolute;
        right: 6px;
      }

      &:hover {
        color: var(--text-li-context-menu-hover);
        background-color: var(--background-li-context-menu-hover);
        svg path {
          fill: var(--fill-path-li-context-menu-hover);
        }
        &:last-child {
          color: var(--system-red);
        }
        ul,
        li {
          visibility: visible;
        }
      }

      &:active {
        background-color: var(--background-li-context-menu-active);
      }

      > div {
        width: 24px;
        display: flex;
        align-items: center;
      }
    }
  }
`;
