import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
  useInfiniteQuery,
  UseQueryOptions
} from "@tanstack/react-query";
import {
  devicesApis,
  GetDevicesRequest,
  GetDeviceRequest,
  CreateDeviceRequest,
  DeleteDeviceRequest,
  ConnectionTestDeviceRequest,
  CreateWaveDeviceRequest,
  AuthenticateDeviceRequest,
  UpdateDeviceRequest,
  ClaimDeviceParam,
  ClaimDevices,
  SnapshotResponse,
  GetDeviceResponse,
  GetPortalDevicesConfigResponse,
  PortalDevicesConfigParms,
  GetPortalDevicesResponse
} from "@queries/Devices";
import { WidgetRuleData } from "@components/widgets/base/setup/widget-rule-info/Interfaces";
import { keys } from "./keys";

export const useGetAllDeviceQuery = (
  params: GetDevicesRequest,
  options?: Parameters<typeof useQuery>[2]
) => {
  return useQuery(
    keys.getDeviceAll(),
    () => devicesApis.getAll(params),
    options
  );
};

export const useGetDeviceQuery = (
  params: GetDeviceRequest,
  options?: Parameters<typeof useQuery>[2]
) => {
  return useQuery(
    keys.getDevice(params.deviceId),
    () => devicesApis.get(params),
    options
  );
};

export const useCreateDeviceQuery = ({
  params,
  signal
}: {
  params: CreateDeviceRequest;
  signal?: AbortSignal;
}) => {
  return useMutation(() => devicesApis.create(params, signal));
};

export const useDeleteDeviceQuery = ({
  params,
  signal
}: {
  params: DeleteDeviceRequest;
  signal?: AbortSignal;
}) => {
  return useMutation(() => devicesApis.delete(params, signal));
};

export const useConnectionTestQuery = (params: ConnectionTestDeviceRequest) => {
  return useMutation(() => devicesApis.connectionTest(params));
};

export const useCreateWaveDeviceQuery = ({
  params,
  signal
}: {
  params: CreateWaveDeviceRequest;
  signal?: AbortSignal;
}) => {
  return useMutation(() => devicesApis.createWaveDevice(params, signal));
};

export const useAuthenticateQuery = (params: AuthenticateDeviceRequest) => {
  return useMutation(() => devicesApis.authenticate(params));
};

export const useUpdateDeviceMutation = (params: UpdateDeviceRequest) => {
  return useMutation(() => devicesApis.update(params));
};

export const useUpdateDeviceListMutation = (
  orgId: string,
  options?: Parameters<typeof useMutation>[2]
) => {
  return useMutation(
    (deviceIds: ClaimDeviceParam[]) => devicesApis.refresh(orgId, deviceIds),
    options
  ) as UseMutationResult<GetDeviceResponse>;
};

export const useClaimDeviceAllMutation = (
  orgId: string,
  options?: Parameters<typeof useMutation>[2]
) => {
  return useMutation(
    () => devicesApis.claimAllDevices(orgId),
    options
  ) as UseMutationResult<
    GetPortalDevicesConfigResponse,
    unknown,
    void,
    unknown
  >;
};

export const useGetPortalDevicesConfigQuery = (
  params: PortalDevicesConfigParms,
  options?: Parameters<typeof useQuery>[2]
) => {
  return useQuery(
    keys.getPortalDeviceConfig(),
    () => devicesApis.getPortalDevicesConfig(params),
    options
  ) as UseQueryResult<GetPortalDevicesConfigResponse>;
};

export const useGetPortalDeviceAllQuery = (
  orgId: string,
  options?: Parameters<typeof useQuery>[2]
) => {
  return useQuery(
    keys.getPortalDevice(),
    () => devicesApis.getAllPortalDevices(orgId),
    options
  );
};

/**
 * @WARNING There are cases where different KEY is used for the same API, so it is necessary to check whether integration is possible before applying.
 */
export const useGetPortalDevicesQuery = (
  { orgId, sightmindStatus }: { orgId: string; sightmindStatus: boolean },
  options?: UseQueryOptions<GetPortalDevicesResponse>
) => {
  return useQuery<GetPortalDevicesResponse>(
    keys.getPortalDevice(),
    () => devicesApis.getPortalDevices(orgId, sightmindStatus),
    options
  );
};

/**
 * @WARNING Additional implementation required during code refactoring
 */
export const useGetPortalDevicesInifiniteQuery = ({
  orgId,
  sightmindStatus
}: {
  orgId: string;
  sightmindStatus: boolean;
}) => {
  return useInfiniteQuery(
    keys.getPortalDevice(),
    ({ pageParam = 1 }) =>
      devicesApis.getPortalDevicesWithPagination(
        orgId,
        sightmindStatus,
        pageParam
      ),
    {
      getNextPageParam: () => {
        // TODO : Based on "portalDevices-pagination" key, query state mutation code needs to be written
        // return prePageParam + 1
      }
    }
  );
};

/**
 * @WARNING because of dependency issues, it must be migrated together with "useGetPortalDevicesWithPagination" function
 */
export const useClaimDevicesQuery = (params: {
  orgId: string;
  device: ClaimDevices;
}) => {
  const { orgId, device } = params;
  return useMutation(() => devicesApis.claimDevices(orgId, device));
};

export const useGetDeviceConfigQuery = (
  { orgId, gatewayId }: { orgId: string; gatewayId: string },
  options?: Parameters<typeof useQuery>[2]
) => {
  return useQuery(
    keys.getPortalDeviceConfigWithGateway(),
    () => devicesApis.getDeviceConfig(orgId, gatewayId),
    options
  );
};

export const useUnclaimDevicesQuery = (params: {
  orgId: string;
  device: ClaimDeviceParam;
}) => {
  const { orgId, device } = params;
  return useMutation(() =>
    devicesApis.unClaimDevice(orgId, { deviceIds: device })
  );
};

export const useGetSnapshotQuery = (
  params: {
    deviceId: WidgetRuleData["deviceId"];
    channel: WidgetRuleData["channel"];
    orgId: string;
  },
  options?: Parameters<typeof useQuery>[2]
) => {
  const { deviceId = "", channel = 0, orgId = "" } = params;
  return useQuery(
    keys.getDeviceSnapshot(deviceId),
    () =>
      devicesApis.getSnapshot(orgId, deviceId, {
        channel
      }),
    options
  ) as UseQueryResult<SnapshotResponse>;
};
