import {
  CHECK_MODEL,
  DataTreeViewItem,
  DataTreeViewItemValue,
  FlatNodeItem,
  FlatNodes,
  NodeModelBase,
  TreeViewProps
} from "./Interfaces";

const nodeHasChildren = (node: DataTreeViewItem) =>
  Array.isArray(node.children);

const getDisabledState = (
  node: DataTreeViewItem,
  parent: DataTreeViewItem | null,
  disabledProp: boolean
) => {
  if (disabledProp) {
    return true;
  }

  if (parent?.disabled) {
    return true;
  }

  return Boolean(node.disabled);
};

class NodeModel implements NodeModelBase {
  props: TreeViewProps;

  nodes: DataTreeViewItem[];

  flatNodes: FlatNodes;

  nodeHasChildren: (node: DataTreeViewItem) => boolean;

  getDisabledState: (
    node: DataTreeViewItem,
    parent: DataTreeViewItem | null,
    disabledProp: boolean
  ) => boolean;

  constructor(props: TreeViewProps, nodes?: FlatNodes) {
    this.props = props;
    this.nodes = props.nodes;
    this.flatNodes = nodes ?? {};
    this.nodeHasChildren = nodeHasChildren.bind(this);
    this.getDisabledState = getDisabledState.bind(this);
  }

  setProps(props: TreeViewProps) {
    this.props = props;
  }

  addNode(data: DataTreeViewItem, parent: DataTreeViewItemValue | null) {
    if (!parent) {
      throw new Error("You must specify a parent node!");
    }

    const node = this.findNode(this.nodes, parent);

    if (!node) {
      throw new Error("You must specify a parent node!");
    }

    node.children = node.children || [];
    node.children.push(data);
    this.flattenNodes(this.nodes, null);
  }

  addNodes(data: DataTreeViewItem[], parent: DataTreeViewItemValue | null) {
    if (!parent) {
      throw new Error("You must specify a parent node!");
    }

    const node = this.findNode(this.nodes, parent);

    if (!node) {
      throw new Error("You must specify a parent node!");
    }

    node.children = (node.children || []).concat(data);
  }

  setNodes(
    data: DataTreeViewItem[] | undefined,
    parent: DataTreeViewItemValue | null
  ) {
    if (!parent) {
      throw new Error("You must specify a parent node!");
    }
    const node = this.findNode(this.nodes, parent);

    if (!node) {
      // root node
      this.nodes = data || [];
      this.flattenNodes(this.nodes, null);
      return;
    }

    node.children = data;
    this.flattenNodes(this.nodes, null);
  }

  findNode(
    nodes: DataTreeViewItem[],
    value: DataTreeViewItemValue
  ): DataTreeViewItem | null {
    for (let index = 0; index < nodes.length; index += 1) {
      const node = nodes[index];
      if (node.value === value) {
        return node;
      }

      if (node.children?.length) {
        const subNode = this.findNode(node.children, value);
        if (subNode) {
          return subNode;
        }
      }
    }

    return null;
  }

  clone() {
    const clonedNodes: FlatNodes = {};

    Object.keys(this.flatNodes).forEach((value) => {
      const node = this.flatNodes[value];
      clonedNodes[value] = { ...node };
    });

    return new NodeModel(this.props, clonedNodes);
  }

  getNode(value: string | number) {
    return this.flatNodes[value];
  }

  reset() {
    this.flatNodes = {};
  }

  serializeList(key: "checked" | "expanded") {
    const list: FlatNodeItem[] = [];

    Object.keys(this.flatNodes).forEach((value) => {
      if (this.flatNodes[value][key]) {
        list.push(this.flatNodes[value]);
      }
    });

    return list;
  }

  flattenNodes(
    nodes: DataTreeViewItem[] | undefined,
    parent: DataTreeViewItem | null
  ) {
    if (!Array.isArray(nodes) || nodes.length === 0) {
      return;
    }

    const { disabled } = this.props;

    nodes.forEach((node, index) => {
      const isParent = this.nodeHasChildren(node);

      const defaultValues = this.flatNodes[node.value] ?? {
        checked: false,
        expanded: false
      };

      this.flatNodes[node.value] = {
        ...defaultValues,
        ...{
          label: node.label,
          value: node.value,
          children: node.children,
          parent,
          isChild: parent?.value !== undefined,
          status: node.status,
          isParent,
          isLeaf: !isParent,
          showCheckbox: node.showCheckbox,
          disabled: this.getDisabledState(node, parent, disabled),
          metaData: node.metaData,
          onChecked: node.onChecked,
          onClicked: node.onClicked,
          onExpanded: node.onExpanded,
          index,
          permissions: node?.permissions,
          thumbnail: node?.thumbnail,
          sketchbook: node?.sketchbook,
          licenseStatus: node?.licenseStatus
        }
      };
      this.flattenNodes(node.children, node);
    });
  }

  expandAllNodes(expand: boolean) {
    Object.keys(this.flatNodes).forEach((value) => {
      if (this.flatNodes[value].isParent) {
        this.flatNodes[value].expanded = expand;
      }
    });
  }

  toggleChecked(
    node: DataTreeViewItem | FlatNodeItem,
    isChecked: boolean,
    checkModel: number,
    percolateUpward = true
  ) {
    const flatNode = this.flatNodes[node.value];
    const modelHasParents =
      [CHECK_MODEL.PARENT, CHECK_MODEL.ALL].indexOf(checkModel) > -1;
    const modelHasLeaves =
      [CHECK_MODEL.LEAF, CHECK_MODEL.ALL].indexOf(checkModel) > -1;

    if (flatNode.isLeaf) {
      if (node.disabled) {
        return;
      }

      this.toggleNode(node.value, "checked", isChecked);
    } else {
      if (modelHasParents || flatNode.children?.length === 0) {
        this.toggleNode(node.value, "checked", isChecked);
      }

      if (modelHasLeaves) {
        this.toggleNode(node.value, "checked", isChecked);
        flatNode.children?.forEach((child) => {
          this.toggleChecked(child, isChecked, checkModel, false);
        });
      }
    }

    if (percolateUpward && flatNode.parent && modelHasParents) {
      this.toggleParentStatus(flatNode.parent, checkModel);
    }
  }

  toggleParentStatus(node: DataTreeViewItem, checkModel: number) {
    const flatNode = this.flatNodes[node.value];

    if (flatNode.isChild) {
      if (checkModel === CHECK_MODEL.ALL) {
        this.toggleNode(
          node.value,
          "checked",
          this.isEveryChildChecked(flatNode)
        );
      }

      if (flatNode.parent) {
        this.toggleParentStatus(flatNode.parent, checkModel);
      }
    } else {
      this.toggleNode(
        node.value,
        "checked",
        this.isEveryChildChecked(flatNode)
      );
    }
  }

  isEveryChildChecked(node: DataTreeViewItem): boolean {
    if (!node.children) {
      return false;
    }

    return node.children.every(
      (child: DataTreeViewItem) => this.getNode(child.value).checked
    );
  }

  toggleNode(
    nodeValue: string | number,
    key: "checked" | "expanded",
    toggleValue: boolean
  ) {
    this.flatNodes[nodeValue][key] = toggleValue;
  }
}

export default NodeModel;
