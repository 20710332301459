import { createContext } from "react";

export type ToastInfo = {
  message: string;
  type?: "success" | "error";
};

type ToastContextType = {
  toastInfo?: ToastInfo;
  setToastInfo: (toastInfo?: ToastInfo) => void;
};

const defaultValue = {
  toastInfo: undefined,
  setToastInfo: () => {
    throw new Error("The setMessage function is not implemented.");
  }
};

export const ToastContext = createContext<ToastContextType>(defaultValue);
