export const keys = {
  all: ["device"] as const,
  getDeviceAll: () => [...keys.all, "list"] as const,
  getDevice: (deviceId: string) => [...keys.getDeviceAll(), deviceId] as const,
  getDeviceSnapshot: (deviceId: string) =>
    [...keys.getDevice(deviceId), "snapshot"] as const,
  getPortalDevice: () => [...keys.all, "portal"] as const,
  getPortalDeviceConfig: () => [...keys.getPortalDevice(), "config"] as const,
  getPortalDeviceConfigWithGateway: () =>
    [...keys.getPortalDevice(), "gateway-config"] as const
};
