import styled from "styled-components";

export const Box = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  background-color: var(--white);
  border-radius: 2px;
  border: 1.5px solid ${({ color }) => color};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  font-size: 9px;
`;

export const Chip = styled.span<{ color: string }>`
  padding: 2px 6px;
  border-radius: 4px;
  height: 20px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: 13px;
  font-weight: 600;
  color: ${({ color }) => color};
  background-color: var(--background-base-widget-left);

  svg {
    path {
      fill: ${({ color }) => color};
    }

    rect {
      stroke: ${({ color }) => color};
    }
  }
`;

export const ChipsWrap = styled.span`
  display: flex;
  align-items: center;
  gap: 0px 4px;
`;

export const Title = styled.span`
  font-size: 14px;
  color: var(--text-secondary-1);
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;

export const Divider = styled.span`
  display: inline-block;
  width: 1px;
  height: 12px;
  background-color: var(--mono-10);
`;
