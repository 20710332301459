/* eslint-disable no-underscore-dangle */
import useOrganization from "@hooks/useOrganization";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { analyticsFilter } from "@queries/AnalyticsFilter";
import { useMemo } from "react";
import useInitialUserInfo from "@hooks/useInitialUserInfo";
import { useAnalyticsRuleData } from "@hooks/analytics/useAnalyticsRuleData";
import {
  AnalyticsFilterRequestDto,
  BaseGetAnalyticsFilterResponseDto,
  GetAnalyticsFilterResponseDto
} from "@open-api";
import { AI_TYPE, ANALYTICS_CHART_TYPE } from "@resources/Constants";
import { CalendarModeRangeType } from "@custom-types/Types";
import { getRuleType } from "@hooks/analytics/useRuleGroupByLocation";
import { useGetPortalDevicesQuery } from "../device/DeviceQuery";
import { keys } from "./keys";

type RuleType = {
  ruleType: string;
  deviceId: string;
  channel: string;
  ruleName: string;
};

export type Order = GetAnalyticsFilterResponseDto["filterList"][0]["order"][0];
export type Hide = GetAnalyticsFilterResponseDto["filterList"][0]["hide"][0];

export type FilterType = {
  baseTime:
    | {
        start: string;
        end: string;
        mode: CalendarModeRangeType;
      }
    | undefined;
  rules: string[] | undefined;
  filterName: string;
  _id?: string;
  order: Order[];
  hide: Hide[];
};

export const toChartType = (orderType: Order) => {
  return _.snakeCase(
    orderType
  ).toUpperCase() as keyof typeof ANALYTICS_CHART_TYPE;
};

export const toOrderType = (chartType: keyof typeof ANALYTICS_CHART_TYPE) => {
  return _.camelCase(chartType) as Order;
};

export const DEFAULT_ORDER = Object.keys(ANALYTICS_CHART_TYPE).map((v) =>
  toOrderType(v as keyof typeof ANALYTICS_CHART_TYPE)
);

export const getDefaultChartOrder = (rules: FilterType["rules"]) => {
  const ruleTypes = _.compact(
    (rules ?? []).map((v: string) => v.split(":")[0])
  ) as (keyof typeof AI_TYPE)[];

  return DEFAULT_ORDER.filter((orderType: Order) => {
    const ruleType = getRuleType(toChartType(orderType));
    if (ruleType) {
      return ruleTypes.includes(ruleType);
    }

    return false;
  });
};

export const useGetAnalyticsFilterQuery = () =>
  //   options?: UseQueryOptions<GetChartResponse>
  {
    const { orgId } = useOrganization();

    const {
      isLoading: isLoadingDevice,
      isInitialLoading: isInitialLoadingDevice
    } = useGetPortalDevicesQuery(
      {
        orgId,
        sightmindStatus: true
      },
      { enabled: !!orgId }
    );

    // prefetch for cache
    const { originData, isLoading: isLoadingRule } = useAnalyticsRuleData(
      { inValidateCacheOnUnmount: true },
      {
        refetchOnMount: true,
        cacheTime: 0,
        staleTime: 0
      }
    );

    const {
      data: filterData,
      isLoading: isLoadingFilter,
      isInitialLoading: isInitialLoadingFilter
    } = useQuery(
      keys.getFilter(orgId),
      () =>
        analyticsFilter.get({
          path: {
            orgId
          }
        }),
      {
        enabled: !!orgId,
        cacheTime: 0,
        staleTime: 0
      }
    );

    const isLoading = isLoadingDevice || isLoadingFilter || isLoadingRule;
    const isInitialLoading =
      isInitialLoadingDevice || isInitialLoadingFilter || isLoadingRule;
    // [TODO] remove later, the type defined in swagger is incorrect.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const data: FilterType[] = useMemo(() => {
      if (filterData && originData) {
        const portalRuleList = _.compact(
          _.flatMap(originData, (origin) => origin.children).map((v) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const metaData = v.metaData;
            if (metaData) {
              const { channel, deviceId, ruleName, type } = metaData;
              return { channel, deviceId, ruleName, type };
            }
            return null;
          })
        );

        const validFilterList = _.map(filterData.filterList, (filter) => {
          return {
            ...filter,
            rules: (filter.rules as unknown as RuleType[]).filter((rule) => {
              return (
                portalRuleList.findIndex((pr) => {
                  return (
                    pr.deviceId === rule.deviceId &&
                    pr.channel === rule.channel &&
                    pr.ruleName === rule.ruleName &&
                    pr.type === rule.ruleType
                  );
                }) > -1
              );
            })
          };
        });

        return _.map(validFilterList, (filter) => {
          return {
            _id: filter._id,
            filterName: filter.filterName,
            baseTime: {
              start: filter.startDateTime,
              end: filter.endDateTime,
              mode: "custom"
            },
            rules: filter.rules.map((rule) => {
              return [
                rule.ruleType,
                rule.deviceId,
                rule.channel,
                rule.ruleName
              ].join(":");
            }),
            order: (() => {
              // if all rule is invalid, return empty array
              // ex) if camera that have rule is unclaimed, return empty array
              if (filter.rules.length === 0) {
                return [];
              }

              if (_.isEmpty(filter.order)) {
                return DEFAULT_ORDER;
              }

              return filter.order;
            })(),
            hide: (() => {
              if (filter.rules.length === 0) {
                return [];
              }

              return filter.hide;
            })()
          };
        });
      }

      return [];
    }, [filterData, originData]);

    return {
      data,
      isLoading,
      isInitialLoading
    };
  };

export const useUpdateFilterNameNotSaveMutation = () => {
  const { orgId } = useOrganization();
  const queryClient = useQueryClient();

  const mutateAsync = async (params: {
    filterId: string;
    filterName: string;
  }) => {
    queryClient.setQueryData<GetAnalyticsFilterResponseDto>(
      keys.getFilter(orgId),
      (prev) => {
        if (!prev) {
          return prev;
        }

        const { filterId, filterName } = params;
        const newFilter = {
          ...prev,
          filterList: prev.filterList.map((filter) => {
            if (filter._id === filterId) {
              return { ...filter, filterName };
            }
            return filter;
          })
        };
        return newFilter;
      }
    );
  };

  return { mutateAsync };
};

export const useUpdateAnalyticsFilterMutation = () => {
  const { orgId } = useOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    (
      params: AnalyticsFilterRequestDto & {
        filterId: string;
      }
    ) => {
      const { filterId, ...body } = params;
      return analyticsFilter.put({
        path: { orgId, filterId },
        body
      });
    },
    {
      onSuccess: (_, params) => {
        queryClient.setQueryData<GetAnalyticsFilterResponseDto>(
          keys.getFilter(orgId),
          (prev) => {
            if (!prev) {
              return prev;
            }

            const { filterId, ...req } = params;
            const changedFilter = prev.filterList
              .filter((filter) => filter._id === filterId)
              .map(() => ({ ...req, _id: filterId }))[0];

            const newFilter = {
              ...prev,
              filterList: [
                changedFilter,
                ...prev.filterList.filter((filter) => filter._id !== filterId)
              ]
            };
            return newFilter;
          }
        );
      }
    }
  );
};

export const useCreateAnalyticsFilterMutation = () => {
  const { orgId } = useOrganization();
  const queryClient = useQueryClient();
  const { data: user } = useInitialUserInfo(orgId, {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    refetchOnMount: true
  });

  return useMutation(
    (req: AnalyticsFilterRequestDto) => {
      return analyticsFilter.post({
        path: {
          orgId
        },
        body: req
      });
    },
    {
      onSuccess: (resp: BaseGetAnalyticsFilterResponseDto) => {
        queryClient.setQueryData<GetAnalyticsFilterResponseDto>(
          keys.getFilter(orgId),
          (prev) => {
            if (!prev) {
              return {
                orgId,
                userId: user?.userId ?? "",
                filterList: [resp as BaseGetAnalyticsFilterResponseDto]
              };
            }
            const newFilter = {
              ...prev,
              filterList: [resp, ...prev.filterList]
            };

            return newFilter;
          }
        );
      }
    }
  );
};

export const useDeleteAnalyticsFilterMutation = () => {
  const { orgId } = useOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    (filterId: string) => {
      return analyticsFilter.delete({
        path: {
          orgId,
          filterId
        }
      });
    },
    {
      onSuccess: (_, params) => {
        queryClient.setQueryData<GetAnalyticsFilterResponseDto>(
          keys.getFilter(orgId),
          (prev) => {
            if (!prev) {
              return prev;
            }

            const filterId = params;
            const newFilter = {
              ...prev,
              filterList: prev.filterList.filter(
                (filter) => filter._id !== filterId
              )
            };
            return newFilter;
          }
        );
      }
    }
  );
};
