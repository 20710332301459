import React, { InputHTMLAttributes, useEffect, useId, useRef } from "react";
import { IconCheckTick, IconIndeterminate } from "@resources/icons/Icons";
import * as S from "./Checkbox.styled";

export enum CHECKBOX_STATUS {
  UNCHECK,
  CHECK,
  HALF_CHECK
}

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  label?: string;
  indeterminate?: boolean;
  css?: React.CSSProperties;
}

export const Checkbox = ({
  icon,
  label = "",
  indeterminate,
  onChange,
  ...props
}: CheckboxProps) => {
  const uniq = useId();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate ?? false;
    }
  }, [indeterminate]);

  return (
    <S.CheckboxStyled aria-labelledby={`checkbox_${uniq}`} style={props.css}>
      <input
        id={`checkbox_${uniq}`}
        type="checkbox"
        ref={inputRef}
        {...props}
        aria-label={label || props["aria-label"]}
        onChange={onChange}
      />
      <S.StyledCheckboxIconWrapper className="checkbox">
        {indeterminate === true ? (
          <IconIndeterminate width={50} height={50} />
        ) : (
          <IconCheckTick width={50} height={50} />
        )}
      </S.StyledCheckboxIconWrapper>
      {(label || icon) && (
        <S.StyledCheckboxLabel className="label">
          <div className="icon-checkbox">{icon}</div>
          <div>{label}</div>
        </S.StyledCheckboxLabel>
      )}
    </S.CheckboxStyled>
  );
};
