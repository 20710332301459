import React, { useContext } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import _ from "lodash";
import { IconCheckCircle, IconDelete } from "@resources/icons/Icons";

import { ToastContext } from "./ToastContext";
import Toast from ".";

const Container = styled.div`
  position: fixed;
  right: 20px;
  top: 68px;
  z-index: 110;
`;

const ContentWrap = styled.div`
  background-color: var(--report-toastify-message-background);
  width: 400px;
  height: 60px;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  color: var(--sementic-text-primary);
  font-size: 16px;
  gap: 8px;

  svg.green {
    path {
      fill: var(--system-emerald);
    }
  }

  svg.red {
    path {
      fill: var(--system-red);
    }
  }
`;

const DURATION = 3000;
export const ToastPortal = () => {
  const { setToastInfo, toastInfo } = useContext(ToastContext);

  if (_.isEmpty(toastInfo)) {
    return null;
  }

  return ReactDOM.createPortal(
    <Container key={`${toastInfo.message}_${toastInfo.type}`}>
      <Toast
        duration={DURATION}
        onClose={() => {
          setToastInfo(undefined);
        }}
      >
        <ContentWrap>
          {(() => {
            switch (toastInfo.type) {
              case "success":
                return (
                  <IconCheckCircle className="green" width={20} height={20} />
                );
              case "error":
                return <IconDelete className="red" width={20} height={20} />;
              default:
                return null;
            }
          })()}
          <span>{toastInfo.message}</span>
        </ContentWrap>
      </Toast>
    </Container>,
    document.getElementById("portal-layer") as Element
  );
};
