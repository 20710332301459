import styled from "styled-components";

export const TreeViewCollapseBtnStyled = styled.div`
  height: 30px;
  display: flex;
  align-items: center;

  &:has(.tree-view-icon) {
    display: none;
  }

  .tree-view-icon {
    width: 14px;
    height: 14px;
    display: block;
  }

  .collapse-btn {
    display: flex;
    svg {
      transition: transform 0.2s;
      path {
        fill: var(--icon-default);
      }
    }

    &.expaneded svg {
      transform: rotate(90deg);
      transition: transform 0.2s;
    }
  }
`;
