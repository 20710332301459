import useOrganization from "@hooks/useOrganization";
import { useMemo } from "react";
import {
  useGetWavesyncStatusQuery,
  useGetWavesyncDeviceQuery
} from "@queries/tanstack/wavesync/WaveQuery";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { WaveSyncDevice, WaveSyncStatus } from "@queries/Wave";

export default function useWaveSyncData(
  queryOptions?: Parameters<typeof useQuery>[2]
) {
  const { orgId } = useOrganization();
  const {
    data: linkedStatusData,
    refetch: refetchLinkedStatus,
    isLoading: isLoadingWaveSyncLinkedStatus
  } = useGetWavesyncStatusQuery(orgId, {
    enabled: !!orgId,
    ...(queryOptions as UseQueryOptions<WaveSyncStatus>)
  });

  const {
    data: devicesData,
    refetch: refetchDevices,
    isInitialLoading: isLoadingWaveSyncDevices
  } = useGetWavesyncDeviceQuery(orgId, {
    enabled: !!orgId && !!linkedStatusData?.linked,
    ...(queryOptions as UseQueryOptions<WaveSyncDevice[]>)
  });

  const refetchWaveSyncData = async () => {
    return refetchLinkedStatus().then((res) => {
      if (res.data?.linked) {
        return refetchDevices().then(() => Promise.resolve());
      }

      return Promise.resolve();
    });
  };

  const waveSyncDevices = useMemo(() => {
    if (linkedStatusData?.linked && devicesData) {
      return devicesData;
    }

    return [];
  }, [devicesData, linkedStatusData?.linked]);

  return {
    // wave sync "devices"
    waveSyncDevices,
    isLoadingWaveSyncDevices:
      isLoadingWaveSyncLinkedStatus || isLoadingWaveSyncDevices,
    refetchWaveSyncData,

    // linked wave
    linkedStatusData,
    isLoadingWaveSyncLinkedStatus
  };
}
