import {
  AIType,
  DeviceRuleMode,
  ExcludeArea,
  TreeMaxSelected
} from "@custom-types/Types";
import { ReactNode } from "react";
import { WidgetStyleType } from "@components/widgets/base/widget";
import { Point, Size } from "@components/rule-canvas/types";
import { CHECKBOX_STATUS } from "../checkbox";

export type DataTreeViewItemValue = string | number;
export interface NodeModelBase {
  props: TreeViewProps;
  flatNodes: FlatNodes;

  nodeHasChildren: (node: DataTreeViewItem) => boolean;
  getDisabledState: (
    node: DataTreeViewItem,
    parent: DataTreeViewItem | null,
    disabledProp: boolean
  ) => boolean;
  setProps: (props: TreeViewProps) => void;
  addNode: (
    data: DataTreeViewItem,
    parent: DataTreeViewItemValue | null
  ) => void;
  addNodes: (
    data: DataTreeViewItem[],
    parent: DataTreeViewItemValue | null
  ) => void;
  setNodes: (
    data: DataTreeViewItem[],
    parent: DataTreeViewItemValue | null
  ) => void;
  findNode: (
    nodes: DataTreeViewItem[],
    value: DataTreeViewItemValue
  ) => DataTreeViewItem | null;
  clone: () => NodeModelBase;
  getNode: (value: string | number) => FlatNodeItem;
  reset: () => void;
  serializeList: (key: "checked" | "expanded") => FlatNodeItem[];
  flattenNodes: (
    nodes: DataTreeViewItem[] | undefined,
    parent: DataTreeViewItem | null,
    depth: number
  ) => void;
  expandAllNodes: (expand: boolean) => void;
  toggleChecked: (
    node: DataTreeViewItem | FlatNodeItem,
    isChecked: boolean,
    checkModel: number,
    percolateUpward: boolean
  ) => void;
  toggleParentStatus: (node: DataTreeViewItem, checkModel: number) => void;
  isEveryChildChecked: (node: DataTreeViewItem) => boolean;
  toggleNode: (
    nodeValue: DataTreeViewItemValue,
    key: "checked" | "expanded",
    toggleValue: boolean
  ) => void;
}

type DataTreeViewItemBase = {
  label: ReactNode;
  value: DataTreeViewItemValue;
};

type MetaData = {
  [key: string]:
    | string
    | number
    | boolean
    | Date
    | MetaData
    | string[]
    | number[]
    | boolean[]
    | Date[]
    | MetaData[]
    | undefined
    | ReactNode;
};

export type ThumbnailInfo = {
  width: number;
  height: number;
  path: string;
};

export type RuleCanvasInfo = {
  model: string;
  resolutionInfo?: Size;
  aiType?: AIType;
  points?: Point[];
  excludeAreas?: ExcludeArea[];
  directionType?: DeviceRuleMode;
  tilt?: number;
  roll?: number;
  cameraHeight?: number;
  figureIndex?: number;
  focalLength?: number;
};

export type LicenseStatus = "Expired" | "No License" | "Normal";

export type DataTreeViewItem = DataTreeViewItemBase & {
  disabled?: boolean;
  icon?: ReactNode;
  status?: "on" | "off";
  showCheckbox?: boolean;
  title?: ReactNode;
  onClicked?: () => void;
  onExpanded?: () => void;
  onChecked?: () => void;
  children?: DataTreeViewItem[];
  metaData?: MetaData;
  thumbnail?: ThumbnailInfo;
  sketchbook?: RuleCanvasInfo;
  hideArrow?: boolean;
  permissions?: boolean;
  licenseStatus?: LicenseStatus;
};

export type TreeViewIconProps = {
  check: ReactNode;
  uncheck: ReactNode;
  halfCheck: ReactNode;
  collapse: ReactNode;
  expand: ReactNode;
};

export type TreeViewProps = {
  nodes: DataTreeViewItem[];
  lazyLoad?: boolean;
  loadDataItemFn?: (
    value: DataTreeViewItemValue
  ) => Promise<DataTreeViewItem[]>;
  checked: DataTreeViewItemValue[];
  disabled: boolean;
  expanded: DataTreeViewItemValue[];
  icons?: TreeViewIconProps;
  id?: string;
  name: string | undefined;
  showIcon?: boolean;
  showCheckbox?: boolean;
  rootStyle?: boolean;
  maxSelected?: TreeMaxSelected;
  onChecked?: (key: FlatNodeItem[]) => void;
  onClicked?: (
    node: FlatNodeItem,
    info: { value: DataTreeViewItemValue }
  ) => void;
  onExpanded?: (key: FlatNodeItem[]) => void;
  onCollapsed?: () => void;
  onRenderItem?: (item: FlatNodeItem) => ReactNode;
  onRenderItemHover?: (item: TreeViewItemProps) => ReactNode;
  onRenderItemSelected?: (label: ReactNode) => ReactNode;
  widgetStyleType?: WidgetStyleType;
  hoverItem?: string;
  onHover?: (id?: string) => void;
  useHoverTooltip?: boolean;
  isFirstAlwaysExpand?: boolean;
};

export type TreeViewItemProps = {
  checked: CHECKBOX_STATUS;
  selected: DataTreeViewItemValue | null;
  disabled: boolean;
  expanded: boolean;
  isLeaf: boolean;
  status?: "on" | "off";
  isRoot: boolean;
  isParent: boolean;
  label: ReactNode;
  id: string;
  data: DataTreeViewItem;
  children: ReactNode;
  icon: ReactNode;
  showCheckbox?: boolean;
  showIcon?: boolean;
  model: NodeModelBase;
  lazyLoad?: boolean;
  loadDataItemFn?: (
    value: DataTreeViewItemValue
  ) => Promise<DataTreeViewItem[]>;
  onChecked: (data: { value: DataTreeViewItemValue; checked: boolean }) => void;
  onExpanded: (data: {
    value: DataTreeViewItemValue;
    expanded: boolean;
  }) => void;
  onClicked: (data: { value: DataTreeViewItemValue }) => void;
  onRenderItem?: (item: FlatNodeItem) => ReactNode;
  onRenderItemHover?: (item: TreeViewItemProps) => ReactNode;
  onRenderItemSelected?: (label: ReactNode) => ReactNode;
  useHoverTooltip?: boolean;
  hoverItem?: string;
  onHover?: (id?: string) => void;
};

export type FlatNodeItem = DataTreeViewItem & {
  isChild: boolean;
  isLeaf: boolean;
  isParent: boolean;
  index: number;
  parent: DataTreeViewItem | null;
  checked: boolean;
  expanded: boolean;
  checkState?: CHECKBOX_STATUS;
  metaData?: MetaData;
  thumbnail?: ThumbnailInfo;
  sketchbook?: RuleCanvasInfo;
  permissions?: boolean;
};

export type FlatNodes = {
  [key: string]: FlatNodeItem;
};

export enum CHECK_MODEL {
  ALL,
  PARENT,
  LEAF
}
