interface QueryKeys {
  feature: readonly string[];
  getData: (data?: string) => readonly [string, string, string | undefined];
  getAll: (id?: string) => readonly [string, string, string | undefined];
  getOne: (id: string) => readonly string[];
}

export const queryKeys = (feature?: string): QueryKeys => {
  const keys = {
    feature: [feature === undefined ? "undefined" : feature] as const,
    getData: (data?: string) => [...keys.feature, "getData", data] as const,
    getAll: (id?: string) => [...keys.feature, "getAll", id] as const,
    getOne: (id: string) => [...keys.feature, "getOne", id] as const
  };
  return keys;
};
