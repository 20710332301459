import { Post } from "@configs/Axios";

export interface AnalyticsFilterRule {
  deviceId: string;
  ruleName: string;
  channel: number;
}

export interface AnalyticsFilterRules {
  [key: string]: AnalyticsFilterRule[];
}

export interface AnalyticsFilterApiInputPayload {
  orgId: string;
  startDateTime: string;
  endDateTime: string;
  timeInterval: string;
  rules: AnalyticsFilterRules;
  vehicleType?: string[];
  cumulativeType: string;
}

export type AnalyticsDetailsResponse =
  | AnalyticsDataResponse
  | AnalyticPeopleFlowResponse
  | AnalyticQueuesResponse
  | AnalyticStoppedVehicleResponse;

export type AnalyticsChartDataResponse = AnalyticsDetailsResponse[];
export interface AnalyticsDataResponse {
  locationName: string;
  total?: number;
  chartData: number[];
  color: string;
}

export interface AnalyticPeopleFlowResponse {
  locationName: string;
  in: {
    chartData: number[];
  };
  out: {
    chartData: number[];
  };
}

export interface QueueCountResponse {
  count: number;
  time: number;
}
export interface AnalyticQueuesResponse {
  locationName: string;
  chartData: QueueCountResponse[];
}

export interface StoppedVehicleChartData {
  bicycle: number[];
  bus: number[];
  car: number[];
  motorcycle: number[];
  truck: number[];
}

export interface AnalyticStoppedVehicleResponse {
  locationName: string;
  chartData: StoppedVehicleChartData;
}

export const analyticsApi = {
  chartAnalytics: async (
    widgetType: string,
    {
      orgId,
      startDateTime,
      endDateTime,
      rules,
      timeInterval,
      vehicleType,
      cumulativeType
    }: AnalyticsFilterApiInputPayload
  ) => {
    const type = widgetType.includes("cumulative")
      ? `&cumulativeType=${cumulativeType.toUpperCase()}`
      : "";
    const apiUrl = `/v3/organizations/${orgId}/${widgetType}?startDateTime=${encodeURIComponent(
      startDateTime
    )}&endDateTime=${encodeURIComponent(
      endDateTime
    )}&timeInterval=${timeInterval}${
      vehicleType && vehicleType.length > 0
        ? vehicleType
            .map(
              (type) =>
                `&vehicleType=${encodeURIComponent(type.toLocaleUpperCase())}`
            )
            .join("")
        : ""
    }${type}`;

    return Post<AnalyticsChartDataResponse>(apiUrl, { rules });
  }
};
