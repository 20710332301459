import { useCallback, useContext } from "react";
import { ToastContext, ToastInfo } from "@components/common/toast/ToastContext";

export const useToast = () => {
  const toastContext = useContext(ToastContext);

  const open = useCallback(
    ({ message, type }: ToastInfo) => {
      toastContext.setToastInfo({
        message,
        type
      });
    },
    [toastContext]
  );

  const close = useCallback(() => {
    toastContext.setToastInfo(undefined);
  }, [toastContext]);

  return { open, close };
};
