import { StandardScrollBarStyle } from "@themes/GlobalStyles";
import styled from "styled-components";

export const HeaderStyled = styled.div`
  font-size: 16px;
  /* dmpro */
  /* color: $ {({ theme }) => theme.color.mono1}; */
  /* sightmind */
  color: var(--cal-text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon-arrow {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 16px;
    height: 16px;

    svg {
      path {
        /* dmpro */
        /* stroke: $ {({ theme }) => theme.color.mono7}; */
        /* sightmind */
        stroke: var(--icon-default);
        stroke-opacity: 1;
      }
    }

    &:hover {
      /* dmpro */
      /* background-color: rgba(255, 255, 255, 0.4); */
      /* sightmind */
      background-color: var(--mono-0-4-per);

      path {
        /* dmpro */
        /* stroke: $ {({ theme }) => theme.color.mono1}; */
        /* sightmind */
        stroke: var(--icon-emphasis);
        stroke-opacity: 1;
      }
    }

    &:active {
      /* dmpro */
      /* background-color: rgba(255, 255, 255, 0.8); */
      /* sightmind */
      background-color: var(--mono-0-8-per);

      path {
        /* dmpro */
        /* stroke: $ {({ theme }) => theme.color.mono1}; */
        /* sightmind */
        stroke: var(--icon-emphasis);
        stroke-opacity: 1;
      }
    }
  }

  .control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 16px 16px 8px 12px;

    .month-year-menu {
      display: flex;
      align-items: center;
      height: 32px;
      .context-menu {
        height: auto;
      }
    }

    ul {
      right: unset !important;
      min-width: 80px !important;
      padding: 0 !important;
      margin-top: 4px !important;
      /* dmpro */
      /* background-color: $ {({ theme }) => theme.color.mono0};
        border: 1px solid $ {({ theme }) => theme.color.mono9};
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); */
      /* sightmind */
      background-color: var(--background-ibb-default);
      border: 1px solid var(--border-default);
      box-shadow: var(--shadow-small);
      border-radius: 4px;
      max-height: 240px;
      overflow-y: auto;
      ${StandardScrollBarStyle}

      > li {
        button {
          width: 100%;
          &:hover,
          &:focus,
          &:active {
            /* dmpro */
            /* color: $ {({ theme }) => theme.color.mono1}; 
          background-color: $ {({ theme }) => theme.color.mono10}; */
            /* sightmind */
            color: var(--text-primary);
            background-color: var(--background-date-range-dropdown-hover);
          }
        }
      }
    }

    .month-year {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 0px 8px 0px 12px;
      width: 80px;
      border-radius: 4px;
      height: 32px;

      &:hover {
        /* dmpro */
        /* background-color: rgba(255, 255, 255, 0.4); */
        /* sightmind */
        background-color: var(--mono-0-4-per);
        .icon-dropdown {
          background-color: transparent;
        }
      }

      &:active {
        /* dmpro */
        /* background-color: rgba(255, 255, 255, 0.8); */
        /* sightmind */
        background-color: var(--mono-0-8-per);
        .icon-dropdown {
          background-color: transparent;

          svg path {
            /* dmpro */
            /* fill: $ {({ theme }) => theme.color.mono1}; */
            /* sightmind */
            fill: var(--icon-emphasis);
          }
        }
      }

      &:has(+ ul[data-visibility="visible"]) .icon-dropdown svg {
        transform: rotate(180deg);

        path {
          /* dmpro */
          /* fill: $ {({ theme }) => theme.color.mono1}; */
          /* sightmind */
          fill: var(--icon-emphasis);
        }
      }

      .icon-dropdown {
        cursor: pointer;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 6px;
        border-radius: 2px;
        /* dmpro */
        /* background-color: $ {({ theme }) => theme.color.mono0}; */
        /* sightmind */
        background-color: var(--surface-popup);

        svg {
          width: 20px;
          transform: rotate(0);
          transition: transform 0.3s ease-in-out;

          path {
            /* dmpro */
            /* fill: $ {({ theme }) => theme.color.mono7}; */
            /* sightmind */
            fill: var(--icon-default);
            stroke: transparent;
          }
        }
      }
    }

    .month-control {
      display: flex;

      > span:last-child {
        margin-left: 20px;
      }
    }
  }
`;

export const CalendarStyled = styled.div`
  /* stylelint-disable selector-class-pattern */
  > div {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .react-datepicker {
    min-width: 255px;
    min-height: 285px;
    /* dmpro */
    /* background-color: $ {({ theme }) => theme.color.mono0}; */
    /* sightmind */
    background-color: var(--background-date-default);
    font-family: Pretendard, sans-serif;
    font-size: 12px;
    display: flex;
    border-radius: 8px;

    &__triangle {
      display: none;
    }

    &__header {
      padding: 0;
      border: 0;
      background-color: inherit;
    }

    &__month {
      margin: 0;
      min-height: 208px;
    }

    &__day-names {
      margin: 20px 0 12px;
    }

    &__day-name {
      font-size: 12px;
      line-height: 14px;
      margin: 0;
      /* dmpro */
      /* color: $ {({ theme }) => theme.color.mono4}; */
      /* sightmind */
      color: var(--text-secondary-1);
      width: 44px;

      &:first-child {
        /* dmpro */
        /* color: $ {({ theme }) => theme.color.systemRed}; */
        /* sightmind */
        color: var(--system-red);
      }

      .date-picker-custom-day-name {
        width: 28px;
      }
    }

    &__week {
      display: flex;
      justify-content: center;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    &__day {
      /* dmpro */
      /* color: $ {({ theme }) => theme.color.mono1}; */
      /* sightmind */
      color: var(--text-primary);
      background-color: transparent;
      width: 44px;
      margin: 0;
      font-size: 14px;
      &:last-of-type {
        width: 28px;
      }

      .date-picker-custom-date {
        position: relative;
        width: 28px;
        height: 28px;
        border-radius: 6px;
        > span {
          &.shadow-date {
            display: none;
          }
        }
      }

      &:hover {
        background-color: transparent;
        &.react-datepicker__day--in-range {
          border-radius: 0;
          &.react-datepicker__day--range-end,
          &.react-datepicker__day--weekend[aria-label*="Saturday"] {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
        .date-picker-custom-date {
          /* dmpro */
          /* color: $ {({ theme }) => theme.color.mono1};
          background-color: $ {({ theme }) => theme.color.mono10}; */
          /* sightmind */
          color: var(--text-primary);
          background-color: var(--background-date-day-hover) !important;
        }
      }

      &:active {
        background-color: transparent;
        .date-picker-custom-date {
          /* dmpro */
          /* color: $ {({ theme }) => theme.color.monoWhite};
          background-color: $ {({ theme }) => theme.color.mono9}; */
          /* sightmind */
          color: var(--white) !important;
          background-color: var(--background-date-day-active) !important;
        }
      }

      &.react-datepicker__day--today {
        background-color: transparent !important;
        .date-picker-custom-date {
          background-color: transparent !important;
          /* dmpro */
          /* border: 1px solid $ {({ theme }) => theme.color.mono0}; */
          /* sightmind */
          border: 1px solid var(--mono-0);
          border-radius: 8px;
          font-weight: 400;
        }
      }

      &.react-datepicker__day--today {
        .date-picker-custom-date {
          /* dmpro */
          /* outline: 1px solid $ {({ theme }) => theme.color.mono0};
           border: 1px solid $ {({ theme }) => theme.color.mono9}; */
          /* sightmind */
          outline: 1px solid var(--mono-0);
          border: 1px solid var(--background-date-default);
          border-radius: 6px;
          :hover {
            /* dmpro */
            /* background-color: rgba(255, 255, 255, 0.4) */
            /* sightmind */
            background-color: var(--mono-0-4-per) !important;
          }
        }
      }

      &.react-datepicker__day--in-range.react-datepicker__day--range-end {
        .date-picker-custom-date:hover {
          /* dmpro */
          /* background-color: $ {({ theme }) => theme.color.orange070};
          color: $ {({ theme }) => theme.color.monoWhite}; */
          /* sightmind */
          background-color: var(--orange-primary) !important;
          opacity: 0.77;
          color: var(--white);
        }
      }

      &.react-datepicker__day--today.react-datepicker__day--selected {
        .date-picker-custom-date:hover {
          /* dmpro */
          /* background-color: $ {({ theme }) => theme.color.orange070}; */
          /* sightmind */
          background-color: var(--orange-primary) !important;
          opacity: 0.77;
        }
      }

      .date-picker-custom-today {
        font-weight: normal;
        &.date-picker-custom-date {
          display: flex;
          justify-content: center;
          align-items: center;
          /* dmpro */
          /* border: 1px solid $ {({ theme }) => theme.color.orange040}; */
          /* sightmind */
          border: 1px solid var(--border-date-range-today);
          background-clip: content-box;
          padding: 1px;
        }
      }

      &--weekend[aria-label*="Sunday"] {
        /* dmpro */
        /* color: $ {({ theme }) => theme.color.systemRed}; */
        /* sightmind */
        color: var(--system-red);
      }

      &--outside-month {
        /* dmpro */
        /* color: $ {({ theme }) => theme.color.mono8}; */
        /* sightmind */
        color: var(--text-disabled);
        &.react-datepicker__day--weekend[aria-label*="Sunday"] {
          /* dmpro */
          /* color: $ {({ theme }) => theme.color.mono8}; */
          /* sightmind */
          color: var(--text-disabled) !important;
        }
      }

      &--in-range,
      &--in-selecting-range {
        border-radius: 0;
        /* dmpro */
        /* color: $ {({ theme }) => theme.color.mono1};
        background-color: rgba(255, 255, 255, 0.4) */
        /* sightmind */
        color: var(--text-primary);
        background-color: var(--mono-0-4-per) !important;
        &:has(.date-picker-start-of-month) {
          border-top-left-radius: 6px !important;
          border-bottom-left-radius: 6px !important;
        }
        &:has(.date-picker-end-of-month) {
          width: 28px;
          margin-right: 16px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        &.react-datepicker__day--range-end,
        &.react-datepicker__day--selecting-range-end {
          /* dmpro */
          /* background-color: rgba(255, 255, 255, 0.4) */
          /* sightmind */
          background-color: var(--mono-0-4-per) !important;
          border-top-right-radius: 6px !important;
          border-bottom-right-radius: 6px !important;
          .date-picker-custom-date {
            /* dmpro */
            /* background-color: $ {({ theme }) => theme.color.orange040};
            color: $ {({ theme }) => theme.color.monoWhite}; */
            /* sightmind */
            background-color: var(--orange-primary) !important;
            color: var(--white);
          }
        }
        &.react-datepicker__day--range-end,
        &.react-datepicker__day--selecting-range-end,
        &.react-datepicker__day--weekend[aria-label*="Saturday"] {
          width: 28px;
          margin-right: 16px;
          border-radius: 0 6px 6px 0;
          &.react-datepicker__day--weekend[aria-label*="Sunday"] {
            .date-picker-custom-date {
              border-radius: 6px;
            }
          }
          &:last-of-type {
            margin-right: 0px;
          }
          .date-picker-custom-today {
            /* dmpro */
            /* background-color: $ {({ theme }) => theme.color.orange070}; */
            /* sightmind */
            background-color: var(--orange-secondary);
            width: 28px;
            margin-right: 8px;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            &.date-picker-custom-date {
              border-radius: 6px;
            }
          }
        }
        &.react-datepicker__day--range-start,
        &.react-datepicker__day--selecting-range-start,
        &.react-datepicker__day--weekend[aria-label*="Sunday"] {
          border-top-left-radius: 6px !important;
          border-bottom-left-radius: 6px !important;
        }
      }

      &--selected {
        /* dmpro */
        /* color: $ {({ theme }) => theme.color.monoWhite}; */
        /* sightmind */
        color: var(--white) !important;
        &.react-datepicker__day--range-start.react-datepicker__day--range-end,
        &.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
          background-color: transparent;
          .date-picker-custom-date {
            border-radius: 6px;
          }
        }
        .date-picker-custom-date {
          display: flex;
          justify-content: center;
          align-items: center;
          /* dmpro */
          /* background-color: $ {({ theme }) => theme.color.orange040}; */
          /* sightmind */
          background-color: var(--orange-primary) !important;
          :hover {
            /* dmpro */
            /* background-color: $ {({ theme }) => theme.color.orange070};
            color: $ {({ theme }) => theme.color.monoWhite}; */
            /* sightmind */
            background-color: var(--orange-primary) !important;
            opacity: 0.77;
            color: var(--white);
          }
        }
      }

      &--disabled {
        cursor: not-allowed;
        /* dmpro */
        /* color: $ {({ theme }) => theme.color.mono8}; */
        /* sightmind */
        color: var(--text-disabled) !important;

        &:hover .date-picker-custom-date {
          /* dmpro */
          /* color: $ {({ theme }) => theme.color.mono8}; */
          /* sightmind */
          color: var(--text-disabled) !important;
          background-color: transparent !important;
        }
      }
    }
  }
  /* stylelint-enable */
`;
