import React, { useEffect, useRef, useState } from "react";
import { useThumbnail } from "@hooks/useThumbnail";
import Thumbnail from "@components/common/thumbnail";
import { useLang } from "@hooks/useLang";
import { Checkbox, CHECKBOX_STATUS } from "../checkbox";
import { DataTreeViewItem, TreeViewItemProps } from "./Interfaces";
import TreeViewCollapseBtn from "./TreeViewCollapse";
import * as S from "./TreeView.styled";

type LabelChildren = JSX.Element | React.ReactNode | null;

const TreeViewItem = (props: TreeViewItemProps) => {
  const {
    isLeaf,
    disabled,
    expanded,
    children,
    model,
    icon,
    label,
    showCheckbox,
    showIcon,
    id,
    checked,
    selected,
    data,
    isRoot,
    lazyLoad,
    loadDataItemFn,
    onRenderItem,
    onRenderItemHover,
    onRenderItemSelected,
    hoverItem,
    onHover,
    isParent
  } = props;
  const { value } = data;
  const { translate } = useLang();
  const nodeRef = useRef<HTMLSpanElement>(null);
  const [hover, setHover] = useState<boolean>(false);
  const isShowTooltip = !!props.useHoverTooltip && !children && hover;
  const {
    data: sdcsImage,
    isLoading: isLoadingImage,
    isError
  } = useThumbnail({
    deviceId: (data.metaData?.deviceId as string) || "",
    channel: (data.metaData?.channel as number) || 0,
    disabled: !isShowTooltip
  });

  useEffect(() => {
    if (hoverItem === value) {
      setHover(true);
    } else {
      setHover(false);
    }
  }, [hoverItem]);

  const getCheckState = ({ toggle }: { toggle: boolean }) => {
    if (checked === CHECKBOX_STATUS.UNCHECK && toggle) {
      return true;
    }

    if (checked === CHECKBOX_STATUS.CHECK && !toggle) {
      return true;
    }

    return false;
  };

  const onExpanded = async () => {
    const { onExpanded } = props;
    if (!expanded && lazyLoad && loadDataItemFn) {
      const data = await loadDataItemFn(value);
      if (data.length) {
        model.setNodes(data as DataTreeViewItem[], value);
      }
    }

    onExpanded && onExpanded({ value, expanded: !expanded });
  };

  const onClicked = () => {
    const { onClicked } = props;
    onClicked &&
      onClicked({
        value
      });
    !isLeaf && onExpanded();
  };

  const onChecked = () => {
    const { onChecked } = props;
    onChecked &&
      onChecked({
        value,
        checked: getCheckState({ toggle: true })
      });
  };

  const renderLabelChildren = (children: LabelChildren | LabelChildren[]) => {
    const clickable = onClicked !== null;

    return (
      <span className="tree-view-node-label">
        {clickable ? (
          <span
            tabIndex={0}
            role="button"
            onClick={() => onClicked()}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "Space") {
                onClicked();
              }
            }}
          >
            {children}
          </span>
        ) : (
          children
        )}
      </span>
    );
  };

  const renderLabelChildrenClick = (
    children: LabelChildren | LabelChildren[]
  ) => {
    return (
      <span
        key={1}
        className="tree-view-node-title"
        title={typeof children === "string" ? children : ""}
        data-testid={isParent ? "camera-title" : "rule-title"}
      >
        {children}
      </span>
    );
  };

  const renderCheckboxLabelChildren = (
    children: LabelChildren | LabelChildren[]
  ) => {
    const clickable = onClicked !== null;
    const nodes = !isRoot
      ? [
          <Checkbox
            key={0}
            id={id}
            data-testid={`checkbox-${id}`}
            value={value}
            disabled={disabled}
            onChange={() => onChecked()}
            indeterminate={checked === CHECKBOX_STATUS.HALF_CHECK}
            checked={checked === CHECKBOX_STATUS.CHECK}
            css={{ minWidth: "24px", height: "24px", justifyContent: "center" }}
          />
        ]
      : [];

    if (clickable) {
      nodes.push(
        <span
          key={2}
          className="tree-view-node-clickable"
          role="button"
          tabIndex={0}
          onClick={() => onClicked()}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "Space") {
              onClicked();
            }
          }}
        >
          {children}
        </span>
      );
    }

    return nodes;
  };

  const renderLabel = () => {
    const labelChildren: LabelChildren[] = [
      showIcon && icon ? (
        <span
          key={0}
          className="tree-view-node-icon"
          data-testid={isParent ? "camera-icon" : "rule-icon"}
        >
          {icon}
        </span>
      ) : null
    ];

    if (hover && onRenderItemHover) {
      labelChildren.push(renderLabelChildrenClick([onRenderItemHover(props)]));
    } else if (selected === value && onRenderItemSelected) {
      const node = model.getNode(value);
      labelChildren.push(
        renderLabelChildrenClick([onRenderItemSelected(node.label)])
      );
    } else if (onRenderItem) {
      labelChildren.push(
        renderLabelChildrenClick([onRenderItem(model.getNode(value))])
      );
    } else {
      labelChildren.push(renderLabelChildrenClick([label || null]));
    }

    if (!showCheckbox) {
      return renderLabelChildren(labelChildren);
    }

    return renderCheckboxLabelChildren(labelChildren);
  };

  const classNames = (classes: { [key: string]: boolean }) =>
    Object.keys(classes)
      .filter((o: string) => classes[o])
      .join(" ");

  const nodeClass = classNames({
    "tree-view-root": isRoot,
    "tree-view-node": true,
    "tree-view-node-leaf": isLeaf,
    "tree-view-node-parent": !isLeaf,
    "tree-view-node-expanded": !isLeaf && expanded,
    "tree-view-node-collapsed": !isLeaf && !expanded,
    "tree-view-node-disabled": disabled,
    "tree-view-node-selected": selected === value
  });
  return (
    <li className={nodeClass} key={value}>
      <span
        className={`tree-view-node-text ${!isLeaf && "tooltip"} ${
          hover ? "hover" : ""
        }`}
        data-tooltip={data.metaData?.name}
        onMouseEnter={() => {
          setHover(true);
          onHover?.(data.value as string);
        }}
        onMouseLeave={() => {
          setHover(false);
          onHover?.(undefined);
        }}
        key={value}
        ref={nodeRef}
      >
        <TreeViewCollapseBtn
          disabled={disabled}
          isLeaf={isLeaf}
          expanded={expanded}
          onExpanded={onExpanded}
        />
        {renderLabel()}

        {isShowTooltip && (
          <S.TreeItemTooltip
            data-testid="map-tree-item-tooltip"
            top={nodeRef.current?.getBoundingClientRect().top ?? 0}
          >
            <S.VideoContainer>
              <Thumbnail
                isLoading={isLoadingImage}
                isError={isError}
                thumbnail={sdcsImage}
                data-testid="map-tree-item-thumbnail"
              />
            </S.VideoContainer>
            <S.CameraInfoContainer data-testid="map-tree-item-container">
              <S.CameraName data-testid="map-tree-item-camera-name">
                {data.metaData?.name as string}
              </S.CameraName>
              <S.RuleName data-testid="map-tree-item-rulename">
                {label}
              </S.RuleName>
              <S.RuleInfoWrap>
                {(data.metaData?.icon as React.ReactNode) || icon}
                <S.RuleType>
                  {data.metaData?.type
                    ? translate(
                        `ai_rules.${String(
                          data.metaData?.type
                        ).toLocaleLowerCase()}`
                      )
                    : "-"}
                </S.RuleType>
              </S.RuleInfoWrap>
            </S.CameraInfoContainer>
          </S.TreeItemTooltip>
        )}
      </span>
      {children}
    </li>
  );
};

export default TreeViewItem;
