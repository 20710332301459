import { client } from "@open-api";
import { getPortalURL, getURL } from "@utils/Common";
import axios, {
  AxiosRequestHeaders,
  AxiosResponseHeaders,
  RawAxiosResponseHeaders
} from "axios";
import { HttpStatus } from "@configs/HttpStatus";
import { REDIRECT_PARAM } from "@resources/Constants";

let isRefreshing = false;
let refreshSubscribers: (() => void)[] = [];

const isJSONResponse = (
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
) => {
  return (
    headers["content-type"] &&
    headers["content-type"].includes("application/json")
  );
};

client.setConfig({
  baseURL: `https://api.${getURL()}`,
  withCredentials: true
});
const instance = client.instance;

instance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    newConfig.headers = {
      ...newConfig.headers
    } as AxiosRequestHeaders;
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const isForbiddenUser = (errorResponseData: {
  message: string;
  error: string;
  statusCode: number;
}) => {
  return errorResponseData.message === "Forbidden resource";
};

instance.interceptors.response.use(
  (response) => {
    if (isJSONResponse(response.headers)) {
      return { ...response, data: response.data.data };
    }
    return { ...response, data: response.data };
  },
  (error) => {
    if (error.response) {
      console.error(error.response.data);
      if (error.response.status === HttpStatus.UNAUTHORIZED) {
        const originalRequest = error.config;
        if (error.response?.data?.code === 401000) {
          window.location.href = `https://${getPortalURL()}?${REDIRECT_PARAM}=${
            window.location.origin
          }`;
        }

        if (error.response?.data?.code === 401001 && !originalRequest.retry) {
          originalRequest.retry = true;

          if (!isRefreshing) {
            isRefreshing = true;
            instance
              .post(`/v1/auth/access-token/refresh`)
              .then(() => {
                isRefreshing = false;
                refreshSubscribers.forEach((callback) => {
                  callback();
                });
                refreshSubscribers = [];
              })
              .catch(() => {
                isRefreshing = false;

                window.location.href = `https://${getPortalURL()}?${REDIRECT_PARAM}=${
                  window.location.origin
                }`;
              });
          }

          return new Promise((res) => {
            refreshSubscribers.push(() => res(axios(originalRequest)));
          });
        }
      }
      if (
        error.response.status === HttpStatus.FORBIDDEN &&
        isForbiddenUser(error.response.data)
      ) {
        const currentUrl = window.location.href;
        if (
          currentUrl.includes("forbidden") &&
          currentUrl.includes(REDIRECT_PARAM)
        ) {
          window.location.href = currentUrl;
        } else {
          window.location.href = `/forbidden?${REDIRECT_PARAM}=${currentUrl}`;
        }
      }
    }

    return Promise.reject(error);
  }
);
