import { Delete, Get, Post } from "@configs/Axios";

type GetLicenseRequest = {
  orgId?: string;
  status: string[];
};

export interface GetLicenseResponse {
  licenseKey: string;
  gatewayId: string;
  deviceName: string;
  licenseStatus: string;
  activeDate?: number;
  expiryDate?: number;
  durationInDays: number;
  productName: string;
  sku: string;
  dueDay: number;
  trial: boolean;
  term: Term;
  entitlements: Term[];
  serviceId: string;
  gracePeriod?: number;
}

interface AttachLicensesRequest {
  orgId?: string;
  params: {
    licenseKey: string;
    gatewayId: string;
  }[];
}
export interface AttachLicensesResponse {
  gatewayId: string;
  licenseKey: string;
  sku: string;
  code: number;
  reason: {
    name: string;
    correlationId: string;
    code: number;
  };
}
interface DetachLicensesRequest {
  orgId?: string;
  licenseKeys: string[];
  gatewayIds: string[];
}

interface Term {
  type: string;
  expiry: number;
  data: Data;
}

interface Data {
  durationInDays: number;
}

interface OnCloudStatusRequest {
  orgId?: string;
  deviceId?: string;
}

interface OnCloudStatusResponse {
  linked: boolean;
}

export const licenseApis = {
  getLicenseList: async ({ orgId, status }: GetLicenseRequest) => {
    return Get<GetLicenseResponse[]>(`/v1/organizations/${orgId}/licenses`, {
      params: {
        status
      }
    });
  },

  attachLicenses: async ({
    orgId,
    params
  }: AttachLicensesRequest): Promise<AttachLicensesResponse[]> => {
    return Post(`/v1/organizations/${orgId}/licenses/attach`, params);
  },

  detachLicenses: async ({
    orgId,
    licenseKeys,
    gatewayIds
  }: DetachLicensesRequest) => {
    return Delete(`/v1/organizations/${orgId}/licenses/detach`, {
      params: {
        licenseKeys,
        gatewayIds
      }
    });
  },
  onCloudStatus: async ({ orgId, deviceId }: OnCloudStatusRequest) => {
    return Get<OnCloudStatusResponse>(
      `/v1/organizations/${orgId}/licenses/${deviceId}/device`,
      {
        params: {
          status: "ALL"
        }
      }
    );
  }
};
