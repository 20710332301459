import { useLang } from "@hooks/useLang";
import {
  IconArrowDown,
  IconArrowUp,
  IconTreeViewCheck,
  IconTreeViewHalfCheck,
  IconTreeViewUnCheck,
  IconPictogram
} from "@resources/icons/Icons";
import ModalManager from "@utils/ModalManager";
import _, { isUndefined, uniq } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import ScrollBar from "../scrollbar";
import {
  CHECK_MODEL,
  DataTreeViewItem,
  DataTreeViewItemValue,
  FlatNodeItem,
  TreeViewIconProps,
  TreeViewProps
} from "./Interfaces";
import NestedTreeViewItem from "./NestedTreeView";
import NodeModel from "./NodeModel";
import * as S from "./TreeView.styled";

const defaultIcons: TreeViewIconProps = {
  check: <IconTreeViewCheck width={16} height={16} />,
  uncheck: <IconTreeViewUnCheck width={16} height={16} />,
  halfCheck: <IconTreeViewHalfCheck width={16} height={16} />,
  collapse: <IconArrowUp width={16} height={16} />,
  expand: <IconArrowDown width={16} height={16} />
};

const defaultProps: TreeViewProps = {
  nodes: [],
  checked: [],
  disabled: false,
  expanded: [],
  icons: defaultIcons,
  name: undefined,
  showCheckbox: true,
  showIcon: true
};

let model: NodeModel;

export type TreeRefFunc = {
  reloadTreeItem: (
    node?: DataTreeViewItemValue,
    nodeHasSelected?: DataTreeViewItemValue
  ) => void;
  resetSelected: () => void;
  collapseAll: (collapse: boolean) => void;
};

const TreeView = (props: TreeViewProps, ref: React.Ref<TreeRefFunc | null>) => {
  const {
    nodes: items,
    showCheckbox,
    disabled,
    showIcon,
    id,
    rootStyle,
    onRenderItem,
    onRenderItemHover,
    onRenderItemSelected,
    lazyLoad,
    checked,
    expanded,
    loadDataItemFn,
    maxSelected,
    hoverItem,
    onHover,
    useHoverTooltip
  } = props;
  const { translate } = useLang();
  const [selected, setSelected] = useState<DataTreeViewItemValue | null>(null);
  const [nodes, setNodes] = useState<DataTreeViewItem[]>([]);
  const [stateModel, setStateModel] = useState<NodeModel>();

  useEffect(() => {
    model = new NodeModel({ ...defaultProps, ...props });
    model.flattenNodes(items, null);
    setNodes(items);

    items.length &&
      checked?.forEach((node) => {
        if (model.flatNodes[node]) {
          model.toggleChecked(model.flatNodes[node], true, CHECK_MODEL.ALL);
        }
      });
    items.length &&
      expanded?.forEach((node) => {
        if (model.flatNodes[node]) {
          model.toggleNode(
            model.flatNodes[node].value,
            "expanded",
            model.flatNodes[node].expanded
          );
        }
      });
    setStateModel(model.clone());
  }, [items]);

  useEffect(() => {
    Object.keys(model.flatNodes).forEach((node) => {
      if (
        model.flatNodes[node].isLeaf &&
        model.flatNodes[node].checked &&
        !checked.includes(node)
      ) {
        model.toggleChecked(model.flatNodes[node], false, CHECK_MODEL.ALL);
      }
    });
    setStateModel(model.clone());
  }, [checked]);

  const onCheckMaxSelected = (nodes: FlatNodeItem[]): boolean => {
    if (maxSelected) {
      let errorMessage = "";
      let errorMax = 0;
      const selectedCameras: string[] = [];
      const selectedRules: string[] = [];

      nodes.forEach((node) => {
        if (!node.children) {
          selectedCameras.push(node.value.toString().split(":")[1]);
          selectedRules.push(node.value.toString());
        }
      });

      if (
        !isUndefined(maxSelected.camera) &&
        uniq(selectedCameras).length > maxSelected.camera
      ) {
        errorMessage = maxSelected.cameraMessage;
        errorMax = maxSelected.camera;
      }

      if (
        !isUndefined(maxSelected.rule) &&
        uniq(selectedRules).length > maxSelected.rule
      ) {
        errorMessage = maxSelected.ruleMessage;
        errorMax = maxSelected.rule;
      }

      if (errorMessage) {
        ModalManager.message(translate(errorMessage, { max: errorMax }), {
          title: translate("system"),
          labelButton: translate("ok")
        });
        return true;
      }
    }
    return false;
  };

  const onChecked = (info: {
    value: DataTreeViewItemValue;
    type?: string | number | boolean;
    checked: boolean;
  }) => {
    const { onChecked } = props;
    const node = model.getNode(info.value);
    model.toggleChecked(node, info.checked, CHECK_MODEL.ALL);
    if (node.onChecked) {
      node.onChecked();
      return;
    }

    const selectedNodes = model.serializeList("checked");
    const isError = onCheckMaxSelected(selectedNodes);

    if (isError) {
      model.toggleChecked(node, false, CHECK_MODEL.ALL);
      return;
    }

    setStateModel(model.clone());
    onChecked?.(model.serializeList("checked"));
  };

  const onClicked = (info: { value: string | number }) => {
    const { onClicked } = props;
    const node = model.getNode(info.value);

    if (selected !== node.value) {
      setSelected(node.value);
    }

    if (node.onClicked) {
      node.onClicked();
      return;
    }

    onClicked && onClicked(node, info);
  };

  const onExpanded = (info: { value: string | number; expanded: boolean }) => {
    const { onExpanded } = props;
    const node = model.getNode(info.value);
    model.toggleNode(node.value, "expanded", info.expanded);
    if (node.onExpanded) {
      node.onExpanded();
      return;
    }
    setStateModel(model.clone());
    onExpanded && onExpanded(model.serializeList("expanded"));
  };

  useEffect(() => {
    if (props.isFirstAlwaysExpand) {
      const value = items[0]?.value;
      if (value) {
        onExpanded({ value: items[0].value, expanded: true });
      }
    }
  }, [items]);

  useImperativeHandle(ref, () => ({
    reloadTreeItem: async (
      node?: DataTreeViewItemValue,
      nodeHasSelected?: DataTreeViewItemValue
    ) => {
      const nodeSelected = node || selected;
      if (nodeSelected) {
        const newNodes = (await loadDataItemFn?.(
          nodeSelected
        )) as DataTreeViewItem[];
        model.setNodes(newNodes.length ? newNodes : undefined, nodeSelected);
        setStateModel(model.clone());
        onExpanded({ value: nodeSelected, expanded: true });
      }

      if (nodeHasSelected) {
        setSelected(nodeHasSelected);
        onClicked({ value: nodeHasSelected });
      }
    },
    resetSelected: () => {
      setSelected(null);
    },
    collapseAll: (collapse) => {
      model.expandAllNodes(collapse);
      setStateModel(model.clone());
    }
  }));

  if (items.length === 0) {
    return (
      <S.EmptyContent>
        <IconPictogram style={{ marginBottom: 4 }} />
        <h1>{translate("analytics_no_data")}</h1>
        <p>{translate("analytics_not_match")}</p>
      </S.EmptyContent>
    );
  }

  return (
    <S.TreeViewStyled>
      <ScrollBar>
        <NestedTreeViewItem
          items={nodes}
          disabled={disabled}
          selected={selected}
          showIcon={showIcon}
          showCheckbox={showCheckbox}
          id={id}
          level={rootStyle ? 0 : 1}
          model={stateModel as NodeModel}
          onChecked={onChecked}
          onClicked={onClicked}
          onExpanded={onExpanded}
          onRenderItem={onRenderItem}
          onRenderItemHover={onRenderItemHover}
          onRenderItemSelected={onRenderItemSelected}
          lazyLoad={lazyLoad}
          loadDataItemFn={loadDataItemFn}
          hoverItem={hoverItem}
          onHover={onHover}
          useHoverTooltip={useHoverTooltip}
        />
      </ScrollBar>
    </S.TreeViewStyled>
  );
};

export default forwardRef(TreeView);
