import styled, { css } from "styled-components";

export const Container = styled.section`
  box-sizing: border-box;
  width: 100%;
  min-height: 472px;
  border: 1px solid var(--border-default);
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TopMenu = styled.div`
  background-color: var(--background-analytics-top-menu);
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid var(--border-default);
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

export const SideMenu = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const LeftSideMenu = styled(SideMenu)`
  justify-content: left;
  font-size: 16px;
  font-weight: 600;
  color: var(--analytics-chart-title);
`;

export const RightSideMenu = styled(SideMenu)`
  justify-content: right;
  gap: 8px;
  font-size: 13px;
  font-weight: 400;
  color: var(--text-secondary-1);

  .select-box {
    border-color: transparent !important;
    background-color: transparent;

    &__disabled {
      background-color: transparent !important;
    }

    .select-icon {
      margin-left: 2px;
    }
  }
`;

export const ChartTypeCheckboxGroup = styled.div`
  text-align: left;
  border-radius: 4px;
  label {
    padding: 9px 0;
  }
`;

export const ChartTypeCheckbox = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

export const ChartTypeLabel = styled.div`
  color: var(--text-secondary-1);
  padding-left: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.71px;
`;

export const ChartTypeDropdown = styled.div`
  position: absolute;
  background-color: var(--legend-background-color);
  border: 1px solid var(--analytics-popup-border-color);
  box-shadow: 0px 2px 4px 0px #00000052;
  color: white;
  padding: 8px 16px 12px;
  border-radius: 8px;
  width: 240px;
  top: 25px;
  right: 0;
  z-index: 1;
  height: 256px;
`;

export const ChartTypeButton = styled.div`
  color: var(--text-secondary-1);
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  cursor: pointer;
`;

export const ChartTypeCount = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background-color: var(--analytics-chart-count-badge-bg);
  color: var(--analytics-chart-count-badge-text);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 13.13px;
  margin-left: 5px;
  padding: 1.5px 1px 0 0;
`;

export const ChartTypeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: relative;
`;

export const ChartTypeIcon = styled.span`
  svg {
    margin-right: 5px;
    path {
      fill: var(--icon-default);
    }
  }
`;
export const ButtonWrap = styled.div`
  margin-top: 12px;
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const IconWrap = styled.div`
  svg {
    path,
    rect {
      fill: var(--analytics-chart-icon);
    }
    width: 24px;
    height: 24px;
    margin-bottom: -2px;
  }
`;

export const LocationButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px 0 8px;
  gap: 4px;
  color: var(--text-secondary-1);
  svg {
    path {
      fill: var(--icon-default);
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--text-disabled);
      svg {
        path {
          fill: var(--icon-disabled);
        }
      }
    `}
`;

export const MoreButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  svg {
    path {
      fill: var(--icon-default);
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      svg {
        path {
          fill: var(--icon-disabled);
        }
      }
    `}
`;

export const Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: var(--border-emphasis);
`;

export const LoadingWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  > div {
    > div {
      top: calc(50% - 40px);
    }
  }
`;

export const LegendItem = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--text-secondary-1);
  padding: 7px 8px;
  border-radius: 4px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--text-disabled);
      span {
        background-color: var(--text-disabled) !important;
      }
    `}
`;

export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  position: absolute;
  top: 20px;
  z-index: 1;
`;

export const LegendCircle = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
`;

export const LegendMore = styled.div`
  cursor: pointer;
  position: relative;
`;

export const LegendButton = styled.button`
  background: transparent;
  padding: 0px 6px 0px 5px;
  height: 24px;
  color: var(--text-secondary-1);
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      fill: var(--icon-default);
    }
  }
  &:hover {
    background: var(--button-background-color);
  }
`;

export const LegendDropdownItem = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  white-space: nowrap;
  color: var(--text-secondary-1);

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--text-disabled);
      span {
        background-color: var(--text-disabled) !important;
      }
    `}
`;

export const LegendDropdown = styled.div`
  display: block;
  position: absolute;
  top: 26px;
  left: 0;
  background-color: var(--legend-background-color);
  color: var(--text-primary);
  border: 1px solid var(--legend-border-color);
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px var(--legend-box-color);
  z-index: 1000;
  overflow-y: auto;
  min-width: 160px;
  max-height: 232px;
  padding: 3px 0px;
  &::-webkit-scrollbar {
    cursor: pointer;
    display: block;
    width: 6px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--background-scroll-thumb);
    border-radius: 3px;
    cursor: auto;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 8px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
`;

export const ChartWrap = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
