import { PrimitiveType, useIntl } from "react-intl";

export const useLang = (): {
  translate: (id: string, values?: Record<string, PrimitiveType>) => string;
} => {
  const intl = useIntl();
  const translate = (
    id: string,
    values?: Record<string, PrimitiveType>
  ): string => {
    return intl.formatMessage({ id, defaultMessage: `lang_${id}` }, values);
  };
  return { translate };
};
