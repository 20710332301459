import { useEffect, useMemo } from "react";
import { useGetPortalDevicesConfigQuery } from "@queries/tanstack/device/DeviceQuery";
import { WidgetType } from "@custom-types/Types";
import {
  devicesDataMapper,
  ruleDataMapper
} from "@components/widgets/base/setup/widget-rules/DataMapper";
import useOrganization from "@hooks/useOrganization";
import { convertWidgetTypeToAI } from "@utils/Widget";
import { useDevicesWithLinkedLicenses } from "@hooks/useDevicesWithLinkedLicenses";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { keys as DeviceKey } from "@queries/tanstack/device/keys";
import { keys as WaveSyncKey } from "@queries/tanstack/wavesync/keys";

interface UseRuleDataParam {
  widgetType?: WidgetType;
  inValidateCacheOnUnmount?: boolean;
}

export const useRuleData = (
  param: UseRuleDataParam,
  queryOptions?: Parameters<typeof useQuery>[2]
) => {
  const queryClient = useQueryClient();
  const { widgetType } = param;
  const { orgId } = useOrganization();
  const {
    allDevicesWithLinkedLicences: devicesList,
    isLoading: isLoadingDevices,
    reloadAllData
  } = useDevicesWithLinkedLicenses(orgId, queryOptions);

  const {
    data: configData,
    isFetching: isFetchingConfig,
    refetch: refetchConfig
  } = useGetPortalDevicesConfigQuery(
    {
      orgId,
      options: {
        ruleTypes: widgetType ? convertWidgetTypeToAI(widgetType) : undefined,
        claimed: "true"
      }
    },
    {
      enabled: !!orgId,
      cacheTime: 0,
      staleTime: 0,
      ...queryOptions
    }
  );

  const data = useMemo(() => {
    if (configData) {
      if (devicesList.length > 0) {
        const deviceData = devicesDataMapper(devicesList, configData);
        return ruleDataMapper(deviceData, widgetType);
      }
    }

    return [];
  }, [configData, devicesList, widgetType]);

  const reloadRule = async () => {
    const response = await Promise.allSettled([
      reloadAllData(),
      refetchConfig()
    ]);

    return !!response;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (param.inValidateCacheOnUnmount) {
      return () => {
        // useGetPortalDevicesConfigQuery hook
        queryClient.invalidateQueries(DeviceKey.getPortalDeviceConfig());

        // useDevicesWithLinkedLicenses
        // > useWaveSyncData hook
        queryClient.invalidateQueries(WaveSyncKey.getStatus());
        queryClient.invalidateQueries(WaveSyncKey.getDevice());

        // license list
        queryClient.invalidateQueries(["license-list"]);
      };
    }
  }, []);

  return {
    data,
    isLoading: isLoadingDevices || isFetchingConfig,
    reloadRule
  };
};
