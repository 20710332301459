import React, { useMemo } from "react";
import { FlatNodeItem } from "@components/common/tree-view/Interfaces";
import { ReactComponent as IconBoxAI } from "@resources/icons/svg/icon-box-ai.svg";
import _ from "lodash";
import * as S from "./TreeItem.styled";

export const TreeItem = (props: FlatNodeItem) => {
  const { label, metaData, licenseStatus, parent } = props;

  const ChipList = useMemo(() => {
    const chips = [];
    if (!parent) {
      if (licenseStatus && licenseStatus !== "Normal") {
        // license
        chips.push(<S.Chip color="var(--system-gray)">{licenseStatus}</S.Chip>);
      }

      // AI rules
      if (typeof metaData?.enabledAiRule !== "undefined") {
        const metaDataStatus = metaData.enabledAiRule ? "on" : "off";

        chips.push(
          <S.Chip
            data-testid="channel-status"
            color={`${
              metaDataStatus === "on"
                ? "var(--system-emerald)"
                : "var(--system-gray)"
            }`}
          >
            <IconBoxAI style={{ marginRight: "3px" }} />
            {metaDataStatus.toUpperCase()}
          </S.Chip>
        );
      }
    }

    return chips;
  }, [licenseStatus, metaData?.enabledAiRule, parent]);

  return (
    <>
      <S.Title>{label}</S.Title>
      <S.ChipsWrap>
        {ChipList.map((v, index) => {
          return (
            <>
              {index > 0 && <S.Divider />}
              {v}
            </>
          );
        })}
      </S.ChipsWrap>
    </>
  );
};
