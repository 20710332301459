import {
  AnalyticsFilterControllerGetAnalyticsFilterListData,
  analyticsFilterControllerGetAnalyticsFilterList,
  analyticsFilterControllerCreateAnalyticsFilter,
  AnalyticsFilterControllerCreateAnalyticsFilterData,
  analyticsFilterControllerUpdateAnalyticsFilter,
  AnalyticsFilterControllerUpdateAnalyticsFilterData,
  analyticsFilterControllerDeleteAnalyticsFilter,
  AnalyticsFilterControllerDeleteAnalyticsFilterData,
  AnalyticsFilterResponseDto,
  AnalyticsFilterControllerGetAnalyticsFilterListResponse
} from "@open-api";

export const analyticsFilter = {
  get: async (params: AnalyticsFilterControllerGetAnalyticsFilterListData) => {
    const resp = (await analyticsFilterControllerGetAnalyticsFilterList(
      params
    )) as unknown as AnalyticsFilterControllerGetAnalyticsFilterListResponse;
    return resp.data;
  },
  post: async (params: AnalyticsFilterControllerCreateAnalyticsFilterData) => {
    const resp = await analyticsFilterControllerCreateAnalyticsFilter(params);
    return resp.data as AnalyticsFilterResponseDto;
  },
  put: async (params: AnalyticsFilterControllerUpdateAnalyticsFilterData) => {
    const resp = await analyticsFilterControllerUpdateAnalyticsFilter(params);
    return resp.data;
  },
  delete: async (
    params: AnalyticsFilterControllerDeleteAnalyticsFilterData
  ) => {
    const resp = await analyticsFilterControllerDeleteAnalyticsFilter(params);
    return resp.data;
  }
};
