import styled from "styled-components";

interface SkeletonStyledProps {
  isLoading?: boolean;
}

export const NormalStyled = styled.div`
  height: 100%;
  width: 100%;
`;

export const SkeletonStyled = styled.div<SkeletonStyledProps>`
  height: 100%;
  width: 100%;

  @keyframes skeleton-gradient {
    0% {
      background-color: transparent;
    }

    10% {
      background-color: rgba(165, 165, 165, 0.1);
    }

    50% {
      background-color: rgba(165, 165, 165, 0.3);
    }

    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }

  .skeleton {
    overflow: hidden;
    position: relative;
    color: transparent !important;
    background-color: transparent !important;

    * {
      visibility: hidden !important;
    }

    ::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 3px;
      animation: skeleton-gradient 1.8s infinite ease-in-out forwards;
      width: 100%;
      height: 100%;
      z-index: 9999;
      background-color: transparent;
    }
  }
`;
