import { Get, PostWithStatusReturn, Put } from "@configs/Axios";
import { PermissionsInfo } from "@custom-types/Types";
import { GetOrganization } from "./Organization";

export type GetPortalUserResponse = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  accountLanguage: string;
  phoneNumber: string;
  accountStatus: string;
  portalRole: string;
  role: string;
  isLocked: true;
  imageUrl: UserProfileImage;
  activeDate: number;
  createdDate: number;
  updatedDate: number;
  permissions: PermissionsInfo<boolean>;
};

type UserProfileImage = {
  url: string;
  expiry: number;
};

export type GetUserSettings = {
  userId?: string;
  colorMode?: string;
  locale?: Language;
  dateTime?: DateTime;
  dashboardSequence?: Sequence;
  notification?: NotificationSettings;
};

type GetRolePermissionListResponse = {
  name: string;
  description: string;
  custom: boolean;
  orgId?: string;
  roleId: string;
  permissions: PermissionsInfo<boolean>;
};

export type NotificationSettings = {
  webpush: boolean;
  email: boolean;
  eventFilter: NotificationFilters;
};

export type NotificationFilters = {
  reportShare: boolean;
  // Note: Don't delete. Event notification can be used in future
  /*
  shoppingCartLoss: boolean;
  mes: boolean;
  temperature: boolean;
  slipAndFall: boolean;
  crowdSafety: boolean;
  forkliftSpeed: boolean;
  emergencyExit: boolean;
  stoppedVehicle: boolean;
  trafficJam: boolean;
  */
};
type DateTime = {
  mode: string;
  timezone: string;
};

type Language = {
  language: string;
};

type Sequence = {
  conversionTime: number;
  dashboards: Dashboards[];
};
type Dashboards = {
  id: string;
};

export type GetPartnerApp = {
  id: string;
  name: string;
  icon: string;
  url: string;
  desc: string;
  wicon: string;
  dicon: string;
};

export const userApi = {
  getUser: async (orgId: string) => {
    return Get<GetPortalUserResponse>(`/v1/organizations/${orgId}/user`);
  },
  getUserWithPermissions: async (orgId: string) => {
    return Get<GetPortalUserResponse>(
      `/v1/organizations/${orgId}/user?permissions=true`
    );
  },
  generateUserPermission: async (orgId: string) => {
    return PostWithStatusReturn(
      `/v1/organization-management/app-default-permission`,
      { orgId }
    );
  },
  getSettings: async (orgId: string) => {
    return Get<GetUserSettings>(`/v1/organizations/${orgId}/settings`);
  },
  update: async (req: GetUserSettings, orgId: string) => {
    return Put<GetUserSettings>(`/v1/organizations/${orgId}/settings`, req);
  },
  partner: async () => {
    return Get<GetPartnerApp[]>(`/partner-app`);
  },
  updateLastAccess: async () => {
    return Get<[]>(`/v1/auth/access-token/update-lastAccess`);
  },
  lastAccess: async () => {
    return Get<[]>(`/v1/auth/access-token/lastAccess`);
  },
  getRolePermissionList: async (orgId: string, roleId: string) => {
    if (orgId) {
      return Get<GetRolePermissionListResponse>(
        `/v1/organizations/${orgId}/roles/${roleId}`
      );
    }
    return "";
  },
  getOrganizations: async () => {
    return Get<GetOrganization[]>(`/v1/organizations`);
  }
};
