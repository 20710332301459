import { ContextMenu } from "@components/common/context-menu";
import { MONTH, FORMAT } from "@resources/Constants";
import moment from "moment";
import React, { useCallback, useEffect, useId, useRef } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";

import {
  IconArrowChevronDown,
  IconArrowChevronUp
} from "@resources/icons/Icons";
import { classNames } from "@utils/Global";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarProps } from "./Interfaces";
import { CalendarStyled, HeaderStyled } from "./Header.styled";

const months = Object.values(MONTH);

const Header = ({
  date,
  monthDate,
  decreaseMonth,
  increaseMonth,
  changeMonth,
  changeYear,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <HeaderStyled>
      <div className="control">
        <div className="month-year-menu">
          <ContextMenu
            data={months.map((month, index) => ({
              label: month,
              custom: {
                onClick: () => {
                  changeMonth(index);
                }
              }
            }))}
          >
            <div className="month-year">
              {moment(monthDate || new Date())
                .format(FORMAT.MONTH_DATE_YEAR_1)
                .substring(0, 3)}

              <span className="icon-dropdown">
                <IconArrowChevronDown width={16} height={16} />
              </span>
            </div>
          </ContextMenu>

          <ContextMenu
            data={Array.from({ length: 6 }, (_, i) => {
              const year = moment().year() - (5 - i);
              return {
                label: year.toString(),
                custom: {
                  onClick: () => {
                    changeYear(year);
                  }
                }
              };
            })}
          >
            <div className="month-year">
              {moment(monthDate || date)
                .format(FORMAT.DATE_MONTH)
                .substring(0, 4)}
              <span className="icon-dropdown">
                <IconArrowChevronDown width={16} height={16} />
              </span>
            </div>
          </ContextMenu>
        </div>

        <div className="month-control">
          <span
            className="icon-arrow up"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!prevMonthButtonDisabled) {
                decreaseMonth();
              }
            }}
          >
            <IconArrowChevronUp width={16} height={16} />
          </span>
          <span
            className="icon-arrow down"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!nextMonthButtonDisabled) {
                increaseMonth();
              }
            }}
          >
            <IconArrowChevronDown />
          </span>
        </div>
      </div>
    </HeaderStyled>
  );
};

export const Calendar = ({ today, ...props }: CalendarProps) => {
  const uniq = useId();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const renderWeekDay = useCallback(
    (nameOfDay: string) => String(nameOfDay).substring(0, 3),
    []
  );

  const renderDayContents = useCallback(
    (day: number, date: Date | undefined) => (
      <div
        className={classNames({
          [`date-picker-custom-today`]: moment(date).diff(today, "h") === 0,
          [`date-picker-custom-date`]: true,
          [`date-picker-start-of-month`]:
            moment(date).startOf("month").diff(date, "day") === 0,
          [`date-picker-end-of-month`]:
            moment(date).endOf("month").diff(date, "day") === 0
        })}
      >
        <span data-day={day}>{date?.getDate()}</span>
        <span className="shadow-date">{date?.getDate()}</span>
      </div>
    ),
    []
  );

  useEffect(() => {
    if (!wrapperRef.current) return undefined;

    const monthContainer = wrapperRef.current.querySelector(
      ".react-datepicker__month"
    ) as HTMLElement;

    const prevButton = wrapperRef.current.querySelector(
      ".icon-arrow.up"
    ) as HTMLSpanElement;

    const nextButton = wrapperRef.current.querySelector(
      ".icon-arrow.down"
    ) as HTMLSpanElement;

    const handleMouseWheelEvent = (e: WheelEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.deltaY > 0) {
        nextButton?.click();
      } else {
        prevButton?.click();
      }
    };

    monthContainer?.addEventListener("wheel", handleMouseWheelEvent);

    return () =>
      monthContainer?.removeEventListener("wheel", handleMouseWheelEvent);
  }, [wrapperRef.current]);

  return (
    <CalendarStyled ref={wrapperRef} id={`date-picker_${uniq}`}>
      <DatePicker
        formatWeekDay={renderWeekDay}
        renderDayContents={renderDayContents}
        renderCustomHeader={(props) => <Header {...props} />}
        {...props}
      />
    </CalendarStyled>
  );
};
