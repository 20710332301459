import styled from "styled-components";
import { PALETTES } from "@resources/Palettes";
import { THEME_TYPE } from "@resources/Constants";

export const VideoContainer = styled.div`
  border-radius: 2px;
  width: 130px;
  height: 80px;
  background-color: ${PALETTES.DARK.MONO_BLACK};
  display: none;
`;

export const TreeViewStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;
  color: #fff;

  > ol {
    flex: 1 1 auto;
    gap: 5px;
  }

  ol {
    ol {
      li > span {
        padding-left: 42px !important;
      }

      ol {
        li > span {
          padding-left: 72px !important;
        }

        ol {
          li > span {
            padding-left: 132px !important;
          }
        }
      }
    }
  }

  .tree-view-root {
    &:not(:first-of-type) {
      margin-top: 6px;
    }
    > .tree-view-node-text {
      margin-bottom: 4px;
      height: 36px;
      background-color: var(--border-light);
      display: flex;
      flex-direction: row-reverse;
      border-bottom: 1px solid var(--border-tree-view-text-node-default);
      &:hover {
        background-color: var(--border-default);
        border-bottom: 1px solid var(--border-tree-view-text-node-hover);
      }
      &:active {
        background-color: var(--mono-8);
        border-bottom: 1px solid var(--border-tree-view-text-node-active);
      }
      > .tree-view-node-clickable {
        margin: 0;
      }
    }
  }

  .tree-view-collapse {
    display: block;
    &:has(.tree-view-icon) {
      display: none;
    }
    .tree-view-icon {
      width: 14px;
      height: 14px;
      display: block;
    }
  }

  .tree-view-node-text {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 16px;
    height: 28px;
    gap: 6px;
    padding: 0 12px 0 4px;
    border-radius: 2px;
    position: relative;
    :has(.tree-view-icon) {
      padding-left: 25px;
    }
    &:hover {
      background-color: var(--tree-item-hover) !important;
      ${VideoContainer} {
        display: block;
      }

      .tree-view-node-title {
        > span {
          color: var(--text-primary);
        }
      }

      .collapse-btn {
        svg {
          path,
          rect {
            fill: var(--icon-emphasis);
          }
        }
      }
      span.tree-view-node-icon {
        svg {
          path,
          rect {
            fill: var(--icon-emphasis);
          }
        }
      }
    }
    &:active {
      background-color: var(--tree-item-selected);
    }
  }

  .hover {
    background-color: var(--tree-item-hover) !important;
  }

  .tree-view-node-collapsed {
    > ol {
      overflow: hidden;
      visibility: hidden;
      max-height: 0;
      transition:
        max-height 0.2s,
        visibility 0.2s;
    }
  }

  .tree-view-node-expanded {
    > ol {
      overflow: visible;
      visibility: visible;
      max-height: 3000px;
      animation: 0.2s node-expanded-transition;
      transition: visibility 0.2s;
    }
  }

  @keyframes node-expanded-transition {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 500px;
    }
  }

  .tree-view-node-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    color: var(--text-primary);
    &:hover {
      cursor: pointer;
    }
  }

  .tree-view-node-icon {
    display: flex;
    text-align: center;

    .img-avatar {
      width: 16px !important;
      height: 16px !important;
      border-radius: 50%;
    }
    svg {
      path,
      rect {
        fill: var(--icon-default);
      }
    }
  }

  .tree-view-node-clickable,
  .tree-view-node-label {
    width: 100%;
  }

  .tree-view-node-label {
    span[role="button"] {
      height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }
  }

  /* setup tooltips */
  .tree-view-node-text::before,
  .tree-view-node-text::after {
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }

  .tree-view-node-text::after {
    content: "";
    height: 0;
    top: 20px;
    left: 20px;
    width: 0;
  }

  .tree-view-node-text::before {
    position: absolute;
    word-break: nowrap;
    padding: 6px 8px;
    border-radius: 8px;
    border: 1px solid var(--border-default);
    background-color: var(--surface-modal);
    color: var(--text-primary);
    content: attr(data-tooltip);
    font-size: 13px;
    top: 12px;
    left: 150px;
    box-sizing: border-box;
  }

  .tree-view-node-text.tooltip::after,
  .tree-view-node-text.tooltip::before {
    transform: translate3d(0, 0, 0);
    transition: all 0.15s ease-in-out;
  }

  .tree-view-node-text.tooltip:hover::after,
  .tree-view-node-text.tooltip:hover::before {
    z-index: 10000;
    opacity: 1;
    transform: translate3d(0, 10px, 0);
  }

  .tree-view-node-clickable {
    gap: 6.75px;
    height: 28px;
    display: flex;
    align-items: center;
  }

  .tree-view-node-disabled {
    .tree-view-node-text * {
      cursor: not-allowed;
      color: var(--text-disabled);
      path {
        fill: var(--icon-disabled);
      }
    }
  }

  .tree-view-node-selected {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 28px;
      background-color: var(--tree-item-selected-left-bar);
    }

    &:not(.tree-view-root) {
      > .tree-view-node-text {
        background-color: var(--mono-0-8-per);
      }
    }
    .collapse-btn {
      svg {
        path,
        rect {
          fill: var(--icon-emphasis);
        }
      }
    }
    > .tree-view-node-text {
      span.tree-view-node-icon {
        svg {
          path,
          rect {
            fill: var(--icon-emphasis);
          }
        }
      }
    }
  }

  .tree-view-node-status {
    text-transform: uppercase;
    .on {
      color: #ff6d10;
    }
    .off {
      color: #4e5153;
    }
  }
`;

export const TreeItemTooltip = styled.div<{ top: number }>`
  position: fixed;
  z-index: 10;
  align-self: flex-start;
  display: flex;
  gap: 12px;
  height: 88px;
  padding: 4px 18px 4px 4px;
  align-items: center;
  color: var(--text-primary);
  border-radius: 4px;
  border: 1px solid var(--border-light);
  top: 0px;
  transform: ${({ top }) => `translate(300px, ${top + 16}px)`};
  background-color: var(--surface-modal);
  box-shadow: ${({ theme }) =>
    theme.type === THEME_TYPE.LIGHT
      ? "0 2px 2px 0 rgba(0 0 0 / 10%)"
      : "0 2px 4px 0 rgba(0 0 0 / 32%)"};
`;

export const CameraInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

export const RuleInfoWrap = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 8px;
`;

export const CameraInfoText = styled.div`
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const RuleType = styled(CameraInfoText)`
  color: var(--text-primary);
  font-size: 14px;
`;

export const CameraName = styled(CameraInfoText)`
  color: var(--text-primary);
  max-width: 100px;
  font-size: 14px;
`;

export const RuleName = styled(CameraInfoText)`
  color: var(--text-secondary-1);
  font-size: 12px;
  max-width: 150px;
`;

export const ChannelWrapper = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  padding: 1px;
  justify-content: center;
  align-items: center;
`;

export const Channel = styled.div`
  display: flex;
  width: 14px;
  height: 14px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${PALETTES.DARK.MONO_1};
  border-radius: 50%;
  color: ${PALETTES.DARK.MONO_BLACK};
  font-size: 9px;
  font-weight: 700;
  line-height: normal;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  h1 {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-primary);
  }

  p {
    font-size: 14px;
    color: var(--text-secondary-1);
  }

  svg path,
  circle {
    stroke: var(--icon-emphasis);
  }
`;
