import React, { ReactNode, useEffect, useState } from "react";
import * as S from "./Toast.styled";

export default function Toast({
  children,
  duration,
  onClose,
  onClick
}: {
  children: ReactNode;
  duration: number;
  onClose?: () => void;
  onClick?: () => void;
}) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);

    const timeout = setTimeout(() => {
      setVisible(false);
      setTimeout(() => {
        onClose?.();
      }, 300);
    }, duration);

    return () => clearTimeout(timeout);
  }, [duration, onClose]);

  return (
    <S.Container
      aria-label="toast-message"
      $visible={visible}
      onClick={() => {
        onClick?.();
        setVisible(false);
        setTimeout(() => {
          onClose?.();
        }, 300);
      }}
    >
      {children}
    </S.Container>
  );
}
