import { IconNotUse } from "@resources/icons/Icons";
import React from "react";
import * as S from "./Thumbnail.styled";

type Props = React.HTMLAttributes<HTMLImageElement> & {
  isLoading: boolean;
  isError: boolean;
  thumbnail:
    | { contentType: string; content: string | object }
    | string
    | undefined;
  refImg?: React.MutableRefObject<HTMLImageElement | null>;
  message?: string;
};

const Thumbnail = ({
  isLoading,
  isError,
  thumbnail,
  refImg,
  message,
  ...props
}: Props) => {
  if (isLoading) {
    return (
      <S.Thumbnail>
        <S.ThumbnailSkeleton />
      </S.Thumbnail>
    );
  }

  if (
    isError ||
    !thumbnail ||
    (typeof thumbnail !== "string" && typeof thumbnail?.content !== "string")
  ) {
    return (
      <S.DefaultImage data-testid="thumbnail-default-image">
        <IconNotUse />
        {message ? <S.Message>{message}</S.Message> : null}
      </S.DefaultImage>
    );
  }

  return (
    <S.Thumbnail>
      <img
        {...props}
        src={`data:${
          typeof thumbnail !== "string" ? thumbnail?.contentType : ""
        };base64,${typeof thumbnail !== "string" ? thumbnail?.content : ""}`}
        alt="snapshot_thumbnail"
        data-testid="thumbnail-image"
        ref={refImg}
      />
    </S.Thumbnail>
  );
};

export default Thumbnail;
