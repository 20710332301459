import { SESSION_STORAGE_KEY } from "@resources/Constants";
import moment from "moment";
import { useCallback } from "react";
import useSessionStorage from "./useSessionStorage";

export const useCurrentTimezone = () => {
  const [sessionStorageUtc] = useSessionStorage(
    SESSION_STORAGE_KEY.TIME_ZONE,
    moment.tz.guess()
  );

  const currentTimeZone = sessionStorageUtc || moment.tz.guess();

  const convertByTimezone = useCallback(
    (date: string) => {
      return moment.tz(date, currentTimeZone).toISOString(true);
    },
    [currentTimeZone]
  );

  return { currentTimeZone, convertByTimezone };
};
