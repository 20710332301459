import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
export const LoadingWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;

  span {
    border-color: var(--mono-0) var(--mono-0) transparent !important;
  }
`;

export const TreeWrap = styled.div`
  height: calc(100%);
  > div {
    // scroll area
    height: 100%;
  }

  .tree-view-node-selected {
    .tree-view-node-text {
      .tree-view-node-title {
        .rule-meta {
          color: var(--text-primary);
        }
      }
    }
  }

  ol {
    padding-left: 0px !important;

    ol {
      li > span {
        padding-left: 12px !important;
      }

      ol {
        li > span {
          padding-left: 42px !important;
        }

        ol {
          li > span {
            padding-left: 72px !important;
          }

          ol {
            li > span {
              padding-left: 132px !important;
            }
          }
        }
      }
    }
  }
`;
