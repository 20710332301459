import { useRef, useState } from "react";

export const useInputHook = <T>(defaultValue?: T) => {
  const [value, setValue] = useState<T | undefined>(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const getValueFromRef = () => {
    if (!inputRef.current) {
      console.warn("inputRef is not defined");
      return undefined;
    }

    return inputRef.current?.value;
  };

  return { inputRef, getValueFromRef, value, setValue };
};
