import React, { useMemo, useState } from "react";
import { ReactComponent as IconCalendar } from "@resources/icons/svg/icon-calendar.svg";
import { DateRangePicker } from "@components/common/calendar/date-range-picker";
import { DateTime } from "@components/common/calendar/date-range-picker/Interfaces";
import moment from "moment";
import _ from "lodash";
import { DEFAULT_DATE_TIME } from "@redux/System";
import { CalendarModeRangeType } from "@custom-types/Types";
import { FORMAT } from "@resources/Constants";
import * as S from "../TopMenu.styled";

interface CalendarButtonProps {
  dateTime?: DateTime;
  onChange: (date: {
    start: string;
    end: string;
    mode: CalendarModeRangeType;
  }) => void;
}
export const CalendarButton = (props: CalendarButtonProps) => {
  const { dateTime } = props;
  const [isShowCalendar, setIsShowCalendar] = useState(false);

  const period = useMemo(() => {
    if (dateTime) {
      const range = [];
      const startMoment = moment(dateTime.start);
      const endMoment = moment(dateTime.end);
      const currentMoment = moment();
      if (
        startMoment.get("year") !== endMoment.get("year") ||
        startMoment.get("year") !== currentMoment.get("year") ||
        endMoment.get("year") !== currentMoment.get("year")
      ) {
        range.push(startMoment.format(FORMAT.MONTH_DATE_YEAR_1));
        range.push(endMoment.format(FORMAT.MONTH_DATE_YEAR_1));
      } else {
        range.push(startMoment.format(FORMAT.DATE5));
        range.push(endMoment.format(FORMAT.DATE5));
      }

      return _.uniq(range).join(" - ");
    }
    return "Period";
  }, [dateTime]);

  const handleChangeDate = (selectedDate: DateTime) => {
    if (dateTime) {
      // if prev date is saved, compare with changed date
      const origin = {
        start: moment(dateTime.start).utc().toISOString(true),
        end: moment(dateTime.end).utc().toISOString(true)
      };
      const changed = {
        start: moment(selectedDate.start).utc().toISOString(true),
        end: moment(selectedDate.end).utc().toISOString(true)
      };

      if (!_.isEqual(origin, changed)) {
        props.onChange(selectedDate);
      }
    } else {
      // if prev date is not saved, save changed date
      props.onChange(selectedDate);
    }
  };

  return (
    <S.MenuButton
      style={{ padding: "0 15px 0 11px", position: "relative" }}
      onClick={() => setIsShowCalendar(true)}
    >
      <IconCalendar width={16} height={16} />
      {period}
      {isShowCalendar && (
        <S.LayerWrap onClick={(e) => e.stopPropagation()}>
          <DateRangePicker<DateTime>
            dateTime={dateTime ?? DEFAULT_DATE_TIME}
            setDateTime={handleChangeDate}
            onShow={(isShow) => setIsShowCalendar(isShow)}
            yearIsLimit
            showRangeMode
            usePreview={false}
          />
        </S.LayerWrap>
      )}
    </S.MenuButton>
  );
};
