import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--surface-popup);
  border: 1px solid var(--border-default);
  width: 480px;
  box-sizing: border-box;
  border-radius: 8px;
  padding-top: 4px;
`;

export const RuleWrap = styled.div`
  height: 400px;
  overflow: hidden;
`;

export const ClearAllButton = styled.button`
  font-size: 14px;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 8px;

  path {
    fill: var(--icon-default);
  }
`;

export const BottomWrap = styled.div`
  border-top: 1px solid var(--border-default);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  border-radius: 0 0 8px 8px;
  padding: 12px 16px;
`;
