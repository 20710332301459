import React from "react";
import { ReactComponent as IconRulePeopleCount } from "@resources/icons/svg/rule/icon-rule-people-counting.svg";
import { ReactComponent as IconRuleQueue } from "@resources/icons/svg/rule/icon-rule-queue-management.svg";
import { ReactComponent as IconRuleSlipAndFall } from "@resources/icons/svg/rule/icon-rule-slip-and-fall.svg";
import { ReactComponent as IconRuleCrowd } from "@resources/icons/svg/rule/icon-rule-crowd.svg";
import { ReactComponent as IconRuleShoppingCart } from "@resources/icons/svg/rule/icon-rule-shopping-cart.svg";
import { ReactComponent as IconRuleVehicle } from "@resources/icons/svg/rule/icon-rule-vehicle.svg";
import { ReactComponent as IconRuleTrafficJam } from "@resources/icons/svg/rule/icon-rule-traffic-jam.svg";
import { TIME_INTERVAL, TYPE_TIME_INTERVAL } from "@resources/Constants";
import { ChartCardProps } from "./Interfaces";

export const defaultDataVehicleType = [
  "all",
  "car",
  "bus",
  "truck",
  "motorcycle",
  "bicycle"
];

const commonProps = {
  timeInterval: TIME_INTERVAL.DAY_1,
  typeInterval: TYPE_TIME_INTERVAL.HOUR
};

export const previewCumulativePeopleCount: ChartCardProps = {
  title: "Cumulative people count",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRulePeopleCount),
  ...commonProps
};

export const previewPeopleOccupancy: ChartCardProps = {
  title: "People occupancy",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRuleCrowd),
  ...commonProps
};

export const previewPeopleFlow: ChartCardProps = {
  title: "People flow",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRulePeopleCount),
  ...commonProps
};

export const previewPeopleQueues: ChartCardProps = {
  title: "People queues",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRuleQueue),
  ...commonProps
};

export const previewSlipAndFall: ChartCardProps = {
  title: "Slip & fall",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRuleSlipAndFall),
  ...commonProps
};

export const previewCrowdCounting: ChartCardProps = {
  title: "Crowd counting",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRuleCrowd),
  ...commonProps
};

export const previewCumulativeShoppingCartCount: ChartCardProps = {
  title: "Cumulative shopping cart count",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRuleShoppingCart),
  ...commonProps
};

export const previewShoppingCartInUse: ChartCardProps = {
  title: "Shopping cart in use",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRuleShoppingCart),
  ...commonProps
};

export const previewShoppingCartQueues: ChartCardProps = {
  title: "Shopping cart queues",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRuleShoppingCart),
  ...commonProps
};

export const previewCumulativeVehicleCount: ChartCardProps = {
  title: "Shopping cart in use",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRuleCrowd),
  ...commonProps
};

export const previewVehicleQueues: ChartCardProps = {
  title: "Vehicle queues",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRuleVehicle),
  ...commonProps
};

export const previewStoppedVehicleAnalytics: ChartCardProps = {
  title: "Stopped vehicle analytics",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRuleVehicle),
  ...commonProps
};

export const previewSlowTraffic: ChartCardProps = {
  title: "Slow traffic",
  analyticsData: [
    {
      locationName: "Nonjinmul Beach",
      total: 400,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 10, 20, 0, 120, 30, 40, 0, 0, 0, 0, 0, 0, 155, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 34, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 40, 20, 20, 4
      ],
      color: "#ff6d10"
    },
    {
      locationName: "Hanwha Vision (HQ)",
      total: 1786,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 23, 3, 55, 0, 45, 23, 77, 4, 5, 22,
        44, 55, 6, 21, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 0, 0, 0
      ],
      color: "#afed23"
    },
    {
      locationName: "EInfochips",
      total: 354,
      chartData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 75, 75,
        0, 0, 162, 35, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 60, 0, 0, 0, 3
      ],
      color: "#eeda12"
    }
  ],
  baseTime: {
    start: "2024-09-26T00:00:00.000+05:30",
    end: "2024-12-24T23:59:59.999+05:30",
    mode: "custom"
  },
  dataSeries: ["Nonjinmul Beach", "Hanwha Vision (HQ)", "EInfochips"],
  icon: React.createElement(IconRuleTrafficJam),
  ...commonProps
};
